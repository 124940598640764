import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from '../../services/utils.service';
import { SupportService } from '../../services/support.service';
import { LockerOccupationModel } from './model/lockerOccupation.model';
import { SearchCCModel } from './model/searchCC.model';
import { SearchConsignmentModel } from './model/searchConsignment.model';
import { SpecModel } from './model/spec.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoginService } from '../../services/login.service';
import { PlatformService } from '../../services/platform.service';

@Component({
  selector: 'app-advanced',
  templateUrl: './advanced.component.html',
  styleUrls: ['./advanced.component.scss']
})
export class AdvancedComponent implements OnInit {
  loading: boolean = false;
  statusResponsePing: number;
  responsePing: string = '';
  clientSelected: string;

  statusResponseMantenimiento: number;
  responseMantenimiento: string = '';

  statusResponseSpec: number;
  responseSpec: string = '';

  statusResponseSpecialParams: number;
  responseSpecialParams: string = '';

  typeMantenimiento: string = '';
  lockerId: string = '';
  role: number = 1;

  specModel: any = false;
  paramsModel: any = false;
  specialParamsModel: any = false;
  networkModel: any = false;
  physicalParamsModel: any = false;

  users : any = [];
  styles = [];

  specialParamChangeModel: any = {
    response: null,
    statusResponse: 0
  }

  size: string = '';
  modalRef: BsModalRef;

  constructor(private router: Router, 
    private _supportService: SupportService, 
    private modalService: BsModalService,
    private _platformService: PlatformService,
    private loginService: LoginService) { }

  ngOnInit(): void {
    this.lockerId = localStorage.getItem('lockerid');

    this.loginService.role$.subscribe((role) => {
      this.role = role;
    });
    this.clientSelected = localStorage.getItem('companyName');
  }

  goBack() {
    this.router.navigate(['support']);
  }

  setMaintenanceConfig() {
    this.loading = true;
    this._supportService.configParamsSet(this.lockerId, this.typeMantenimiento).subscribe((res: any) => {
      this.loading = false;
      console.log('success', res);
      const resOK = (res.success) ? 'OK' : 'NO OK'
      this.responseMantenimiento = resOK;
      this.statusResponseMantenimiento = 200;
    }, (err) => {
      this.responseMantenimiento = 'NO OK';
      this.statusResponseMantenimiento = err.status;
      this.loading = false;
      console.log('error', err);
    })
  }
  next() {
    if (this.role == 2) {
      this.router.navigate(['end']);
    } else if (this.role == 3) {
      this.router.navigate(['level3']);
    }
  }
  
  getConfig(config: string) {
    console.log({config, method: 'getConfig'});
    this._platformService.getJSON(this.lockerId, config).subscribe((res: any) => {
      console.log({
        res, type: typeof res, config
      })
      if (config == 'specs') {
        console.log('specs setted')
        this.specModel = res;
      } else if (config == 'params') {
        this.paramsModel = res;
      } else if (config == 'network') {
        this.networkModel = res;
      } else if (config == 'users') {
        this.users = (res.success == false) ? [] : res;
      } else if (config == 'special-params') {
        this.physicalParamsModel = res;
      }
    }, (err) => {
      console.log({data: err});
    })
  }

  getClientConfigs() {
    this._platformService.getClientStyles(this.clientSelected).subscribe((res: any) => {
      console.log({
        res, type: typeof res
      });
      this.styles = res;
    }, (err) => {
      console.log({data: err});
    });
  }

}
