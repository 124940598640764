import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StartComponent } from './pages/start/start.component';
import { LoginComponent } from './pages/login/login.component';
import { SupportComponent } from './pages/support/support.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './common/interceptors/auth.interceptor';
import { CommonModule } from '@angular/common';
import { EndComponent } from './pages/end/end.component';
import { ReportComponent } from './pages/report/report.component';
import { HomeComponent } from './pages/home/home.component';
import { AdvancedComponent } from './pages/advanced/advanced.component';

import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { UsersComponent } from './pages/users/users.component';
import { Level3Component } from './pages/level3/level3.component';
import { ConfigurationToolComponent } from './pages/configuration-tool/configuration-tool.component';
import { ConfigurationSpecsComponent } from './pages/configuration/specs/specs.component';
import { ConfigurationParamsComponent } from './pages/configuration/params/params.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ConfigurationNetworkComponent } from './pages/configuration/network/network.component';
import { ConfigurationUsersComponent } from './pages/configuration/users/users.component';
import { SuperClientComponent } from './pages/super-client/super-client.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { PhysicalParamsComponent } from './pages/configuration/physical-params/physical-params.component';
import { PipesModule } from './common/pipes/pipes.module';
import { SuperLevel3Component } from './pages/super-level3/super-level3.component';
import { ConfigurationStylesComponent } from './pages/configuration/styles/styles.component';

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    LoginComponent,
    SupportComponent,
    EndComponent,
    ReportComponent,
    AdvancedComponent,
    HomeComponent,
    UsersComponent,
    ConfigurationToolComponent,
    Level3Component,
    ConfigurationSpecsComponent,
    ConfigurationParamsComponent,
    ConfigurationNetworkComponent,
    ConfigurationUsersComponent,
    ConfigurationStylesComponent,
    SuperClientComponent,
    PhysicalParamsComponent,
    SuperLevel3Component
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgSelectModule,
    PipesModule,
    ModalModule.forRoot(),
    NgbDatepickerModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
  }, BsModalService],
  bootstrap: [AppComponent]
})
export class AppModule { }
