<div class="row justify-content-md-center pt-3">
  <div class="row">
    <div class="col-md-9">
      <label for="lockerLocation" class="form-label">Ubicación</label>
      <input class="form-control" type="text" name="lockerLocation" [(ngModel)]="specModel.lockerLocation" id="lockerLocation" placeholder="Madrid">
    </div>
    <div class="col-md-3">
      <label for="locksPerBoard" class="form-label">Modulación de Driver Locks</label>
      <select name="locksPerBoard" [(ngModel)]="specModel.locksPerBoard" id="locksPerBoard" class="form-control">
        <option value="" *ngFor="let locksPerBoard of locksPerBoards" [value]="locksPerBoard.id">{{ locksPerBoard.name }}</option>
      </select>
    </div>
    
  </div>
  <div class="row">
    <div class="col-md-4">
      <label for="boardsNumber" class="form-label">Número de Driver Locks</label>
      <input class="form-control" type="number" name="boardsNumber" [(ngModel)]="specModel.boardsNumber" id="boardsNumber" placeholder="2">
    </div>
    <div class="col-md-4">
      <label for="screenSaverElement" class="form-label">Formato Salvapantallas</label>
      <select name="screenSaverElement" [(ngModel)]="specModel.screenSaverElement" id="screenSaverElement" class="form-control">
        <option value="">Seleccionar</option>
        <option value="desactivado">Desactivado</option>
        <option value="gif">Gif</option>
        <option value="img">Img</option>
        <option value="video">Video</option>
      </select>
    </div>
    <div class="col-md-4">
      <label for="timeOutSS" class="form-label">Timeout Salvapantallas</label>
      <input class="form-control" type="number" min="5" name="timeOutSS" [(ngModel)]="specModel.timeOutSS" id="timeOutSS" placeholder="5">
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-md-4 mt-2">
      <label class="form-label" for="">Tipos de negocio</label>
      <ng-select [items]="bussinessTypes" bindLabel="name" bindValue="id" [multiple]="multipleLanguages"
        [closeOnSelect]="closeOnSelect" [(ngModel)]="specModel.businessTypes">
      </ng-select>
    </div>
    <div class="col-md-4 mt-2">
      <label class="form-label" for="">Idiomas disponibles</label>
      <ng-select [items]="languages" bindLabel="name" bindValue="id" [multiple]="multipleLanguages"
        [closeOnSelect]="closeOnSelect" [(ngModel)]="specModel.languages">
      </ng-select>
    </div>
    <div class="col-md-4">
      <label for="languageSelected" class="form-label">Idioma de preferencia</label>
      <select name="statusLocker" [(ngModel)]="specModel.languageSelected" [disabled]="languages.length == 1"
        id="languageSelected" class="form-control">
        <option *ngFor="let lang of languages" [value]="lang.id">{{ lang.name }}</option>
      </select>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <label for="showScreenSaverQR" class="form-label">Mostrar QR en Salvapantallas</label>
      <select name="showScreenSaverQR" [(ngModel)]="specModel.showScreenSaverQR" id="showScreenSaverQR" class="form-control">
        <option value="">Seleccionar</option>
        <option value="QR Estático">QR Estático</option>
        <option value="QR Acceso Interfaz Móvil">QR Acceso Interfaz Móvil</option>
        <option value="Desactivado">Desactivado</option>
      </select>
    </div>
    <div class="col-md-4">
      <label for="showScreenSaverLogo" class="form-label">Mostrar Logo Cliente en Salvapantalla</label>
      <select name="showScreenSaverLogo" [(ngModel)]="specModel.showScreenSaverLogo" id="showScreenSaverLogo" class="form-control">
        <option value="">Seleccionar</option>
        <option value="Si">Habilitar</option>
        <option value="No">Deshabilitar</option>
      </select>
    </div>
    <div class="col-md-4">
      <label for="showCustomerSection" class="form-label">Área de Cliente</label>
      <select name="showCustomerSection" [(ngModel)]="specModel.showCustomerSection" id="showCustomerSection" class="form-control">
        <option value="">Seleccionar</option>
        <option value="Si">Habilitar</option>
        <option value="No">Deshabilitar</option>
      </select>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3">
      <label for="webhookEnable" class="form-label">Webhook</label>
      <select name="webhookEnable" [(ngModel)]="specModel.webhookEnable" id="webhookEnable" class="form-control">
        <option value="">Seleccionar</option>
        <option value="Si">Habilitar</option>
        <option value="No">Deshabilitar</option>
      </select>
    </div>
    <div class="col-md-9">
      <label for="webhookURL" class="form-label">Webhook URL</label>
      <input class="form-control" [disabled]="specModel.webhookEnable != 'Si'" type="text" name="webhookURL" [(ngModel)]="specModel.webhookURL" id="webhookURL" placeholder="http://localhost">
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-md-12 text-end">
      <button class="btn btn-primary ms-2" [disabled]="loading" (click)="cancel()">Cancelar</button>
      <button class="btn btn-success ms-2" [disabled]="loading" (click)="save()">Guardar</button>
    </div>
  </div>
</div>
<div class="spinner" [hidden]="!loading">
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>  
</div>