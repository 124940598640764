import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  token: string;

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('token');
  }

  getClients() {
    return this.http.get(`${environment.apiUrl}/platform/clients`, { headers: { Authorization: this.token } })
  }

  createJSONs(data: any) {
    return this.http.post(`${environment.apiUrl}/platform/json`, data, { headers: { Authorization: this.token } })
  }

  getJSON(lockerId, config) {
    return this.http.get(`${environment.apiUrl}/platform/json/${lockerId}/${config}`, { headers: { Authorization: this.token } })
  }

  getAllSuperClient() {
    return this.http.get(`${environment.apiUrl}/platform/super-client`, { headers: { Authorization: this.token } })
  }
  
  addSuperClient(data) {
    return this.http.post(`${environment.apiUrl}/platform/super-client`, data, { headers: { Authorization: this.token } });
  }

  deleteSuperClient(id: any) {
    return this.http.delete(`${environment.apiUrl}/platform/super-client/${id}`, { headers: { Authorization: this.token } });
  }

  createCron(data: any) {
    return this.http.post(`${environment.apiUrl}/platform/cron`, data, { headers: { Authorization: this.token } });
  }

  getLogsByLockerId(data: any) {
    return this.http.post(`${environment.apiUrl}/platform/get-lockers-by-client`, data, { headers: { Authorization: this.token } });
  }

  getCronsUrls() {
    return this.http.get(`${environment.apiUrl}/platform/get-cron-urls`, { headers: { Authorization: this.token } });
  }

  getLogsEventsByClientIdAndDate(data: any) {
    return this.http.post(`${environment.apiUrl}/platform/getLogsEventsByClientIdAndDate`, data, { headers: { Authorization: this.token } });
  }

  getCronLogs(data: any) {
    return this.http.post(`${environment.apiUrl}/platform/get-cron-logs`, data, { headers: { Authorization: this.token } });
  }

  getConfigByClientAndName(clientId, name) {
    return this.http.get(`${environment.apiUrl}/platform/client/configdata/${clientId}/${name}`, { headers: { Authorization: this.token } })
  }

  getClientConfigs(clientId) {
    return this.http.get(`${environment.apiUrl}/platform/client/config/${clientId}`, { headers: { Authorization: this.token } })
  }

  getClientStyles(clientId) {
    return this.http.get(`${environment.apiUrl}/platform/client/styles/${clientId}`, { headers: { Authorization: this.token } })
  }

  updateStyleOwn(data: any) {
    return this.http.post(`${environment.apiUrl}/platform/client/update/style`, data, { headers: { Authorization: this.token } })
  }

}
