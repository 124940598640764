import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LoginInterface } from '../interfaces/login.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private loginStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private showResumen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private isAdmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private pingResult: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private diagnosticResult: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private role: BehaviorSubject<number> = new BehaviorSubject<number>(1);

  public loginStatus$: Observable<boolean> = this.loginStatus.asObservable();
  public showResumen$: Observable<boolean> = this.showResumen.asObservable();
  public isAdmin$: Observable<boolean> = this.isAdmin.asObservable();
  public pingResult$: Observable<string> = this.pingResult.asObservable();
  public diagnosticResult$: Observable<string> = this.diagnosticResult.asObservable();
  public role$: Observable<number> = this.role.asObservable();

  constructor(private http: HttpClient) {
    const isAdminV = localStorage.getItem('admin');
    if (isAdminV && isAdminV == 'si') {
      this.updateIsAdmin(true);
    } else {
      this.updateIsAdmin(false);
    }
    const ping = (localStorage.getItem('ping')) ? localStorage.getItem('ping') : '';
    const diagnostic = (localStorage.getItem('diagnostic')) ? localStorage.getItem('diagnostic') : '';
    const role: number = (localStorage.getItem('role')) ? parseInt(localStorage.getItem('role')) : 1;
    this.updatePingResult(ping);
    this.updateDiagnosticResult(diagnostic);
    this.updateRole(role);
  }

  auth(loginData: LoginInterface) {
    return this.http.post(`${environment.apiUrl}/auth/log-in`, loginData);
  }

  getData(data: any) {
    return this.http.post(`${environment.apiUrl}/cabinet/get-data`, data);
  }
  
  updateLoginStatus(status:boolean) {
    this.loginStatus.next(status);
  }

  updateShowResumen(show:boolean) {
    this.showResumen.next(show);
  }

  updateIsAdmin(isAdmin:boolean) {
    this.isAdmin.next(isAdmin);
  }

  updatePingResult(result:string) {
    this.pingResult.next(result);
  }

  updateDiagnosticResult(result:string) {
    this.diagnosticResult.next(result);
  }

  updateRole(result:number) {
    this.role.next(result);
  }

}
