import { Component, Input, OnInit } from '@angular/core';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
  selector: 'app-configuration-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss']
})
export class ConfigurationNetworkComponent implements OnInit {

  operationsUrl: any;
  message: string;
  messageError: boolean = false;
  loading: boolean = false;

  lockerId: string;
  client: string;
  flagInPort: any;
  typeOperationProcess: number = 1;

  networkModel: any = {
    hostName: '',
    dhcpManagementType: 1,
    dhcpManagement: 1,
    fixedIp: '',
    vpnip: '',
    anydesk: '',
    gateway: '',
    mask: '',
    dns: '',
    portsmanagement: {
      tcpout1194: false,
      router80: false,
      tcpout1883: false,
      tcpout53: false,
      tcpout443: false,
      tcpout3013: false
    }
  }

  @Input()
  get networkModelV(): any { return this.networkModel; }
  set networkModelV(obj: any) {
    console.log('model v', obj)
    if (obj) {
      this.networkModel = obj;
    }
  }

  constructor(private _platformService: PlatformService) { }

  ngOnInit(): void {
    this.lockerId = localStorage.getItem('lockerid');
    this.client = localStorage.getItem('companyName');
  }

  async save() {

    const jsonData = {
      clientId: this.client,
      name: `${this.lockerId}`,
      lockerId: this.lockerId,
      listConfigMedia: [{
        name: `${this.lockerId}`,
        urlFile: '',
        extensionName: 'json',
        isExternalFile: true
      }],
      network: {
        ...this.networkModel,
        hostname: this.networkModel.hostname,
        dhcpManagementType: (this.networkModel.dhcpmanagementtype == 1) ? 'Client Wired Network' : '4G Router',
        dhcpManagement: (this.networkModel.dhcpManagement == 1) ? 'DHCP' : 'Fixed Ip',
        fixedIp: this.networkModel.fixedIp
      }
    }

    console.log({jsonData});

    this.loading = true;
    this._platformService.createJSONs(jsonData).subscribe((res: any) => {
      alert('Configuracion seteada correctamente');
      this.loading = false;
    }, (err) => {
      this.loading = false;
    })
  }

  cancel() {
    this.networkModel = {
      hostName: '',
      dhcpManagementType: 1,
      dhcpManagement: 1,
      fixedIp: '',
      vpnip: '',
      anydesk: '',
      gateway: '',
      mask: '',
      dns: '',
      portsmanagement: {
        tcpout1194: false,
        tcpout80: false,
        tcpout1883: false,
        tcpout53: false,
        tcpout443: false,
        tcpout3013: false
      }
    }
  }

}
