import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  token: string;

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('token');
  }

  ping(lockerId) {
    return this.http.post(`${environment.apiUrl}/cabinet/ping`, {lockerId}, { headers: { Authorization: this.token } })
  }

  lockerOccupation(lockerId) {
    return this.http.post(`${environment.apiUrl}/cabinet/locker-ocupation`, {lockerId}, { headers: { Authorization: this.token } })
  }

  getDiagnostic(lockerId) {
    return this.http.post(`${environment.apiUrl}/locksCom/diagnostic-locker`, {lockerId}, { headers: { Authorization: this.token } })
  }

  resetLocker(lockerId) {
    return this.http.post(`${environment.apiUrl}/locksCom/reset-locker`, {lockerId}, { headers: { Authorization: this.token } })
  }

  getSearchOrder(lockerId) {
    return this.http.post(`${environment.apiUrl}/locksCom/diagnostic-locker`, {lockerId}, { headers: { Authorization: this.token } })
  }

  openConsignmentByCode(lockerId, code) {
    return this.http.post(`${environment.apiUrl}/consigment-staff/open-by-code`, {lockerId, code}, { headers: { Authorization: this.token } })
  }

  openCCByOrdeId(lockerId, orderId) {
    return this.http.post(`${environment.apiUrl}/cc-staff/open-by-order`, {lockerId, orderId}, { headers: { Authorization: this.token } })
  }

  openConsignmentByCoordinates(lockerId, type, y, x) {
    let url = 'open-by-coordinates';
    url = (type == 'cc') ? `cc-staff/${url}` : `consigment-staff/${url}`;
    return this.http.post(`${environment.apiUrl}/${url}`, {lockerId, y, x}, { headers: { Authorization: this.token } })
  }

  searchConsignment(lockerId, type, phone, code, coordinates) {
    const data: any = {lockerId};
    console.log(type);
    console.log(coordinates);
    if (type == 'phone') data.phone = phone;
    if (type == 'code') data.code = code;
    if (type == 'coordinates') data.coordinates = coordinates;
    return this.http.post(`${environment.apiUrl}/consigment-staff/find`, data, { headers: { Authorization: this.token } })
  }

  searchCC(lockerId, type, phone, orderId, coordinates) {
    const data: any = {lockerId};
    if (type == 'phone') data.phone = phone;
    if (type == 'orderid') data.orderId = orderId;
    if (type == 'coordinates') data.coordinates = coordinates;
    return this.http.post(`${environment.apiUrl}/cc-staff/find`, data, { headers: { Authorization: this.token } })
  }

  retireOrder(lockerId, type, id) {
    let data: any = { lockerId };
    let urlType = (type == 'cc') ? 'cc-staff' : 'consigment-staff';
    data = (type == 'cc') ? { ...data, orderId: id } : { ...data, code: id };
    return this.http.post(`${environment.apiUrl}/${urlType}/retire-order`, data, { headers: { Authorization: this.token } })
  }

  resendCode(lockerId, code) {
    return this.http.post(`${environment.apiUrl}/consigment-staff/resend-notification`, {lockerId, code}, { headers: { Authorization: this.token } })
  }

  operationLocker(lockerId, type, operationType, operationLockerId) {
    let data: any = { lockerId };
    let url = 'disable-locker';
    url = (type == 'cc') ? `cc-staff/${url}` : `consigment-staff/${url}`;
    if (operationType == 'bloquear') {
      data = { ...data, lockIdToDisable: operationLockerId, disable: true };
    } else if (operationType == 'desbloquear') {
      data = { ...data, lockIdToDisable: operationLockerId, disable: false };
    }
    return this.http.post(`${environment.apiUrl}/${url}`, data, { headers: { Authorization: this.token } })
  }

  configParamsSet(lockerId, type, data: any = false) {
    data = (!data) ? {lockerId} : data;
    if (type == 'normal') {
      data = {...data, onlyPickupMode: false, outOfService: false};
    } else if (type == 'recogida') {
      data = {...data, onlyPickupMode: true, outOfService: false};
    } else if (type == 'fueradeservicio') {
      data = {...data, onlyPickupMode: false, outOfService: true};
    }
    return this.http.post(`${environment.apiUrl}/cabinet/param-set`, data, { headers: { Authorization: this.token } });
  }

  configSpecialParamsSet(data) {
    return this.http.post(`${environment.apiUrl}/cabinet/special-params-set`, data, { headers: { Authorization: this.token } });
  }

  configSpecSet(data) {
    return this.http.post(`${environment.apiUrl}/cabinet/specs-set`, data, { headers: { Authorization: this.token } });
  }

  moveToLocker0(lockerId, type, id) {
    const url = (type == 'cc') ? `cc-staff/from-lockers-to-locker0` : `consigment-staff/from-lockers-to-locker0`;
    const data = (type == 'cc') ? { lockerId, orderId: id } : { lockerId, code: id };
    return this.http.post(`${environment.apiUrl}/${url}`, data, { headers: { Authorization: this.token } });
  }

  saveStartMessage(message) {
    return this.http.post(`${environment.apiUrl}/incidence/save-start-message`, {message}, { headers: { Authorization: this.token } })
  }

  endIncidence(message, status, date, incidence) {
    return this.http.post(`${environment.apiUrl}/incidence/end/${incidence}`, {message, status, date}, { headers: { Authorization: this.token } })
  }

  getIncidenceAndRequests() {
    return this.http.get(`${environment.apiUrl}/incidence`, { headers: { Authorization: this.token } })
  }

  getAllIncidences() {
    return this.http.get(`${environment.apiUrl}/incidence/all`, { headers: { Authorization: this.token } })
  }

  getIncidenceById(id: string) {
    return this.http.get(`${environment.apiUrl}/incidence/${id}`, { headers: { Authorization: this.token } })
  }

  getAllUsers() {
    return this.http.get(`${environment.apiUrl}/users`, { headers: { Authorization: this.token } })
  }

  createUser(user) {
    return this.http.post(`${environment.apiUrl}/users/create`, user, { headers: { Authorization: this.token } });
  }

  updateUser(user) {
    return this.http.post(`${environment.apiUrl}/users/update`, user, { headers: { Authorization: this.token } });
  }

  deleteUser(id) {
    return this.http.post(`${environment.apiUrl}/users/delete`, { id }, { headers: { Authorization: this.token } });
  }

  getAllUsersCT() {
    return this.http.get(`${environment.apiUrl}/configuration-tool/users`, { headers: { Authorization: this.token } })
  }

  createUserCT(user) {
    return this.http.post(`${environment.apiUrl}/configuration-tool/users/create`, user, { headers: { Authorization: this.token } });
  }

  updateUserCT(user) {
    return this.http.post(`${environment.apiUrl}/configuration-tool/users/update`, user, { headers: { Authorization: this.token } });
  }

  deleteUserCT(id) {
    return this.http.post(`${environment.apiUrl}/configuration-tool/users/delete`, { id }, { headers: { Authorization: this.token } });
  }

  validateSMS(lockerId, phone, lang) {
    return this.http.post(`${environment.apiUrl}/notification/locker-test-sms`, { lockerId, phone, lang }, { headers: { Authorization: this.token } })
  }

}
