import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  flagInPort: any;

  constructor() { }

  processDiagnostic(text: string) {
    let lines: any = text.split("<br>");
    this.flagInPort = {val: false};
    lines = lines.filter((line) => {
      return this.forLines(line, 1, this.flagInPort);
    });
    lines = lines.map((line) => {
      return this.forLines(line, 2, this.flagInPort);
    });
    lines = lines.join('<br>');
    return lines;
  }

  forLines(line, type, flagInPort) {
    if (line.includes('POSRT')) {
      flagInPort.val = true;
    }
    if (flagInPort.val && !line.includes('POSRT')) {
      if (line == '-') {
        flagInPort.val = false;
        if (type == 2) {
          line = `OK<br>${line}`
        }
      } else if (type == 1) {
        return false;
      }
    }
    if (type == 1) {
      return true;
    }
    return line;
  }

}
