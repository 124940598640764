import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoginService } from './services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'support-tool';
  logoHMY = 'assets/images/logoYudigar.png';
  step: number = 1;
  role: number = 1;
  logged: boolean = false;
  route: string = '';

  fecha: string = '';
  hora_inicio: string = '';
  companyName: string = '';
  username: string = '';
  lockerId: string = '';
  location: string = '';

  showResumen: boolean = true;
  isadmin = false;
  ping_result = '';
  diagnostic_result = '';

  constructor(private router: Router, private _loginService: LoginService) {
    if (localStorage.getItem('token')) {
      this.logged = true;
    }
    this._loginService.updateLoginStatus(this.logged);
    this._loginService.loginStatus$.subscribe((status) => {
      this.logged = status;
    })
    this._loginService.isAdmin$.subscribe((status) => {
      this.isadmin = status;
    })
    this._loginService.showResumen$.subscribe((show) => {
      this.showResumen = show;
      console.log(this.showResumen)
    })
    this._loginService.pingResult$.subscribe((result) => {
      this.ping_result = result;
    })
    this._loginService.diagnosticResult$.subscribe((result) => {
      this.diagnostic_result = result;
    })
    this._loginService.showResumen$.subscribe((show) => {
      this.showResumen = show;
      console.log(this.showResumen)
    })
    this._loginService.role$.subscribe((role) => {
      this.role = role;
      console.log({role})
    })
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.fecha = localStorage.getItem('fecha') || '';
        this.hora_inicio = localStorage.getItem('start') || '';
        this.companyName = localStorage.getItem('companyName') || '';
        this.username = localStorage.getItem('username') || '';
        this.lockerId = localStorage.getItem('lockerid') || '';
        this.location = localStorage.getItem('location') || '';
        this.route = event.url;
        switch (event.url) {
          case '/advanced': 
            this.step = 3;
            break;
          case '/support': 
            this.step = 2;
            break;
          case '/level3':
            this.step = 4;
            break;
          case '/end':
            this.step = 5;
            break;
          default:
            this.step = 1;
            break;
        }
        console.log('step', this.step)
      }
    })
  }

  logout() {
    localStorage.clear();
    this._loginService.updatePingResult('');
    this._loginService.updateDiagnosticResult('');
    this._loginService.updateIsAdmin(false);
    this._loginService.updateShowResumen(true);
    this._loginService.updateLoginStatus(false);
    this.router.navigate(['']);
  }

  goToNivel(nivel) {
    if (this.step != nivel) {
      this.step = nivel;
      switch (nivel) {
        case 3: 
          this.router.navigate(['advanced'])
          break;
        case 2: 
          this.router.navigate(['support'])
          break;
        case 4: 
          this.router.navigate(['level3'])
          break;
        case 5:
          this.router.navigate(['end'])
          break;
      }
    }
  }

  goToUsers() {
    this.router.navigate(['users']);
  }

  goToConfigurationTool() {
    this.router.navigate(['configuration-tool']);
  }

  goToSuperClient() {
    this.router.navigate(['super-client']);
  }

  goToHome() {
    console.log({role: this.role});
    if (this.role == 2 || this.role == 3)
      this.router.navigate(['home']);
    else
      this.router.navigate(['start'])
  }

  goToNivel3() {
    this.router.navigate(['super-level3']);
  }

}
