<div class="row justify-content-md-center pt-3">
  <div class="row justify-content-md-center">
    <div class="col-md-12">
      <label for="lockerLocation" class="form-label">Seleccionar configuracion</label>
      <select name="style" [(ngModel)]="styleSelected" id="style" class="form-control">
        <option value="" *ngFor="let style of styles" [value]="style.id">{{ style.id }}</option>
      </select>
      <pre class="mt-3">{{responseStyle}}</pre>
    </div>
  </div>
  <div class="row mt-4 justify-content-md-center">
    <div class="col-md-12 text-end">
      <button class="btn btn-primary ms-2" [disabled]="loading" (click)="cancel()">Cancelar</button>
      <button class="btn btn-success ms-2" [disabled]="loading" (click)="save()">Aplicar</button>
    </div>
  </div>
</div>
<div class="spinner" [hidden]="!loading">
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>  
</div>