<div class="container mt-4">
  <div class="accordion" id="accordionExample">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#maintenanceSet" aria-expanded="true" aria-controls="collapseOne">
          Cambiar a modos de mantenimiento
        </button>
      </h2>
      <div id="maintenanceSet" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <div class="col-md-6">
            <label for="typeMantenimiento" class="form-label">Modo:</label>
            <select name="typeMantenimiento" [(ngModel)]="typeMantenimiento" id="typeMantenimiento" class="form-control">
              <option value="">Seleccionar</option>
              <option value="normal">Normal</option>
              <option value="recogida">Solo recogida</option>
              <option value="fueradeservicio">Fuera de Servicio</option>
            </select>
          </div>
          <div class="row" *ngIf="responseMantenimiento != ''">
            <div class="col-md-12">
              <p>Response</p>
              <p>Status: {{statusResponseMantenimiento}}</p>
              <pre class="bg-light">{{responseMantenimiento}}</pre>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-end">
              <button class="btn btn-success ms-2" [disabled]="loading" (click)="setMaintenanceConfig()">Enviar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#configSpecs" aria-expanded="true" aria-controls="collapseOne" (click)="getConfig('specs')">
          Especificaciones del mueble
        </button>
      </h2>
      <div id="configSpecs" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <app-configuration-specs [specModelV]="specModel"></app-configuration-specs>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#configParams" aria-expanded="true" aria-controls="collapseOne" (click)="getConfig('params')">
          Parámetros del mueble
        </button>
      </h2>
      <div id="configParams" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <app-configuration-params [paramsModelV]="paramsModel"></app-configuration-params>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#physicalParams" aria-expanded="true" aria-controls="collapseOne" (click)="getConfig('special-params')">
          Parámetros físicos del mueble
        </button>
      </h2>
      <div id="physicalParams" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <app-physical-params [physicalParamsModelV]="physicalParamsModel"></app-physical-params>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#configNetwork" aria-expanded="true" aria-controls="collapseOne" (click)="getConfig('network')">
          Parámetros de RED
        </button>
      </h2>
      <div id="configNetwork" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <app-configuration-network [networkModelV]="networkModel"></app-configuration-network>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#configUsers" aria-expanded="true" aria-controls="collapseOne" (click)="getConfig('users')">
          Usuarios de Staff
        </button>
      </h2>
      <div id="configUsers" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <app-configuration-users [usersV]="users"></app-configuration-users>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#configStyles" aria-expanded="true" aria-controls="collapseOne" (click)="getClientConfigs()">
          Asociar estilos
        </button>
      </h2>
      <div id="configStyles" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <app-configuration-styles [stylesV]="styles"></app-configuration-styles>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="text-end mt-4 mb-4">
      <button class="btn btn-primary" (click)="goBack()">Volver</button>
      <button class="btn btn-primary ms-2" (click)="next()" [disabled]="loading">Siguiente</button>
    </div>
  </div>
  <div class="spinner" [hidden]="!loading">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>  
  </div>
</div>