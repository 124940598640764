import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginInterface } from '../../interfaces/login.interface';
import { LoginService } from '../../services/login.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  logoHMY = 'assets/images/logoYudigar.png';
  email: string = '';
  password: string = '';
  lockerid: string = '';
  loading: boolean = false;

  constructor(private loginService: LoginService,
    private router: Router) {
      
     }

  ngOnInit(): void {
    window.localStorage.clear();
  }

  login() {
    if (this.email == '' || this.password == '') {
      alert('Todos los campos son requeridos')
      return;
    }

    const timezone = moment.tz.guess();
    localStorage.setItem('timezone', timezone);
    const loginData: LoginInterface = {
      timezone,
      email: this.email,
      password: this.password,
      lockerId: this.lockerid,
      startHour: moment().format('YYYY-MM-DD HH:mm'),
    }

    this.loading = true;
    this.loginService.auth(loginData).subscribe((res: any) => {
      localStorage.setItem('token', res.accessToken);
      localStorage.setItem('iduser', res.id);
      localStorage.setItem('username', res.name);
      localStorage.setItem('email', res.email);
      localStorage.setItem('incidence', res.incidenceId);
      localStorage.setItem('step', '1');
      localStorage.setItem('role', res.role);
      this.loginService.updateRole(res.role);
      if (this.lockerid != '')
        localStorage.setItem('lockerid', this.lockerid);
      else if (res.role == 1) {
        alert('El campo lockerId es requerido');
        this.loading = false;
        return;
      }
      localStorage.setItem('fecha', moment().format('DD/MM/YYYY'));
      localStorage.setItem('start', moment().format('HH:mm'));
      if (res.role == 2 || res.role == 3) {
        localStorage.setItem('admin', 'si');
        this.loginService.updateIsAdmin(true);
        if (this.lockerid == '') {
          this.loginService.updateLoginStatus(true);
          this.loginService.updateShowResumen(true);
          this.router.navigate(['home']);
          return;
        }
      } else {
        localStorage.setItem('admin', 'no');
        this.loginService.updateIsAdmin(false);
      }
      console.log('success', res);
      if (this.lockerid != '') {
        const data = {lockerId: this.lockerid};
        this.loginService.getData(data).subscribe((resData: any) => {
          this.loginService.updateLoginStatus(true);
          this.loginService.updateShowResumen(true);
          localStorage.setItem('companyName', resData.companyName || '');
          localStorage.setItem('location', resData.lockerLocation || '');
          this.loading = false;
          this.router.navigate(['start']);
        }, (err) => {
          this.loginService.updateLoginStatus(false);
          this.loading = false;
          if (err.status == 404) {
            alert('Ha ocurrido un error en el servidor, por favor intente nuevamente');
          } else {
            alert('Disculpe no tiene permisos, para acceder al locker, por favor contacte con soporte de HMY');
          }
          console.log('error', err);
        })
      }
      
    }, (err) => {
      this.loginService.updateLoginStatus(false);
      this.loading = false;
      if (err.status == 404) {
        alert('Usuario o password invalidos');
      }
      console.log('error', err);
    })
  }

}
