import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PlatformService } from '../../../services/platform.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-configuration-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class ConfigurationUsersComponent implements OnInit {

  @ViewChild('addUser', { read: TemplateRef }) addUserTemplate:TemplateRef<any>;

  operationsUrl: any;
  message: string;
  messageError: boolean = false;
  loading: boolean = false;

  lockerId: string;
  client: string;
  flagInPort: any;
  typeOperationProcess: number = 1;

  userModel: any = {
    id: '',
    name: '',
    email: '',
    username: '',
    password: '',
    phoneNumber: '',
    notificationChannel: [],
    notificationChannelB: {
      sms: false,
      email: false
    },
    role: '',
    client: ''
  }
  users = [];
  userRole = {
    staffAll: 'CC y Consigna',
    staffCC: 'CC',
    staffConsigment: 'Consigna'
  };
  userOption = 1;
  userIndex = -1;

  modalRef: BsModalRef;

  @Input()
  get usersV(): any { return this.users; }
  set usersV(obj: any) {
    if (obj) {
      this.users = obj;
    }
  }

  constructor(
    private _platformService: PlatformService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.lockerId = localStorage.getItem('lockerid');
    this.client = localStorage.getItem('companyName');
  }

  cancel() {
    this.users = [];
    this.userModel = {
      id: '',
      name: '',
      email: '',
      username: '',
      password: '',
      phoneNumber: '',
      notificationChannel: [],
      notificationChannelB: {
        sms: false,
        email: false
      },
      role: '',
      client: ''
    };
  }

  editUser(user: any, index) {
    this.userModel = user;
    this.userOption = 2;
    this.userIndex = index;
    this.showModal(this.addUserTemplate);
  }

  removeUser(user: any, index: any) {
    console.log({index, user, users: this.users});
    this.users.splice(index, 1)
    console.log({usersAfter: this.users});
  }

  saveUser() {
    this.userModel.client = this.client;

    console.log({userModel: this.userModel});

    this.userModel.notificationChannelStr = this.userModel.notificationChannel.join(', ');
    this.userModel.roleStr = (this.userModel.role != '') ? this.userRole[this.userModel.role] : '';

    if(this.userOption == 1) {
      this.users.push(this.userModel);
    } else {
      this.users[this.userIndex] = this.userModel;
    }

    this.userModel = {
      id: '',
      name: '',
      email: '',
      username: '',
      password: '',
      phoneNumber: '',
      notificationChannel: [],
      notificationChannelB: {
        sms: false,
        email: false
      },
      role: '',
      client: ''
    };
    this.modalRef.hide();
  }

  async save() {

    const jsonData = {
      clientId: this.client,
      name: `${this.lockerId}`,
      lockerId: this.lockerId,
      listConfigMedia: [{
        name: `${this.lockerId}`,
        urlFile: '',
        extensionName: 'json',
        isExternalFile: true,
      }],
      users: this.users
    }

    console.log({jsonData});

    this.loading = true;
    this._platformService.createJSONs(jsonData).subscribe((res: any) => {
      alert('Configuracion seteada correctamente');
      this.loading = false;
    }, (err) => {
      this.loading = false;
    })
  }

  showModal(template: TemplateRef<any>, userOption = 0) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    if(userOption != 0) {
      this.userOption = userOption;
    }
  }

  changeNotification() {
    this.userModel.notificationChannel = [];
    if(this.userModel.notificationChannelB.sms) this.userModel.notificationChannel.push('sms');
    if(this.userModel.notificationChannelB.email) this.userModel.notificationChannel.push('email');
    console.log({notificationChannel: this.userModel.notificationChannel});
  }

}


