import { Router } from '@angular/router';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoginService } from '../../services/login.service';
import { SupportService } from '../../services/support.service';
import * as moment from 'moment';
import { PlatformService } from '../../services/platform.service';

@Component({
  selector: 'app-super-client',
  templateUrl: './super-client.component.html',
  styleUrls: ['./super-client.component.scss']
})
export class SuperClientComponent implements OnInit {

  modalRef: BsModalRef;
  loading: boolean = false;
  lockerid: string = '';
  users: any = [];
  titleOperation: string;
  selected: any = {
    client: {},
    clientId: '',
    clientName: '',
    email: '',
    password: '',
    password2: ''
  };

  clients:any = [];

  constructor(private router: Router, 
    private modalService: BsModalService, 
    private loginService: LoginService,
    private _platformService: PlatformService) { 
      this.titleOperation = 'Agregar';
      this.selected = {
        name: 'test', email: 'test@test.com', password: 'test', password2: 'test'
      }
    }

  ngOnInit(): void {
    console.log('ngOnInit');
    this.loadUsers();
    this.loadClients();
  }

  loadUsers() {
    this._platformService.getAllSuperClient().subscribe((res: any) => {
      console.log({res});
      this.users = res;
      console.log({users: this.users})
    })
  }

  loadClients() {
    this._platformService.getClients().subscribe((res: any) => {
      console.log({res});
      this.clients = [
        {id: -1, name: 'Seleccionar'}, ...res
      ];
    })
  }

  openModal(template: TemplateRef<any>, operation = 1) {
    this.selected = {};
    this.selected.operation = operation;
    this.selected.client = this.clients[0];
    this.selected.email = '';
    this.selected.clientId = '';
    this.selected.clientName = '';
    this.titleOperation = (operation == 1) ? 'Agregar' : 'Actualizar';
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  update(user: any, template: TemplateRef<any>) {
    this.openModal(template, 2);
    this.selected = user;
    this.selected.operation = 2;
  }

  getNameById(id) {
    console.log({id})
    for (const client of this.clients) {
      console.log({idSearch: id, idObject: client.id})
      if(client.id == id) {
        return client.name;
      }
    }
  }

  save() {

    if ((this.selected.client == -1) || (this.selected.email == '') || (this.selected.password == '') || (this.selected.password2 == ''))
    {
      alert('Todos los campos son requeridos');
      return
    }
    if ((this.selected.password) != (this.selected.password2)) {
      alert('Los passwords deben ser coincidentes');
      return;
    }

    console.log({client: this.selected.client})

    const data = Object.assign({}, this.selected);
    delete data.operation;
    delete data.password2;
    delete data.client;

    data.clientId = this.selected.client;
    data.clientName = this.getNameById(this.selected.client);
    
    console.log({data, step: 1});

    const method: any = (this.selected.operation == 1) ? this._platformService.addSuperClient(data) : this._platformService.addSuperClient(data);
    const text1 = (this.selected.operation == 1) ? 'agregado' : 'actualizado';
    const text2 = (this.selected.operation == 1) ? 'agregando' : 'actualizando';
    method.subscribe(res => {
      this.modalRef.hide();
      this.loadUsers();
      alert(`Super cliente ${text1} correctamente`);
    }, err => {
      console.log({err});
      if (err.error.statusCode == 409) {
        alert(`Existe un super cliente registrado con el email (${this.selected.email})`);
        return;
      }
      alert(`Ocrrio un error ${text2} el super cliente, por favor intente nuevamente`);
    })
  }

  delete(user: any) {
    if (confirm('Esta seguro que desea eliminar el super cliente?')) {
      this._platformService.deleteSuperClient(user.id).subscribe(res => {
        alert('Super cliente eliminado correctamente');
        this.loadUsers();
      }, err => {
        alert('Ocrrio un error elimando el super cliente, por favor intente nuevamente');
      })
    }
  }

}
