import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SupportService } from '../../services/support.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LockerStaffService } from '../../services/locker-staff.service';
import { PlatformService } from '../../services/platform.service';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-super-level3',
  templateUrl: './super-level3.component.html',
  styleUrls: ['./super-level3.component.scss']
})
export class SuperLevel3Component implements OnInit {
  loading: boolean = false;
  lockerId: string = '';
  titleOperation: string;
  clients: any[] = [{id: '', name: "Seleccionar"}];
  lockersId: any[] = [{id: '', name: "Seleccionar"}, {id: '1', name: 'Todo el parque de Lockers'}];
  clientSelected = '';
  showAccordion = false;
  selectedURL = '';
  selectedURLStr = '';
  selectedURLForDownload = '';
  selectedURLForDownloadStr = '';
  URLs = [];
  crondata: any = JSON.stringify({});
  crondataShow = false;

  resetRelay1: boolean = true;
  resetRelay2: boolean = false;

  userModel: any = {
    response: null,
    statusResponse: 0,
    users: [],
    selected: null,
    modal: null
  }
  hours: string[] = [];
  selectedHour: any;
  
  modalRef: BsModalRef;


	hoveredDate: NgbDate | null = null;

	fromDate: NgbDate | null;
	toDate: NgbDate | null;

  constructor(private router: Router, private _lockerStaffService: LockerStaffService, private modalService: BsModalService, private _platformService: PlatformService, private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    this.titleOperation = 'Agregar';
    this.toDate = calendar.getToday();
		this.fromDate = calendar.getPrev(calendar.getToday(), 'd', 10);
  }

  ngOnInit(): void {
    for (let index = 0; index <= 23; index++) {
      const hour = moment(index, 'H').format('HH:mm');
      this.hours.push(hour);
    }
    this._platformService.getClients().subscribe((res: any) => {
      this.clients = [ {id: '', name: "Seleccionar"}, ...res];
    })
  }

  getUsers(saveIncidence: boolean = true) {
    this.loading = true;
    this._lockerStaffService.getAllUsers(this.lockerId, saveIncidence).subscribe((res: any) => {
      this.loading = false;
      console.log('success', res);
      this.userModel.selected = null;
      this.userModel.users = res;
      this.userModel.statusResponse = 200;
    }, (err) => {
      this.userModel.users = [];
      this.userModel.response = 'NO OK';
      this.userModel.statusResponse = err.status;
      this.loading = false;
      console.log('error', err);
    })
  }

  goBack() {
    this.router.navigate(['advanced']);
  }

  showModal(template) {
    this.userModel.modal = {};
    this.userModel.modal.id = '';
    this.userModel.modal.username = '';
    this.userModel.modal.name = '';
    this.userModel.modal.email = '';
    this.userModel.modal.phoneNumber = '';
    this.userModel.modal.password = '';
    this.userModel.modal.password2 = '';
    this.userModel.modal.role = '';
    this.userModel.modal.notificationChannel = '';
    this.modalRef = this.modalService.show(template);
  }
  
  add(template) {
    this.titleOperation = 'Agregar';
    this.showModal(template);
  }

  update(template) {
    this.titleOperation = 'Modificar';
    this.showModal(template);
    this.userModel.modal = this.userModel.selected;
  }

  save() {
    
    let user = this.userModel.modal;

    if (user.id == '' || user.name == '' || user.email == '' || user.password == '' || user.role == '' || user.notificationChannel == '') {
      alert('Todos los campos son requeridos');
      return;
    }

    if (user.password != user.password2) {
      alert('Los passwords deben ser coincidentes');
      return;
    }
    
    if (user.notificationChannel != 'disabled') {
      user.notificationChannel = (user.notificationChannel != 'sms') ? user.notificationChannel.split(',') : ['sms'];
    } else {
      user.notificationChannel = [];
    }

    let data: any = {
      lockerId: this.lockerId,
    }
    data = Object.assign(data, user);
    delete data.password2;

    const method = (this.titleOperation == 'Agregar') ? this._lockerStaffService.createUser(data) : this._lockerStaffService.updateUser(data);
    this.loading = true;
    method.subscribe((res: any) => {
      this.loading = false;
      if(res.error) {
        alert('Ocurrio un error inesperado, por favor intente nuevamente');
      } else {
        const textOperation = (this.titleOperation == 'Agregar') ? 'agregado' : 'modificado';
        alert(`Usuario ${textOperation} correctamente`);
        this.modalRef.hide();
        this.getUsers(false);
      }
    }, err => {
      alert('Ocurrio un error inesperado, por favor intente nuevamente');
    })
  }

  delete() {
    if (confirm(`Está seguro que desea eliminar el usuario (${this.userModel.selected.email})?`)) {
      const data = {
        lockerId: this.lockerId, 
        id: this.userModel.selected.id
      }
      this._lockerStaffService.deleteUser(data).subscribe(res => {
        alert('Usuario eliminado correctamente');
        this.getUsers(false);
      }, err => {
        alert('Error eliminando el usuario');
      })
    }
  }

  download(type) {
    this._platformService.getJSON(this.lockerId, type).subscribe((res: any) => {
      console.log({res});
      this.downloadJSON(res, `${this.lockerId}-${type}`);
    }, (Err) => {
      console.log({Err});
      alert('No se consiguio configuracion');
    })
  }

  downloadCronLogs() {
    console.log(this.selectedURLForDownload);
    console.log(this.selectedURLForDownloadStr);
    const data = {
      supportUrl: this.selectedURLForDownloadStr,
      lockerId: this.lockerId
    }
    this._platformService.getCronLogs(data).subscribe((res: any) => {
      this.downloadJSON(res, `${this.lockerId}-CronLogs`);
    }, (err) => {
      console.log({err});
    })
  }

  resetLocker() {
    const data = {
      lockerId: this.lockerId,
      hour: parseInt(moment(this.selectedHour, 'HH:mm').format('H')),
      supportUrl: this.selectedURLStr,
      data: JSON.parse(this.crondata),
      clientId: this.clientSelected
    }
    this._platformService.createCron(data).subscribe((res: any) => {
      console.log({res});
      alert('Cron creado correctamente');
    }, (Err) => {
      alert('Ocurrio un error creando el cron')
    })
  }

  next() {
    this.router.navigate(['end']);
  }

  getLogs() {

  }

  onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

	validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input);
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
	}

  changeClient() {  
    this._platformService.getLogsByLockerId({
      clientId: this.clientSelected
    }).subscribe((res: any) => {
      const locks = res.lockers.map((lv) => {
        return { id: lv.lockerId, name: lv.lockerId };
      });
      this.lockersId = [
        {id: '', name: "Seleccionar"}, {id: '1', name: 'Todo el parque de Lockers'}, ...locks
      ];
    }, (err) => {
      console.log(err);
    });
  }

  changeConfig() {
    if (this.lockerId == '') {
      this.showAccordion = false;
    } else {
      this.showAccordion = true;
    }
  }

  getCronURLs() {
    this._platformService.getCronsUrls().subscribe((res: any) => {
      this.URLs = res;
      let data = {};
      this.crondata = JSON.stringify(data);
    }, (err) => {
      console.log(err)
    })
  }

  changeURL(selectedURLKey: string, selectedURLStrKey: string, crondataEnabled = true) {
    if (crondataEnabled) {
      if(this[selectedURLKey] == '') {
        this.crondataShow = false;
      } else {
        this.crondataShow = true;
      }
      this.crondata = {};
    }
    this.URLs.map((urlv: any) => {
      if (urlv.id == this[selectedURLKey]) {
        this[selectedURLStrKey] = urlv.url;
        if (crondataEnabled) {
          urlv.keys.map((key) => {
            this.crondata[key] = this[key]
          });
        }
        
      }
    })
    if (crondataEnabled) {
      this.crondata = JSON.stringify(this.crondata, null, 4);
    }
  }

  downloadLogs() {
    const data = {
      lockerId: this.lockerId,
      dateFrom: moment(`${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`, 'YYYY-M-D').format('YYYY-MM-DD'),
      dateTo: moment(`${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`, 'YYYY-M-D').format('YYYY-MM-DD')
    }
    this._platformService.getLogsEventsByClientIdAndDate(data).subscribe((res: any) => {
      console.log({res});
      this.downloadJSON(res, `logs-${this.lockerId}`);
    }, (err) => {
      console.log({err})
    })
  }

  downloadJSON(data: string, nameFile: string) {
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data));
    var dlAnchorElem = document.getElementById('downloadAnchorElem');
    dlAnchorElem.setAttribute("href", dataStr);
    dlAnchorElem.setAttribute("download", `${nameFile}.json`);
    dlAnchorElem.click();
  }

}
