<div class="row justify-content-md-center pt-3">
  <div class="row" >
    <div class="col-md-6">
      <p class="title">Host Name:</p>
      <div class="row justify-content-md-center">
        <div class="col-md-11">
          <input class="form-control" type="text" name="hostName" [(ngModel)]="networkModel.hostName" id="hostName" [value]="2">
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <p class="title">DHCP Management</p>
      <div class="row">
        <div class="col-md-6">
          <label for="dhcpManagementType" class="form-label">Client Wired Network</label>
          <input type="radio" name="dhcpManagementType" [(ngModel)]="networkModel.dhcpManagementType" id="dhcpManagementType" [value]="1">
        </div>
        <div class="col-md-6">
          <label for="dhcpManagementType2" class="form-label">4G Router</label>
          <input type="radio" name="dhcpManagementType" [(ngModel)]="networkModel.dhcpManagementType" id="dhcpManagementType2" [value]="2">
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <p class="title">DHCP Management:</p>
      <div class="row justify-content-md-center">
        <div class="col-md-3">
          <label for="dhcpManagement" class="form-label">DHCP</label>
          <input type="radio" name="dhcpManagement" [(ngModel)]="networkModel.dhcpManagement" id="dhcpManagement" [value]="1">
        </div>
        <div class="col-md-8">
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-3">
          <label for="dhcpManagement2" class="form-label">Fixed IP</label>
          <input type="radio" name="dhcpManagement" [(ngModel)]="networkModel.dhcpManagement" id="dhcpManagement2" [value]="2">
        </div>
        <div class="col-md-8">
          <input type="text" id="fixedIp" class="form-control" [(ngModel)]="networkModel.fixedIp">
        </div>
      </div>
      <p class="title">VPN:</p>
      <div class="row justify-content-md-center">
        <div class="col-md-3">
          <label for="vpnip" class="form-label">VPN IP</label>
        </div>
        <div class="col-md-8">
          <input type="text" id="vpnip" class="form-control" [(ngModel)]="networkModel.vpnip">
        </div>
      </div>
      <p class="title">ANYDESK:</p>
      <div class="row justify-content-md-center">
        <div class="col-md-3">
          <label for="anydesk" class="form-label">NUC ID</label>
        </div>
        <div class="col-md-8">
          <input type="text" id="anydesk" class="form-control" [(ngModel)]="networkModel.anydesk">
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <p class="title">Client Network Info:</p>
      <div class="row justify-content-md-center">
        <div class="col-md-3">
          <label for="gateway" class="form-label">Gateway:</label>
        </div>
        <div class="col-md-8">
          <input type="text" id="gateway" class="form-control" [(ngModel)]="networkModel.gateway">
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-3">
          <label for="mask" class="form-label">Mask:</label>
        </div>
        <div class="col-md-8">
          <input type="text" class="form-control" id="mask" [(ngModel)]="networkModel.mask">
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-3">
          <label for="dns" class="form-label">DNS</label>
        </div>
        <div class="col-md-8">
          <input type="text" class="form-control" id="dns" [(ngModel)]="networkModel.dns">
        </div>
      </div>
      <p class="title">Ports Management:</p>
      <p>Please confirm with the client that the following ports are available in this network thru the Lockers Standar Network Requeriments form. Tick them when done.</p>
      <div class="row">
        <div class="col-md-4">
          <label for="portsmanagement1" class="form-label">1194 TCP OUT</label>
          <input type="checkbox" name="portsmanagement1" [(ngModel)]="networkModel.portsmanagement.tcpout1194" id="portsmanagement1" >
        </div>
        <div class="col-md-4">
          <label for="portsmanagement2" class="form-label">80 TCP OUT</label>
          <input type="checkbox" name="portsmanagement2" [(ngModel)]="networkModel.portsmanagement.router4G" id="portsmanagement2" >
        </div>
        <div class="col-md-4">
          <label for="portsmanagement3" class="form-label">1883 TCP OUT</label>
          <input type="checkbox" name="portsmanagement3" [(ngModel)]="networkModel.portsmanagement.tcpout1883" id="portsmanagement3" >
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <label for="portsmanagement4" class="form-label">53 TCP/UDP OUT</label>
          <input type="checkbox" name="portsmanagement4" [(ngModel)]="networkModel.portsmanagement.tcpout53" id="portsmanagement4" >
        </div>
        <div class="col-md-4">
          <label for="portsmanagement5" class="form-label">443 TCP OUT</label>
          <input type="checkbox" name="portsmanagement5" [(ngModel)]="networkModel.portsmanagement.tcpout443" id="portsmanagement5" >
        </div>
        <div class="col-md-4">
          <label for="portsmanagement6" class="form-label">3013 TCP OUT</label>
          <input type="checkbox" name="portsmanagement6" [(ngModel)]="networkModel.portsmanagement.tcpout3013" id="portsmanagement6" >
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-md-12 text-end">
      <button class="btn btn-primary ms-2" [disabled]="loading" (click)="cancel()">Cancelar</button>
      <button class="btn btn-success ms-2" [disabled]="loading" (click)="save()">Guardar</button>
    </div>
  </div>
</div>
<div class="spinner" [hidden]="!loading">
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>  
</div>