import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { SupportService } from '../../services/support.service';
import { UtilsService } from '../../services/utils.service';
import * as moment from 'moment';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {

  operationsUrl: any;
  message: string;
  messageError: boolean = false;
  loading: boolean = false;

  statusResponsePing: number;
  responsePing: string = '';
  pingError: boolean = false;

  statusResponseDiagnostic: number;
  responseDiagnostic: string = '';
  diagnosticError: boolean = false;
  
  lockerId: string;
  flagInPort: any;
  typeOperationProcess: number = 1;

  constructor(private router: Router, private _supportService: SupportService, private _utilsService: UtilsService, private _loginService: LoginService) { }

  async ngOnInit(): Promise<void> {
    const token = localStorage.getItem('token');
    this.lockerId = localStorage.getItem('lockerid');
    this.message = (localStorage.getItem('start_message')) ? localStorage.getItem('start_message') : '';
    this.statusResponsePing = (localStorage.getItem('ping_status')) ? parseInt(localStorage.getItem('ping_status')) : null;
    this.statusResponseDiagnostic = (localStorage.getItem('diagnostic_status')) ? parseInt(localStorage.getItem('diagnostic_status')) : null;
    console.log(this.getMinutes(moment().toDate(), moment('2022-06-28 16:54:00').toDate()));
    if (localStorage.getItem('step') == '1') {
      this.loading = true;
      await this.pingLocker();
      await this.getDiagnostic();
      this.loading = false;
    } else {
      this.router.navigate(['support']);
    }
  }

  pingLocker() {
    return new Promise((resolve) => {
      this.loading = true;
      this._supportService.ping(this.lockerId).subscribe((res: any) => {
        this.pingError = false;
        this.loading = false;
        console.log('success', res);
        this.responsePing = (res.pingResponse) ? 'Online' : 'Offline';
        localStorage.setItem('ping', this.responsePing);
        this._loginService.updatePingResult(this.responsePing);
        this.statusResponsePing = 200;
        resolve(true);
      }, (err) => {
        this.responsePing = JSON.stringify(err, null, 4);
        this.statusResponsePing = err.status;
        this.loading = false;
        this._loginService.updatePingResult('NO OK');
        localStorage.setItem('ping', 'NO OK');
        console.log('error', err);
        resolve(false);
      })
    })
    
  }

  getDiagnostic() {
    return new Promise((resolve) => {
      this.loading = true;
      this._supportService.getDiagnostic(this.lockerId).subscribe((res: any) => {
        this.diagnosticError = false;
        this.loading = false;
        console.log('success', res);
        //this.responseDiagnostic = JSON.stringify(res, null, 4);
        if (res.info) {
          let infoData = res.info.split("\n").join("<br>");
          infoData = infoData.split("<----->").join("-");
          infoData = this._utilsService.processDiagnostic(infoData);
          this._loginService.updateDiagnosticResult('OK');
          localStorage.setItem('diagnostic', 'OK');
          this.responseDiagnostic = infoData
          this.statusResponseDiagnostic = 200;
          resolve(true);
        } else {
          this._loginService.updateDiagnosticResult('NO OK');
          localStorage.setItem('diagnostic', 'NO OK');
          this.statusResponseDiagnostic = 200;
        }
        
        
      }, (err) => {
        this.responseDiagnostic = JSON.stringify(err, null, 4);
        this.statusResponseDiagnostic = err.status;
        this.loading = false;
        this._loginService.updateDiagnosticResult('NO OK');
        localStorage.setItem('diagnostic', 'NO OK');
        console.log('error', err);
        resolve(false);
      })
    })
  }

  goBack() {
    this.router.navigate(['home']);
  }

  next() {
    if (this.message == '') {
      this.messageError = true;
    }
    if (!this.messageError) {
      localStorage.setItem('start_message', this.message);
      localStorage.setItem('ping_status', this.statusResponsePing.toString());
      localStorage.setItem('diagnostic_status', this.statusResponseDiagnostic.toString());
      this.loading = true;
      this._supportService.saveStartMessage(this.message).subscribe((res: any) => {
        this.loading = false;
        this.router.navigate(['support']);
      }, (err: any) => {
        this.loading = false;
        this.router.navigate(['support']);
      })
    }
  }

  clearErr(type: string) {
    this.messageError = false;
  }

  getMinutes(dt1, dt2) {
    var diff =(dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

}
