<section class="vh-100" style="background-color: #508bfc;">
    <div class="container py-5 h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
          <div class="card shadow-2-strong" style="border-radius: 1rem;">
            <div class="card-body p-5 text-center">

              <img [src]="logoHMY" alt="">
  
              <h3 class="mb-5">Acceso a Interfaz de Soporte</h3>
  
              <div class="form-outline mb-4">
                <input type="email" id="typeEmailX-2" [(ngModel)]="email" placeholder="test@test.com" class="form-control" />
                <label class="form-label" for="typeEmailX-2">Email</label>
              </div>
  
              <div class="form-outline mb-4">
                <input type="password" id="typePasswordX-2" [(ngModel)]="password" placeholder="****" class="form-control" />
                <label class="form-label" for="typePasswordX-2">Password</label>
              </div>

              <div class="form-outline mb-4">
                <input type="text" id="lockerid" [(ngModel)]="lockerid" placeholder="" class="form-control" />
                <label class="form-label" for="lockerid">Id de Mueble</label>
              </div>

              <button class="btn btn-primary btn-block" [disabled]="loading" (click)="login()">Abrir Incidencia</button>
  
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="spinner" [hidden]="!loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>  
    </div>
  </section>