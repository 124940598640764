import { Router } from '@angular/router';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoginService } from 'src/app/services/login.service';
import { SupportService } from 'src/app/services/support.service';
import * as moment from 'moment';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  modalRef: BsModalRef;
  loading: boolean = false;
  lockerid: string = '';
  users: any = [];
  titleOperation: string;
  selected: any;

  constructor(private router: Router, 
    private modalService: BsModalService, 
    private loginService: LoginService,
    private supportService: SupportService) { 
      this.titleOperation = 'Agregar';
      this.selected = {
        name: 'test', email: 'test@test.com', password: 'test', password2: 'test'
      }
    }

  ngOnInit(): void {
    console.log('ngOnInit');
    this.loadUsers();
  }

  loadUsers() {
    this.supportService.getAllUsers().subscribe((res: any) => {
      console.log({res});
      this.users = res;
    })
  }

  openModal(template: TemplateRef<any>, operation = 1) {
    this.selected = {};
    this.selected.operation = operation;
    this.selected.name = '';
    this.selected.email = '';
    this.titleOperation = (operation == 1) ? 'Agregar' : 'Actualizar';
    this.modalRef = this.modalService.show(template);
  }

  update(user: any, template: TemplateRef<any>) {
    this.openModal(template, 2);
    this.selected = user;
    this.selected.operation = 2;
  }

  save() {

    if ((this.selected.name == '') || (this.selected.email == '') || (this.selected.password == '') || (this.selected.password2 == ''))
    {
      alert('Todos los campos son requeridos');
      return
    }
    if ((this.selected.password) != (this.selected.password2)) {
      alert('Los passwords deben ser coincidentes');
      return;
    }

    const data = Object.assign({}, this.selected);
    delete data.operation;
    delete data.password2;

    const method = (this.selected.operation == 1) ? this.supportService.createUser(data) : this.supportService.updateUser(data);
    const text1 = (this.selected.operation == 1) ? 'agregado' : 'actualizado';
    const text2 = (this.selected.operation == 1) ? 'agregando' : 'actualizando';
    method.subscribe(res => {
      this.modalRef.hide();
      this.loadUsers();
      alert(`Usuario ${text1} correctamente`);
    }, err => {
      console.log({err});
      if (err.error.statusCode == 409) {
        alert(`Existe un usuario registrado con el email (${this.selected.email})`);
        return;
      }
      alert(`Ocrrio un error ${text2} el usuario, por favor intente nuevamente`);
    })
  }

  delete(user: any) {
    if (confirm('Esta seguro que desea eliminar el usuario?')) {
      this.supportService.deleteUser(user.id).subscribe(res => {
        alert('Usuario eliminado correctamente');
        this.loadUsers();
      }, err => {
        alert('Ocrrio un error elimando el usuario, por favor intente nuevamente');
      })
    }
  }

}
