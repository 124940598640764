
<div class="container mt-4">
  <div class="accordion" id="accordionExample">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#statusCabinet" aria-expanded="true" aria-controls="collapseOne">
          Estado del mueble (ping)
        </button>
      </h2>
      <div id="statusCabinet" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <div class="row" *ngIf="responsePing != ''">
            <div class="col-md-12">
              <p>Response</p>
              <p>Status: {{statusResponsePing}}</p>
              <pre class="bg-light">{{responsePing}}</pre>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-end">
              <button class="btn btn-success ms-2" [disabled]="loading" (click)="pingLocker()">Enviar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#diagnostic" aria-expanded="true" aria-controls="collapseOne">
          Diagnostico
        </button>
      </h2>
      <div id="diagnostic" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <div class="row" *ngIf="responseDiagnostic != ''">
            <div class="col-md-12">
              <p>Response</p>
              <p>Status: {{statusResponseDiagnostic}}</p>
              <div [innerHtml]="responseDiagnostic" class="bg-light"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-end">
              <button class="btn btn-success ms-2" [disabled]="loading" (click)="getDiagnostic()">Enviar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#lockerOcupation" aria-expanded="true" aria-controls="collapseOne">
          Estado de ocupacion del mueble
        </button>
      </h2>
      <div id="lockerOcupation" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <div class="row">
            <div class="col-md-12 text-end">
              <button class="btn btn-success ms-2" [disabled]="loading" (click)="getLockerOccupation()">Enviar</button>
            </div>
          </div>
          <div class="row" *ngIf="lockerOccupationModel.locks">
            <div class="col-md-12">
              <p>Response</p>
              <p>Status: {{lockerOccupationModel.statusResponse}}</p>
              <table class="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Coordenadas</th>
                    <th>Tipo</th>
                    <th>Tamaño</th>
                    <th>Frio</th>
                    <th>Orden</th>
                    <th>Estado</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let occupation of lockerOccupationModel.locks">
                    <td><input type="radio" name="occupation.selected" [value]="occupation" [(ngModel)]="lockerOccupationModel.selected" (ngModelChange)="changeLockerOccupation()" /></td>
                    <td>{{occupation.y}}{{occupation.x}}</td>
                    <td>{{(occupation.businessType == 'cc') ? 'Click & Collect' : 'Consigna'}}</td>
                    <td>{{occupation.size}}</td>
                    <td>{{(occupation.cold) ? 'Si' : 'No'}}</td>
                    <td>{{(occupation.order) ? occupation.order : '-'}}</td>
                    <td>{{(occupation.status == 'free') ? 'Libre' : ((occupation.status == 'busy') ? 'Ocupado' : 'Bloqueado')}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="buttons text-end" *ngIf="lockerOccupationModel.locks && lockerOccupationModel.locks.length > 0">
            <button class="btn btn-primary ms-2" [disabled]="loading" (click)="openLocker()">Abrir</button>
            <button class="btn btn-primary ms-2" [disabled]="loading || lockerOccupationModel.disabledOperation" (click)="disableLocker()">{{lockerOccupationModel.titleOperation}}</button>
          </div>
          <div class="row" *ngIf="lockerOccupationModel.response">
            <div class="col-md-12">
              <p>Response</p>
              <p>Status: {{lockerOccupationModel.statusResponse}}</p>
              <pre class="bg-light">{{lockerOccupationModel.response}}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#findConsigna" aria-expanded="true" aria-controls="collapseOne">
          Localizador de consignas
        </button>
      </h2>
      <div id="findConsigna" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <div class="row">
            <div class="col-md-6">
              <label for="searchConsignmentModel.type" class="form-label">Buscar por:</label>
              <select name="searchConsignmentModel.type" [(ngModel)]="searchConsignmentModel.type" id="searchConsignmentModel.type" class="form-control">
                <option value="">Seleccionar</option>
                <option value="phone">Telefono</option>
                <option value="code">Codigo de consigna</option>
                <option value="coordinates">Coordenadas</option>
              </select>
            </div>
            <div class="col-md-6" *ngIf="searchConsignmentModel.type == 'phone'">
              <label for="searchPhone" class="form-label">Telefono:</label>
              <input class="form-control" [ngClass]="{'required-error-input': !searchConsignmentModel.flagValid && searchConsignmentModel.phone == ''}"  type="text" name="searchConsignmentModel.phone" [(ngModel)]="searchConsignmentModel.phone" id="searchConsignmentModel.phone">
            </div>
            <div class="col-md-6" *ngIf="searchConsignmentModel.type == 'code'">
              <label for="searchCode" class="form-label">Code:</label>
              <input class="form-control" [ngClass]="{'required-error-input': !searchConsignmentModel.flagValid && searchConsignmentModel.code == ''}" type="text" name="searchConsignmentModel.code" [(ngModel)]="searchConsignmentModel.code" id="searchConsignmentModel.code">
            </div>
            <div class="col-md-6" *ngIf="searchConsignmentModel.type == 'coordinates'">
              <label for="searchConsignmentModel.coordinates" class="form-label">Coordenadas:</label>
              <input class="form-control" [ngClass]="{'required-error-input': !searchConsignmentModel.flagValid && searchConsignmentModel.coordinates == ''}" type="text" name="searchConsignmentModel.coordinates" [(ngModel)]="searchConsignmentModel.coordinates" id="searchConsignmentModel.coordinates">
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-end">
              <button class="btn btn-success ms-2" [disabled]="loading" (click)="searchConsignment()">Buscar</button>
            </div>
          </div>
          <div class="row" *ngIf="consignments.length > 0">
            <div class="col-md-12">
              <p>Response</p>
              <p>Status: {{searchConsignmentModel.status}}</p>
              <div>
                <table class="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Coordenadas</th>
                      <th>Contacto</th>
                      <th>Expiracion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let consignment of consignments">
                      <td><input type="radio" name="searchConsignmentModel.selected" [value]="consignment.code" [(ngModel)]="searchConsignmentModel.selected" /></td>
                      <td>{{consignment.coordinates.join(', ')}}</td>
                      <td>{{(consignment.phone) ? consignment.phone : ((consignment.email) ? consignment.email : '' )}}</td>
                      <td>{{consignment.expirationDate}}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="buttons text-end" *ngIf="consignments.length > 0">
                  <button class="btn btn-primary ms-2" [disabled]="loading" (click)="openOrder('consignment')">Abrir</button>
                  <button class="btn btn-primary ms-2" [disabled]="loading" (click)="retireOrder('consignment')">Retirar</button>
                  <button class="btn btn-primary ms-2" [disabled]="loading" (click)="sendNotificationOrder('consignment')">Reenviar Mensaje</button>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="searchConsignmentModel.response">
            <div class="col-md-12">
              <p>Response</p>
              <p>Status: {{searchConsignmentModel.statusResponse}}</p>
              <pre class="bg-light">{{searchConsignmentModel.response}}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#findOrder" aria-expanded="true" aria-controls="collapseOne">
          Localizador de pedidos - Click & Collect
        </button>
      </h2>
      <div id="findOrder" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <div class="row">
            <div class="col-md-6">
              <label for="searchCCModel.type" class="form-label">Buscar por:</label>
              <select name="searchCCModel.type" [(ngModel)]="searchCCModel.type" id="searchCCModel.type" class="form-control">
                <option value="">Seleccionar</option>
                <option value="phone">Telefono</option>
                <option value="orderid">Orden Id</option>
                <option value="coordinates">Coordenadas</option>
              </select>
            </div>
            <div class="col-md-6" *ngIf="searchCCModel.type == 'phone'">
              <label for="searchCCModel.phone" class="form-label">Telefono:</label>
              <input class="form-control" [ngClass]="{'required-error-input': !searchCCModel.flagValid && searchCCModel.phone == ''}"  type="text" name="searchCCModel.phone" [(ngModel)]="searchCCModel.phone" id="searchCCModel.phone">
            </div>
            <div class="col-md-6" *ngIf="searchCCModel.type == 'orderid'">
              <label for="searchCCModel.orderId" class="form-label">Orden ID:</label>
              <input class="form-control" [ngClass]="{'required-error-input': !searchCCModel.flagValid && searchCCModel.orderId == ''}" type="text" name="searchCCModel.orderId" [(ngModel)]="searchCCModel.orderId" id="searchCCModel.orderId">
            </div>
            <div class="col-md-6" *ngIf="searchCCModel.type == 'coordinates'">
              <label for="searchCCModel.coordinates" class="form-label">Coordenadas:</label>
              <input class="form-control" [ngClass]="{'required-error-input': !searchCCModel.flagValid && searchCCModel.coordinates == ''}" type="text" name="searchCCModel.coordinates" [(ngModel)]="searchCCModel.coordinates" id="searchCCModel.coordinates">
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-end">
              <button class="btn btn-success ms-2" [disabled]="loading" (click)="searchCC()">Buscar</button>
            </div>
          </div>
          <div class="row" *ngIf="ccs.length > 0">
            <div class="col-md-12">
              <p>Response</p>
              <p>Status: {{searchCCModel.statusResponse}}</p>
              <div>
                <table class="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Coordenadas</th>
                      <th>Contacto</th>
                      <th>Expiracion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let cc of ccs">
                      <td><input type="radio" name="searchCCModel.selected" [value]="cc.orderId" [(ngModel)]="searchCCModel.selected" /></td>
                      <td>{{cc.coordinates.join(', ')}}</td>
                      <td>{{(cc.phone) ? cc.phone : ((cc.email) ? cc.email : '' )}}</td>
                      <td>{{cc.expirationDate}}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="buttons text-end" *ngIf="ccs.length > 0">
                  <button class="btn btn-primary ms-2" [disabled]="loading" (click)="openOrder('cc')">Abrir</button>
                  <button class="btn btn-primary ms-2" [disabled]="loading" (click)="retireOrder('cc')">Retirar</button>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="searchCCModel.response">
            <div class="col-md-12">
              <p>Response</p>
              <p>Status: {{searchCCModel.statusResponse}}</p>
              <pre class="bg-light">{{searchCCModel.response}}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#resetLocker" aria-expanded="true" aria-controls="collapseOne">
          Reiniciar mueble
        </button>
      </h2>
      <div id="resetLocker" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <div class="row" *ngIf="resetLockerModel.response != ''">
            <div class="col-md-12">
              <p>Response</p>
              <p>Status: {{resetLockerModel.statusResponse}}</p>
              <div [innerHtml]="resetLockerModel.response" class="bg-light"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-end">
              <button class="btn btn-success ms-2" [disabled]="loading" (click)="resetLocker()">Enviar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#resetScreen" aria-expanded="true" aria-controls="collapseOne">
          Reiniciar pantalla
        </button>
      </h2>
      <div id="resetScreen" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <div class="row" *ngIf="resetScreenModel.response != ''">
            <div class="col-md-12">
              <p>Response</p>
              <p>Status: {{resetScreenModel.statusResponse}}</p>
              <div [innerHtml]="resetScreenModel.response" class="bg-light"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-end">
              <button class="btn btn-success ms-2" [disabled]="loading" (click)="resetScreen()">Enviar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#validateSMS" aria-expanded="true" aria-controls="collapseOne">
          Validar SMS
        </button>
      </h2>
      <div id="validateSMS" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <div class="row">
            <div class="col-md-6">
              <label for="validateSMSModel.phone" class="form-label">Telefono:</label>
              <input class="form-control" (change)="changePhone()" [ngClass]="{'required-error-input': validateSMSModel.flagValid == false && validateSMSModel.sended == true}"  type="text" name="validateSMSModel.phone" [(ngModel)]="validateSMSModel.phone" id="validateSMSModel.phone">
            </div>
            <div class="col-md-6">
              <label for="validateSMSModel.phone" class="form-label">Lenguaje:</label>
              <select name="validateSMSModel.lang" (change)="changeLang()"  [ngClass]="{'required-error-input': validateSMSModel.flagValidLang == false && validateSMSModel.sended == true}"   [(ngModel)]="validateSMSModel.lang" id="validateSMSModel.lang" class="form-control">
                <option value="">Seleccionar</option>
                <option value="es">Español</option>
                <option value="en">Inglés</option>
                <option value="fr">Francés</option>
              </select>
            </div>
          </div>
          <div class="row" *ngIf="validateSMSModel.response != ''">
            <div class="col-md-12">
              <p>Response</p>
              <p>Status: {{validateSMSModel.statusResponse}}</p>
              <div [innerHtml]="validateSMSModel.response" class="bg-light"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-end">
              <button class="btn btn-success ms-2" [disabled]="loading" (click)="validateSMS()">Enviar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="text-end mt-4 mb-4">
      <button class="btn btn-primary ms-2" (click)="next()" [disabled]="loading">Siguiente</button>
    </div>
  </div>
  <div class="spinner" [hidden]="!loading">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>  
  </div>
</div>