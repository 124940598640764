import { Component, Input, OnInit } from '@angular/core';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
  selector: 'app-configuration-specs',
  templateUrl: './specs.component.html',
  styleUrls: ['./specs.component.scss']
})
export class ConfigurationSpecsComponent implements OnInit {

  operationsUrl: any;
  message: string;
  messageError: boolean = false;
  loading: boolean = false;

  lockerId: string;
  client: string;
  flagInPort: any;
  typeOperationProcess: number = 1;

  languages = [
    { id: 'es', name: 'Español (ES)' }, 
    { id: 'en', name: 'Ingles (EN)' },
    { id: 'fr', name: 'Frances (FR)' }
  ];
  bussinessTypes = [
    { id: 'cc', name: 'Click & Collect' },
    { id: 'consigna', name: 'Consigna' }
  ];
  multipleLanguages = true;
  closeOnSelect = false;

  @Input()
  get specModelV(): any { return this.specModel; }
  set specModelV(obj: any) {
    if (obj) {
      this.specModel = obj;
      setTimeout(() => {
        this.specModel.showScreenSaverLogo = (this.specModel.showScreenSaverLogo) ? 'Si' : 'No';
        this.specModel.webhookEnable = (this.specModel.webhookEnable) ? 'Si' : 'No';
        this.specModel.showCustomerSection = (this.specModel.showCustomerSection) ? 'Si' : 'No';
        this.specModel.screenSaverElement = (!this.specModel.screenSaverShow) ? 'desactivado' : this.specModel.screenSaverElement;
      }, 500)
    }
  }

  specModel: any = {
    lockerLocation: '',
    timeOutSS: '',
    locksPerBoard: 8,
    boardsNumber: '',
    descriptionLocker: '',
    screenSaverShow: '',
    screenSaverElement: '',
    showScreenSaverQR: '',
    showScreenSaverLogo: '',
    showCustomerSection: '',
    supportContactPhone: '',
    webhookEnable: '',
    webhookURL: '',
    languages: [],
    languageSelected: '',
    bussinessTypes: []
  };

  locksPerBoards = [{
      id: 8,
      name: 'Driver8LOCKS'
    }, {
      id: 12,
      name: 'Driver12LOCKS'
  }]

  constructor(
    private _platformService: PlatformService
  ) { }

  ngOnInit(): void {
    this.lockerId = localStorage.getItem('lockerid');
    this.client = localStorage.getItem('companyName');
  }

  async save() {

    let flagValid = false;
    const data = {
      lockerId: this.lockerId
    };

    for (const key of Object.keys(this.specModel)) {
      let value = this.specModel[key];
      if(value != '') {
        if (['showScreenSaverQR', 'showScreenSaverLogo', 'showCustomerSection', 'webhookEnable'].indexOf(key) !== -1) {
          if (value === 'Si') {
            value = true;
          } else if (value === 'No') {
            value = false;
          }
        }
        flagValid = true;
        if (key === 'screenSaverElement' && value != '') {
          if (value == 'desactivado') {
            data['screenSaverShow'] = false;
            continue;
          } else {
            data['screenSaverShow'] = true;
          }
        }
        data[key] = value
      }
    }
    if(!flagValid) {
      alert('Debe rellenar al menos un campo');
      return;
    }

    console.log('data', data);

    const jsonData = {
      name: `${this.lockerId}`,
      clientId: this.client,
      lockerId: this.lockerId,
      listConfigMedia: [{
        name: `${this.lockerId}`,
        urlFile: '',
        extensionName: 'json',
        isExternalFile: true
      }],
      specs: {
        ...data
      }
    }

    console.log({data, jsonData, client: this.client});

    this.loading = true;
    this._platformService.createJSONs(jsonData).subscribe((res: any) => {
      alert('Configuracion seteada correctamente');
      this.loading = false;
    }, (err) => {
      this.loading = false;
    })

  }

  cancel() {
    this.specModel = {
      lockerLocation: '',
      timeOutSS: '',
      locksPerBoard: '',
      boardsNumber: '',
      descriptionLocker: '',
      screenSaverShow: '',
      screenSaverElement: '',
      showScreenSaverQR: '',
      showScreenSaverLogo: '',
      showCustomerSection: '',
      supportContactPhone: '',
      webhookEnable: '',
      webhookURL: '',
      languages: [],
      languageSelected: '',
      bussinessTypes: []
    };
  }

}
