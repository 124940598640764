<div class="row justify-content-md-center pt-3">
    <div class="col col-lg-12">
        <label for="message">Mensaje:</label>
        <textarea name="message" class="form-control" [(ngModel)]="message" id="" rows="8"></textarea>
        <label for="status">Estado de Incidencia:</label>
        <select name="status" id="status" class="form-control" [(ngModel)]="status">
            <option value="">Seleccionar</option>
            <option value="resuelta">Resuelta</option>
            <option value="noresuelta">No Resuelta</option>
            <option value="cancelada">Cancelada</option>
        </select>
    </div>
    <div class="text-end mt-4 mb-4">
        <button class="btn btn-primary" (click)="goBack()">Volver</button>
        <button class="btn btn-primary ms-2" (click)="endIncidence()">Finalizar</button>
    </div>
</div>
<div class="spinner" [hidden]="!loading">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>  
</div>