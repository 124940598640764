import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (!request.url.includes('auth') && localStorage.getItem('token')) {
            request = request.clone({
                setHeaders: {
                    Authorization: `${localStorage.getItem('token')}`,
                },
            });
        }
        return next.handle(request);
    }
}
