<div class="col-md-12">
    <div class="text-end mt-4 mb-4">
        <button class="btn btn-primary ms-2" (click)="add(template)">Agregar</button>
    </div>
    <table class="table">
        <thead>
            <tr>
                <th>Fecha</th>
                <th>Hora</th>
                <th>LockerId</th>
                <th>Estado</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let incidence of incidences">
                <td>{{incidence.date}}</td>
                <td>{{incidence.hour}}</td>
                <td>{{incidence.idLock}}</td>
                <td>{{incidence.statusString}}</td>
                <td><button (click)="open(incidence)" >ReAbrir</button></td>
            </tr>
        </tbody>
    </table>
</div>
<ng-template #template>
   <div class="modal-header">
      <h4 class="modal-title pull-left">Seleccionar Lockerid</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
         <span aria-hidden="true">×</span>
      </button>
   </div>
   <div class="modal-body">
      <input type="text" class="form-control" name="lockerid" id="lockerid" placeholder="Locker ID" [(ngModel)]="lockerid">
   </div>
   <div class="modal-footer">
      <button type="button" class="btn btn-default btn-primary" (click)="start()">Abrir Incidencia</button>
   </div>
</ng-template>
<div class="spinner" [hidden]="!loading">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>  
</div>