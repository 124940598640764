<div class="phisycal-params-config">
  <hr>
  <div class="row">
    <div class="col-md-6 checkboxesDiv">
      <!-- <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
        <label class="form-check-label paddingCheckboxLabel" for="flexCheckChecked">
          Pantalla Táctil
        </label>
      </div> -->
      <div class="form-check">
        <!-- <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
        <label class="form-check-label paddingCheckboxLabel" for="flexCheckChecked">
          Escáner QR
        </label> -->
      </div>
    </div>
    <div class="col-md-6">
      <label for="selectMainColumn" class="form-label selectMainTitle h6">Módulo de tecnología</label>
      <select name="selectMainColumn" [(ngModel)]="mainModule" class="form-control selectMain"
        (ngModelChange)="changedMainModule()">
        <option value="-">Seleccionar</option>
        <option *ngFor="let moduleInfo of modulesInfo | moduleNoAuxPipe; index as i "
          [value]="moduleInfo.mainColumnIfAux ? modulesInfo[i-1].id : moduleInfo.id"> Módulo {{moduleInfo.id}}
        </option>
      </select>
    </div>
  </div>
  <hr>
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">Módulo</th>
        <th scope="col">Tipo de módulo</th>
        <th scope="col">Número de slots</th>
        <th scope="col">Configurar</th>
        <th scope="col">Eliminar módulo</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let moduleInfo of modulesInfo; index as i">
        <th scope="row">{{moduleInfo.id}}</th>
        <td *ngIf="modulesInfo[i].moduleType !== 'aux'">
          <select name="moduleType" [(ngModel)]="modulesInfo[i].moduleType"
            (change)="changedModuleInfoType(i, modulesInfo[i].moduleType, modulesInfo[i].locksNumber)"
            class="form-control">
            <option hidden value="-1">Seleccionar</option>
            <option value="cl">Click&Locker (Columna)</option>
            <option value="cs">Click&Scooter (Bloque)</option>
            <option value="cb">Click&Bike (Bloque)</option>
            <option value="cc">Click&Cart (Bloque)</option>
            <!-- <option value="cd">Click&Drawer (Bloque)</option> -->
          </select>
        </td>
        <td *ngIf="modulesInfo[i].moduleType !== 'aux'">
          <select name="locksNumber" [(ngModel)]="modulesInfo[i].locksNumber" class="form-control"
            (change)="changeLocksNumber(i, modulesInfo[i].locksNumber)">
            <option hidden value="100">Seleccionar</option>
            <option value="1" *ngIf="moduleInfo.moduleType !== 'cs' && moduleInfo.moduleType !== 'cb'">1</option>
            <option value="2">2</option>
            <option value="3" *ngIf="moduleInfo.moduleType !== 'cs' && moduleInfo.moduleType !== 'cb'">3</option>
            <option value="4" *ngIf="moduleInfo.moduleType !== 'cc'">4</option>
            <option value="5"
              *ngIf="moduleInfo.moduleType !== 'cs' && moduleInfo.moduleType !== 'cb' && moduleInfo.moduleType !== 'cc'">
              5</option>
            <option value="6" *ngIf="moduleInfo.moduleType !== 'cc'">6</option>
            <option value="7"
              *ngIf="moduleInfo.moduleType !== 'cs' && moduleInfo.moduleType !== 'cb' && moduleInfo.moduleType !== 'cc'">
              7</option>
            <option value="8"
              *ngIf="moduleInfo.moduleType !== 'cs' && moduleInfo.moduleType !== 'cb' && moduleInfo.moduleType !== 'cc'">
              8</option>
          </select>
        </td>
        <td
          *ngIf="modulesInfo[i].moduleType && modulesInfo[i].moduleType !== 'aux' && modulesInfo[i].moduleType !== 'cl'">
          <select name="moduleTemperature" [(ngModel)]="modulesInfo[i].businessType" class="form-control">
            <option hidden [value]="null">Módulo de tecnología</option>
            <option value="cc">Click&Collect</option>
            <option value="consigna">Consigna</option>
          </select>
        </td>
        <td class="gearIconPadding" *ngIf="moduleInfo.moduleType === 'cl'" class="gearPadding"><img class="iconSize" *ngIf="moduleInfo.locksNumber !== 100"(click)="editModuleInfoConfig(i, moduleInfo)"
            src="assets/icons/gear.png" >
        </td>
        <td *ngIf="modulesInfo[i].moduleType !== 'aux'" class="deleteModuleTD"><span class="deleteModuleInfo"
            (click)="deleteFromModulesInfo(moduleInfo.id)">Eliminar</span></td>
        <td *ngIf="modulesInfo[i].moduleType === 'aux'"> Columna auxiliar de {{modulesInfo[i].mainColumnIfAux}}
        </td>
        <td *ngIf="modulesInfo[i].moduleType === 'aux'"></td>
        <td *ngIf="modulesInfo[i].moduleType === 'aux'"></td>
        <td *ngIf="modulesInfo[i].moduleType === 'aux'"></td>
      </tr>
    </tbody>
  </table>
  <!-- <div class="row">
    <div class="col-md-12 ">
      <div class="row">
        <div class="col-md-4">
        <input class="form-control" type="text" name="lockerId" [(ngModel)]="paramsModel.lockerId" id="lockerId" placeholder="Locker Id (tst-0001)">
        </div>
        <div class="text-end col-md-8">
          <button class="btn btn-primary ms-2" [disabled]="loading" (click)="showModal(addConfig)">Agregar</button>
          <button class="btn btn-primary ms-2" [disabled]="loading" (click)="clearSizes()">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row" >
    <div class="col-md-12">
      <table class="table">
        <thead>
          <tr>
            <th>Coordenadas</th>
            <th>Tipo</th>
            <th>Tamaño</th>
            <th>Frio</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let occupation of lockSizes">
            <td>{{occupation.y}}{{occupation.x}}</td>
            <td>{{(occupation.businessType == 'cc') ? 'Click & Collect' : 'Consigna'}}</td>
            <td>{{occupation.size}}</td>
            <td>{{(occupation.cold) ? 'Si' : 'No'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div> -->
  <div class="buttons text-end">
    <button class="btn btn-primary ms-2" [disabled]="loading" (click)="addModuleInfo()">Añadir módulo</button>
    <button class="btn btn-info ms-2" [disabled]="loading" (click)="refreshImg()"> Descargar imagen</button>
    <button class="btn btn-success ms-2" [disabled]="loading" (click)="createSpecialParamsJSON()">Guardar</button>
  </div>
  <div class="demoImgDiv">

    <div class="demoModules">
      <div class="moduleImgDiv" *ngFor="let module of modulesInfo; index as i">
        <div class="lockerIdShow h3" *ngIf="module.moduleType === 'cl'"> {{module.id}}</div>
        <div class="locker2IdShow h5" *ngIf="module.moduleType === 'aux'"> {{module.id}}</div>
        <div class="bikeScooterIdShow h3" *ngIf="module.moduleType === 'cb' || module.moduleType === 'cs'">
          {{module.id}}</div>
        <div class="cartIdShow h3" *ngIf="module.moduleType === 'cc'"> {{module.id}}</div>
        <div class="drawerIdShow h3" *ngIf="module.moduleType === 'cd'"> {{module.id}}</div>
        <div class="demoImgFlex">
          <!-- <div class="scooterBikeBar" [class.scooterBikebarStart]="i === 0" [class.scooterBikebarEnd]=" (i+1) === modulesInfo.length" *ngIf="module.moduleType === 'cb' || module.moduleType === 'cs'"></div> -->
          <img class="imgDemo" [ngStyle]="{'z-index': (modulesInfo.length - i)  }"
            src="assets/images/lockerpng.png" *ngIf="module.moduleType === 'cl' || module.moduleType === 'aux'">
          <!-- <img class="imgDemo" [ngStyle]="{'z-index': ((modulesInfo.length - i) * 2)  + 4  }" src="assets/images/lockerpng.png" *ngIf="module.moduleType === 'cl' && module.twoColumnModule" > -->
          <img class="imgDemoCart" [ngStyle]="{'z-index': (modulesInfo.length - i)  }"
            src="assets/images/cart.png" *ngIf="module.moduleType === 'cc' && !(module.locks.length>1)">
          <img class="imgDemoCart" [ngStyle]="{'z-index': (modulesInfo.length - i)  }"
            src="assets/images/cart2.png" *ngIf="module.moduleType === 'cc' && module.locks.length===2">
          <img class="imgDemoCart" [ngStyle]="{'z-index': (modulesInfo.length - i)  }"
            src="assets/images/cart3.png" *ngIf="module.moduleType === 'cc' && module.locks.length===3">
          <img class="imgDemoCart" [ngStyle]="{'z-index': (modulesInfo.length - i)   }"
            src="assets/images/cart4.png" *ngIf="module.moduleType === 'cc' && module.locks.length===4">
          <img class="imgDemo clickDrawerImg" src="assets/images/drawer.png" *ngIf="module.moduleType === 'cd'">
          <img class="imgDemoSmall clickBikeImg" src="assets/images/bikeBoth.png"
            *ngIf="module.moduleType === 'cb' && (!((i + 1) === modulesInfo.length) || module.locks.length>3) && i !== 0">
            <img class="imgDemoSmall clickBikeImg miniBikeScooterImg" src="assets/images/bike.png"
            *ngIf="module.moduleType === 'cb' && ((i + 1) === modulesInfo.length) && i === 0 && 3>module.locks.length ">
            <img class="imgDemoSmall clickBikeImg" src="assets/images/bikeRight.png"
            *ngIf="module.moduleType === 'cb' && (!((i + 1) === modulesInfo.length) || module.locks.length>3) && i === 0">
            <img class="imgDemoSmall clickBikeImg" src="assets/images/bikeLeft.png"
            *ngIf="module.moduleType === 'cb' && ((i + 1) === modulesInfo.length) && i !== 0 && 3>module.locks.length">
          <img class="imgDemoSmall clickBikeImg" src="assets/images/bikeBoth.png"
            *ngIf="module.moduleType === 'cb' && module.locks.length>3  && (!((i + 1) === modulesInfo.length) || module.locks.length>5)">
            <img class="imgDemoSmall clickBikeImg" src="assets/images/bikeLeft.png"
            *ngIf="module.moduleType === 'cb' && module.locks.length>3 && 5>module.locks.length && (i + 1) === modulesInfo.length">
          <img class="imgDemoSmall clickBikeImg" src="assets/images/bikeBoth.png"
            *ngIf="module.moduleType === 'cb' && module.locks.length>5 && (!((i + 1) === modulesInfo.length) || module.locks.length>7)">
            <img class="imgDemoSmall clickBikeImg" src="assets/images/bikeLeft.png"
            *ngIf="module.moduleType === 'cb' && module.locks.length>5 && 7>module.locks.length && (i + 1) === modulesInfo.length">
          <img class="imgDemoSmall clickBikeImg" src="assets/images/bikeBoth.png"
            *ngIf="module.moduleType === 'cb' && module.locks.length>7 &&  !((i + 1) === modulesInfo.length)">
            <img class="imgDemoSmall clickBikeImg" src="assets/images/bikeBoth.png"
            *ngIf="module.moduleType === 'cb' && module.locks.length>7 && 9>module.locks.length && (i + 1) === modulesInfo.length">
          <img class="imgDemoSmall clickScooterImg" src="assets/images/scooterBoth.png"
            *ngIf="module.moduleType === 'cs' && (!((i + 1) === modulesInfo.length) || module.locks.length>3) && i !== 0">
            <img class="imgDemoSmall clickScooterImg miniBikeScooterImg" src="assets/images/scooter.png"
            *ngIf="module.moduleType === 'cs' && ((i + 1) === modulesInfo.length) && i === 0 && 3>module.locks.length ">
            <img class="imgDemoSmall clickScooterImg" src="assets/images/scooterRight.png"
            *ngIf="module.moduleType === 'cs' && (!((i + 1) === modulesInfo.length) || module.locks.length>3) && i === 0">
            <img class="imgDemoSmall clickScooterImg" src="assets/images/scooterLeft.png"
            *ngIf="module.moduleType === 'cs' && ((i + 1) === modulesInfo.length) && i !== 0 && 3>module.locks.length">
          <img class="imgDemoSmall clickScooterImg" src="assets/images/scooterBoth.png"
            *ngIf="module.moduleType === 'cs' && module.locks.length>3  && (!((i + 1) === modulesInfo.length) || module.locks.length>5)">
            <img class="imgDemoSmall clickScooterImg" src="assets/images/scooterLeft.png"
            *ngIf="module.moduleType === 'cs' && module.locks.length>3 && 5>module.locks.length && (i + 1) === modulesInfo.length">
          <img class="imgDemoSmall clickScooterImg" src="assets/images/scooterBoth.png"
            *ngIf="module.moduleType === 'cs' && module.locks.length>5 && (!((i + 1) === modulesInfo.length) || module.locks.length>7)">
            <img class="imgDemoSmall clickScooterImg" src="assets/images/scooterLeft.png"
            *ngIf="module.moduleType === 'cs' && module.locks.length>5 && 7>module.locks.length && (i + 1) === modulesInfo.length">
          <img class="imgDemoSmall clickScooterImg" src="assets/images/scooterBoth.png"
            *ngIf="module.moduleType === 'cs' && module.locks.length>7 &&  !((i + 1) === modulesInfo.length)">
            <img class="imgDemoSmall clickScooterImg" src="assets/images/scooterLeft.png"
            *ngIf="module.moduleType === 'cs' && module.locks.length>7 && 9>module.locks.length && (i + 1) === modulesInfo.length">
          <div class="auxDivForScooter" *ngIf="(module.moduleType === 'cs') && (modulesInfo[i + 1] && modulesInfo[i + 1].moduleType !== 'cb' && modulesInfo[i + 1].moduleType !== 'cs')">
          </div>
          <div class="auxDivForBike" *ngIf="(module.moduleType === 'cb') && (modulesInfo[i + 1] && modulesInfo[i + 1].moduleType !== 'cb' && modulesInfo[i + 1].moduleType !== 'cs')">
          </div>
        </div>
        <div class="lockerNumberSlots h5" *ngIf="module.moduleType === 'cl'"> {{module.locks.length}} <img
            class="imglockForLocksNumber" src="assets/images/lockForLocksNumber.png"></div>
        <div class="locker2NumberSlots h5" *ngIf="module.moduleType === 'aux'"> AUX</div>
        <div class="bikeScooterNumberSlots h5" *ngIf="module.moduleType === 'cb' || module.moduleType === 'cs'">
          {{module.locks.length}} <img class="imglockForLocksNumber" src="assets/images/lockForLocksNumber.png">
        </div>
        <div class="cartNumberSlots h5" *ngIf="module.moduleType === 'cc'"> {{module.locks.length}} <img
            class="imglockForLocksNumber" src="assets/images/lockForLocksNumber.png"></div>
        <div class="drawerNumberSlots h5" *ngIf="module.moduleType === 'cd'"> {{module.locks.length}} <img
            class="imglockForLocksNumber" src="assets/images/lockForLocksNumber.png"></div>

        <!-- <div class="auxDivForScooterBike" *ngIf="module.moduleType === 'cb' || module.moduleType === 'cs'"></div> -->

      </div>
    </div>
    <div class="demoImgDiv noShow">
      <div #imgDemoDiv class="demoModulesForImg">
        <div class="moduleImgDiv" *ngFor="let module of modulesInfo; index as i">
          <div class="lockerIdShow h5" *ngIf="module.moduleType === 'cl'"> {{module.id}}</div>
          <div class="locker2IdShow h6" *ngIf="module.moduleType === 'aux'"> {{module.id}}</div>
          <div class="bikeScooterIdShow h5" *ngIf="module.moduleType === 'cb' || module.moduleType === 'cs'">
            {{module.id}}</div>
          <div class="cartIdShow h5" *ngIf="module.moduleType === 'cc'"> {{module.id}}</div>
          <div class="drawerIdShow h5" *ngIf="module.moduleType === 'cd'"> {{module.id}}</div>
          <div class="demoImgFlex">
            <img class="imgDemo" [ngStyle]="{'z-index': (modulesInfo.length - i)  }"
            src="assets/images/lockerpng.png" *ngIf="module.moduleType === 'cl' || module.moduleType === 'aux'">
          <!-- <img class="imgDemo" [ngStyle]="{'z-index': ((modulesInfo.length - i) * 2)  + 4  }" src="assets/images/lockerpng.png" *ngIf="module.moduleType === 'cl' && module.twoColumnModule" > -->
          <img class="imgDemoCart" [ngStyle]="{'z-index': (modulesInfo.length - i)  }"
            src="assets/images/cart.png" *ngIf="module.moduleType === 'cc' && !(module.locks.length>1)">
          <img class="imgDemoCart" [ngStyle]="{'z-index': (modulesInfo.length - i)  }"
            src="assets/images/cart2.png" *ngIf="module.moduleType === 'cc' && module.locks.length===2">
          <img class="imgDemoCart" [ngStyle]="{'z-index': (modulesInfo.length - i)  }"
            src="assets/images/cart3.png" *ngIf="module.moduleType === 'cc' && module.locks.length===3">
          <img class="imgDemoCart" [ngStyle]="{'z-index': (modulesInfo.length - i)   }"
            src="assets/images/cart4.png" *ngIf="module.moduleType === 'cc' && module.locks.length===4">
          <img class="imgDemo clickDrawerImg" src="assets/images/drawer.png" *ngIf="module.moduleType === 'cd'">
          <img class="imgDemoSmall clickBikeImg" src="assets/images/bikeBoth.png"
            *ngIf="module.moduleType === 'cb' && (!((i + 1) === modulesInfo.length) || module.locks.length>3) && i !== 0">
            <img class="imgDemoSmall clickBikeImg miniBikeScooterImg" src="assets/images/bike.png"
            *ngIf="module.moduleType === 'cb' && ((i + 1) === modulesInfo.length) && i === 0 && 3>module.locks.length ">
            <img class="imgDemoSmall clickBikeImg" src="assets/images/bikeRight.png"
            *ngIf="module.moduleType === 'cb' && (!((i + 1) === modulesInfo.length) || module.locks.length>3) && i === 0">
            <img class="imgDemoSmall clickBikeImg" src="assets/images/bikeLeft.png"
            *ngIf="module.moduleType === 'cb' && ((i + 1) === modulesInfo.length) && i !== 0 && 3>module.locks.length">
          <img class="imgDemoSmall clickBikeImg" src="assets/images/bikeBoth.png"
            *ngIf="module.moduleType === 'cb' && module.locks.length>3  && (!((i + 1) === modulesInfo.length) || module.locks.length>5)">
            <img class="imgDemoSmall clickBikeImg" src="assets/images/bikeLeft.png"
            *ngIf="module.moduleType === 'cb' && module.locks.length>3 && 5>module.locks.length && (i + 1) === modulesInfo.length">
          <img class="imgDemoSmall clickBikeImg" src="assets/images/bikeBoth.png"
            *ngIf="module.moduleType === 'cb' && module.locks.length>5 && (!((i + 1) === modulesInfo.length) || module.locks.length>7)">
            <img class="imgDemoSmall clickBikeImg" src="assets/images/bikeLeft.png"
            *ngIf="module.moduleType === 'cb' && module.locks.length>5 && 7>module.locks.length && (i + 1) === modulesInfo.length">
          <img class="imgDemoSmall clickBikeImg" src="assets/images/bikeBoth.png"
            *ngIf="module.moduleType === 'cb' && module.locks.length>7 &&  !((i + 1) === modulesInfo.length)">
            <img class="imgDemoSmall clickBikeImg" src="assets/images/bikeBoth.png"
            *ngIf="module.moduleType === 'cb' && module.locks.length>7 && 9>module.locks.length && (i + 1) === modulesInfo.length">
          <img class="imgDemoSmall clickScooterImg" src="assets/images/scooterBoth.png"
            *ngIf="module.moduleType === 'cs' && (!((i + 1) === modulesInfo.length) || module.locks.length>3) && i !== 0">
            <img class="imgDemoSmall clickScooterImg miniBikeScooterImg" src="assets/images/scooter.png"
            *ngIf="module.moduleType === 'cs' && ((i + 1) === modulesInfo.length) && i === 0 && 3>module.locks.length ">
            <img class="imgDemoSmall clickScooterImg" src="assets/images/scooterRight.png"
            *ngIf="module.moduleType === 'cs' && (!((i + 1) === modulesInfo.length) || module.locks.length>3) && i === 0">
            <img class="imgDemoSmall clickScooterImg" src="assets/images/scooterLeft.png"
            *ngIf="module.moduleType === 'cs' && ((i + 1) === modulesInfo.length) && i !== 0 && 3>module.locks.length">
          <img class="imgDemoSmall clickScooterImg" src="assets/images/scooterBoth.png"
            *ngIf="module.moduleType === 'cs' && module.locks.length>3  && (!((i + 1) === modulesInfo.length) || module.locks.length>5)">
            <img class="imgDemoSmall clickScooterImg" src="assets/images/scooterLeft.png"
            *ngIf="module.moduleType === 'cs' && module.locks.length>3 && 5>module.locks.length && (i + 1) === modulesInfo.length">
          <img class="imgDemoSmall clickScooterImg" src="assets/images/scooterBoth.png"
            *ngIf="module.moduleType === 'cs' && module.locks.length>5 && (!((i + 1) === modulesInfo.length) || module.locks.length>7)">
            <img class="imgDemoSmall clickScooterImg" src="assets/images/scooterLeft.png"
            *ngIf="module.moduleType === 'cs' && module.locks.length>5 && 7>module.locks.length && (i + 1) === modulesInfo.length">
          <img class="imgDemoSmall clickScooterImg" src="assets/images/scooterBoth.png"
            *ngIf="module.moduleType === 'cs' && module.locks.length>7 &&  !((i + 1) === modulesInfo.length)">
            <img class="imgDemoSmall clickScooterImg" src="assets/images/scooterLeft.png"
            *ngIf="module.moduleType === 'cs' && module.locks.length>7 && 9>module.locks.length && (i + 1) === modulesInfo.length">
          <div class="auxDivForScooter" *ngIf="(module.moduleType === 'cs') && (modulesInfo[i + 1] && modulesInfo[i + 1].moduleType !== 'cb' && modulesInfo[i + 1].moduleType !== 'cs')">
          </div>
          <div class="auxDivForBike" *ngIf="(module.moduleType === 'cb') && (modulesInfo[i + 1] && modulesInfo[i + 1].moduleType !== 'cb' && modulesInfo[i + 1].moduleType !== 'cs')">
          </div>
          </div>
          <div class="lockerNumberSlots h5" *ngIf="module.moduleType === 'cl'"> {{module.locks.length}} <img
              class="imglockForLocksNumber" src="assets/images/lockForLocksNumber.png"></div>
          <div class="locker2NumberSlots h5" *ngIf="module.moduleType === 'aux'"> AUX</div>
          <div class="bikeScooterNumberSlots h5" *ngIf="module.moduleType === 'cb' || module.moduleType === 'cs'">
            {{module.locks.length}} <img class="imglockForLocksNumber" src="assets/images/lockForLocksNumber.png">
          </div>
          <div class="cartNumberSlots h5" *ngIf="module.moduleType === 'cc'"> {{module.locks.length}} <img
              class="imglockForLocksNumber" src="assets/images/lockForLocksNumber.png"></div>
          <div class="drawerNumberSlots h5" *ngIf="module.moduleType === 'cd'"> {{module.locks.length}} <img
              class="imglockForLocksNumber" src="assets/images/lockForLocksNumber.png"></div>
          <!-- <div class="auxDivForScooterBike" *ngIf="module.moduleType === 'cb' || module.moduleType === 'cs'"></div> -->


        </div>
        <div id="download">
          <img #canvas class="noDisplay">
          <a #downloadLink class="downloadDemoButton" class="noDisplay">sss</a>
        </div>

        <div class="moduleInfoOptionsDiv" *ngIf="showModuleInfoOptionsDiv && selectedModuleInfo">
          <div class="moduleInfoOptionsScroll">
            <div class="moduleInfoOptionsDiv1Div row">
              <div class=" col-lg-12 col-xl-12 col-xxl-8 moduleInfoOptionsDiv1Div1">
                <div class="moduleInfoMapAndCheckboxes row">
                  <div class="moduleInfoMapAndCheckboxesDiv1">
                    <div class="title moduleInfoTitle">Configura tu columna Click&Locker</div>
                    <label for="moduleTemperature" class="form-label moduleInfoTemperatureTitle h6">Opciones de
                      climatización</label>
                    <select name="moduleTemperature" [(ngModel)]="selectedModuleInfo.temperature"
                      class="selectTransparentBackground form-control selectHeat"
                      (ngModelChange)="changedModuleTemperature($event)">
                      <option value="-1">Seleccionar</option>
                      <option value="normal">Columna sin climatización</option>
                      <option value="cold">Columna de frío</option>
                      <option value="cold2">Columna de frío doble</option>
                      <option value="negativeCold">Columna de frío negativo</option>
                      <option value="negativeCold2">Columna de frío negativo doble</option>
                      <option value="heat">Columna calefactada</option>
                    </select>

                    <label for="moduleModulation" class="form-label moduleInfoTemperatureTitle h6">Opciones de
                      modulación</label>
                    <select name="moduleModulation" [(ngModel)]="selectedModuleInfo.isReducedModule"
                      class=" selectTransparentBackground form-control selectHeat"
                      (ngModelChange)="changedIsReduced()">
                      <option [ngValue]="false">Modulación de 4</option>
                      <option [ngValue]="true">Modulación de 3</option>
                    </select>
                    <!--  <input class="form-check-input smallCheckBox" type="checkbox" value="" id="reducedModuleChecked" [(ngModel)]="selectedModuleInfo.isReducedModule" (ngModelChange)="changedIsReduced()">
                <label class="form-check-label paddingCheckboxLabel h6" for="reducedModuleChecked">
                  Módulo reducido
                </label> -->
                    <div class="hintMainModule h6">*El tamaño mínimo de la taquilla asignada a la pantalla debe de
                      ser M</div>
                  </div>

                </div>
                <div class="locksDetailsContainer scrollableLocks">
                  <div class="locksDetailsDiv" *ngFor="let lock of selectedModuleInfo.locks; index as i">
                    <div class="row">
                      <div class="h5 lockText col-lg-12 col-xxl-2">Taquilla {{i + 1}}: </div>
                      <div class="col-sm- 12 col-lg-4 col-xxl-3">
                        <select name="moduleTemperature" [(ngModel)]="selectedModuleInfo.locks[i].size"
                          class="form-control selectTransparentBackground selectHeat"
                          (ngModelChange)="changedSize(selectedModuleInfo.id, i, $event)">
                          <option value="">Seleccionar</option>
                          <option value="s">Small</option>
                          <option value="m">Medium</option>
                          <option value="l">Large</option>
                          <option value="xl">Extra Large</option>
                          <option value="xxl">Extra Extra Large</option>
                        </select>
                      </div>
                      <div class="col-sm- 12 col-lg-4 col-xxl-3">
                        <select name="moduleTemperature" *ngIf="selectedModuleInfo.locks[i].size"
                          [(ngModel)]="selectedModuleInfo.locks[i].businessType"
                          class="form-control selectHeat selectTransparentBackground"
                          (ngModelChange)="changedBusinessType(selectedModuleInfo.id, i, $event)">
                          <option value="">Seleccionar</option>
                          <option value="cc">Click&Collect</option>
                          <option value="consigna">Consigna</option>
                          <option value="tech"
                            *ngIf="selectedModuleInfo.locks[i].size === 'm' && selectedModuleInfo.isMain && (selectedModuleInfo.secondColumnStart ? selectedModuleInfo.secondColumnStart>i : true)">
                            Taquilla de tecnología</option>
                          <option value="screen"
                            *ngIf="selectedModuleInfo.locks[i].size === 'm' && selectedModuleInfo.isMain && (selectedModuleInfo.secondColumnStart ? selectedModuleInfo.secondColumnStart>i : true)">
                            Taquilla de pantalla</option>
                            <option value="disabled">Deshabilitada</option>
                        </select>
                      </div>
                      <div class="col-sm- 12 col-lg-4 col-xxl-3"
                        *ngIf="selectedModuleInfo.twoColumnModule && i !== 0">
                        <!-- <select name="moduleTemperature" *ngIf="selectedModuleInfo.locks[i].businessType" class="form-control selectHeat">
                    <option>Seleccionar</option>
                    <option>Columna 1</option>
                    <option>Columna 2</option>
                  </select> -->
                        <input class="form-check-input smallCheckBox" [(ngModel)]="secondColumnStartVar[i]"
                          type="checkbox" value="" id="reducedModuleChecked"
                          (change)="secondColumnStartChanged(i, $event)">
                        <span class="form-check-label paddingCheckboxLabel h6">
                          Segunda Columna
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="moduleInfoMapAndCheckboxesDiv2">
                    <img class="imgLockersMap" *ngIf="!selectedModuleInfo.isReducedModule"
                      src="assets/images/lockerSizeMap.png">
                    <img class="imgLockersMap" *ngIf="selectedModuleInfo.isReducedModule"
                      src="assets/images/lockerSizeMap3.png">
                    <!-- <div class="twoColumnModuleDiv">
                <input class="form-check-input" type="checkbox" value="" id="twoColumnModule" [(ngModel)]="selectedModuleInfo.twoColumnModule" (ngModelChange)="changedTwoColumnModule(selectedModuleInfo.id, $event)">
                <label class="form-check-label paddingCheckboxLabel h6 twoColumnModuleLabel" for="twoColumnModule">
                  Módulo de dos columnas
                </label>
              </div> -->
                  </div>
                </div>
              </div>
              <div class="moduleInfoOptionsDiv1Div2 col-lg-12 col-xl-12 col-xxl-4 row"
                *ngIf="!selectedModuleInfo.twoColumnModule">
                <div class=" columnMap">
                  <div class="twoColumnModuleColumnLabel h4">Columna <span>{{selectedModuleInfo.id}}:</span></div>
                  <div class="imgContainer">
                    <div class="lockersDiv">
                      <div
                        *ngIf="selectedModuleInfo.locks[0]?.height !== '' && selectedModuleInfo.locks[0]?.size !== ''"
                        class="lockDiv lockDiv1"
                        [class.isDisabledLock]="selectedModuleInfo.locks[0]?.businessType === 'disabled'"
                        [class.isTech]="selectedModuleInfo.locks[0]?.businessType === 'tech'"
                        [class.screenMod3]="selectedModuleInfo.locks[0]?.businessType === 'screen' && selectedModuleInfo.isReducedModule"
                        [class.screenMod4]="selectedModuleInfo.locks[0]?.businessType === 'screen' && !selectedModuleInfo.isReducedModule">
                        <div class="locksSizeText" [class.h2]="selectedModuleInfo.locks[0]?.businessType !== 'disabled'" [class.h6]="selectedModuleInfo.locks[0]?.businessType === 'disabled'">{{selectedModuleInfo.locks[0]?.businessType !== 'disabled' ? (selectedModuleInfo.locks[0]?.businessType !== 'tech' ?
                          (selectedModuleInfo.locks[0]?.businessType !== 'screen' ?
                          selectedModuleInfo.locks[0]?.size.toUpperCase() : '') : 'TECH') : 'DISABLED'}}</div>
                      </div>
                      <div
                        *ngIf="selectedModuleInfo.locks[1]?.height !== '' && selectedModuleInfo.locks[1]?.size !== ''"
                        class="lockDiv lockDiv2"
                        [class.isDisabledLock]="selectedModuleInfo.locks[1]?.businessType === 'disabled'"
                        [class.isTech]="selectedModuleInfo.locks[1]?.businessType === 'tech'"
                        [class.screenMod3]="selectedModuleInfo.locks[1]?.businessType === 'screen' && selectedModuleInfo.isReducedModule"
                        [class.screenMod4]="selectedModuleInfo.locks[1]?.businessType === 'screen' && !selectedModuleInfo.isReducedModule">
                        <div class="locksSizeText" [class.h2]="selectedModuleInfo.locks[1]?.businessType !== 'disabled'" [class.h6]="selectedModuleInfo.locks[1]?.businessType === 'disabled'">{{selectedModuleInfo.locks[1]?.businessType !== 'disabled' ? (selectedModuleInfo.locks[1]?.businessType !== 'tech' ?
                          (selectedModuleInfo.locks[1]?.businessType !== 'screen' ?
                          selectedModuleInfo.locks[1]?.size.toUpperCase() : '') : 'TECH') : 'DISABLED'}}</div>
                      </div>
                      <div
                        *ngIf="selectedModuleInfo.locks[2]?.height !== '' && selectedModuleInfo.locks[2]?.size !== ''"
                        class="lockDiv lockDiv3"
                        [class.isDisabledLock]="selectedModuleInfo.locks[2]?.businessType === 'disabled'"
                        [class.isTech]="selectedModuleInfo.locks[2]?.businessType === 'tech'"
                        [class.screenMod3]="selectedModuleInfo.locks[2]?.businessType === 'screen' && selectedModuleInfo.isReducedModule"
                        [class.screenMod4]="selectedModuleInfo.locks[2]?.businessType === 'screen' && !selectedModuleInfo.isReducedModule">
                        <div class="locksSizeText" [class.h2]="selectedModuleInfo.locks[2]?.businessType !== 'disabled'" [class.h6]="selectedModuleInfo.locks[2]?.businessType === 'disabled'">{{selectedModuleInfo.locks[2]?.businessType !== 'disabled' ? (selectedModuleInfo.locks[2]?.businessType !== 'tech' ?
                          (selectedModuleInfo.locks[2]?.businessType !== 'screen' ?
                          selectedModuleInfo.locks[2]?.size.toUpperCase() : '') : 'TECH') : 'DISABLED'}}</div>
                      </div>
                      <div
                        *ngIf="selectedModuleInfo.locks[3]?.height !== '' && selectedModuleInfo.locks[3]?.size !== ''"
                        class="lockDiv lockDiv4"
                        [class.isDisabledLock]="selectedModuleInfo.locks[3]?.businessType === 'disabled'"
                        [class.isTech]="selectedModuleInfo.locks[3]?.businessType === 'tech'"
                        [class.screenMod3]="selectedModuleInfo.locks[3]?.businessType === 'screen' && selectedModuleInfo.isReducedModule"
                        [class.screenMod4]="selectedModuleInfo.locks[3]?.businessType === 'screen' && !selectedModuleInfo.isReducedModule">
                        <div class="locksSizeText" [class.h2]="selectedModuleInfo.locks[3]?.businessType !== 'disabled'" [class.h6]="selectedModuleInfo.locks[3]?.businessType === 'disabled'">{{selectedModuleInfo.locks[3]?.businessType !== 'disabled' ? (selectedModuleInfo.locks[3]?.businessType !== 'tech' ?
                          (selectedModuleInfo.locks[3]?.businessType !== 'screen' ?
                          selectedModuleInfo.locks[3]?.size.toUpperCase() : '') : 'TECH') : 'DISABLED'}}</div>
                      </div>
                      <div
                        *ngIf="selectedModuleInfo.locks[4]?.height !== '' && selectedModuleInfo.locks[4]?.size !== ''"
                        class="lockDiv lockDiv5"
                        [class.isDisabledLock]="selectedModuleInfo.locks[4]?.businessType === 'disabled'"
                        [class.isTech]="selectedModuleInfo.locks[4]?.businessType === 'tech'"
                        [class.screenMod3]="selectedModuleInfo.locks[4]?.businessType === 'screen' && selectedModuleInfo.isReducedModule"
                        [class.screenMod4]="selectedModuleInfo.locks[4]?.businessType === 'screen' && !selectedModuleInfo.isReducedModule">
                        <div class="locksSizeText" [class.h2]="selectedModuleInfo.locks[4]?.businessType !== 'disabled'" [class.h6]="selectedModuleInfo.locks[4]?.businessType === 'disabled'">{{selectedModuleInfo.locks[4]?.businessType !== 'disabled' ? (selectedModuleInfo.locks[4]?.businessType !== 'tech' ?
                          (selectedModuleInfo.locks[4]?.businessType !== 'screen' ?
                          selectedModuleInfo.locks[4]?.size.toUpperCase() : '') : 'TECH') : 'DISABLED'}}</div>
                      </div>
                      <div
                        *ngIf="selectedModuleInfo.locks[5]?.height !== '' && selectedModuleInfo.locks[5]?.size !== ''"
                        class="lockDiv lockDiv6"
                        [class.isDisabledLock]="selectedModuleInfo.locks[5]?.businessType === 'disabled'"
                        [class.isTech]="selectedModuleInfo.locks[5]?.businessType === 'tech'"
                        [class.screenMod3]="selectedModuleInfo.locks[5]?.businessType === 'screen' && selectedModuleInfo.isReducedModule"
                        [class.screenMod4]="selectedModuleInfo.locks[5]?.businessType === 'screen' && !selectedModuleInfo.isReducedModule">
                        <div class="locksSizeText" [class.h2]="selectedModuleInfo.locks[5]?.businessType !== 'disabled'" [class.h6]="selectedModuleInfo.locks[5]?.businessType === 'disabled'">{{selectedModuleInfo.locks[5]?.businessType !== 'disabled' ? (selectedModuleInfo.locks[5]?.businessType !== 'tech' ?
                          (selectedModuleInfo.locks[5]?.businessType !== 'screen' ?
                          selectedModuleInfo.locks[5]?.size.toUpperCase() : '') : 'TECH') : 'DISABLED'}}</div>
                      </div>
                      <div
                        *ngIf="selectedModuleInfo.locks[6]?.height !== '' && selectedModuleInfo.locks[6]?.size !== ''"
                        class="lockDiv lockDiv7"
                        [class.isDisabledLock]="selectedModuleInfo.locks[6]?.businessType === 'disabled'"
                        [class.isTech]="selectedModuleInfo.locks[6]?.businessType === 'tech'"
                        [class.screenMod3]="selectedModuleInfo.locks[6]?.businessType === 'screen' && selectedModuleInfo.isReducedModule"
                        [class.screenMod4]="selectedModuleInfo.locks[6]?.businessType === 'screen' && !selectedModuleInfo.isReducedModule">
                        <div class="locksSizeText" [class.h2]="selectedModuleInfo.locks[6]?.businessType !== 'disabled'" [class.h6]="selectedModuleInfo.locks[6]?.businessType === 'disabled'">{{selectedModuleInfo.locks[6]?.businessType !== 'disabled' ? (selectedModuleInfo.locks[6]?.businessType !== 'tech' ?
                          (selectedModuleInfo.locks[6]?.businessType !== 'screen' ?
                          selectedModuleInfo.locks[6]?.size.toUpperCase() : '') : 'TECH') : 'DISABLED'}}</div>
                      </div>
                      <div
                        *ngIf="selectedModuleInfo.locks[7]?.height !== '' && selectedModuleInfo.locks[7]?.size !== ''"
                        class="lockDiv lockDiv8"
                        [class.isDisabledLock]="selectedModuleInfo.locks[7]?.businessType === 'disabled'"
                        [class.isTech]="selectedModuleInfo.locks[7]?.businessType === 'tech'"
                        [class.screenMod3]="selectedModuleInfo.locks[7]?.businessType === 'screen' && selectedModuleInfo.isReducedModule"
                        [class.screenMod4]="selectedModuleInfo.locks[7]?.businessType === 'screen' && !selectedModuleInfo.isReducedModule">
                        <div class="locksSizeText" [class.h2]="selectedModuleInfo.locks[7]?.businessType !== 'disabled'" [class.h6]="selectedModuleInfo.locks[7]?.businessType === 'disabled'">{{selectedModuleInfo.locks[7]?.businessType !== 'disabled' ? (selectedModuleInfo.locks[7]?.businessType !== 'tech' ?
                          (selectedModuleInfo.locks[7]?.businessType !== 'screen' ?
                          selectedModuleInfo.locks[7]?.size.toUpperCase() : '') : 'TECH') : 'DISABLED'}}</div>
                      </div>
                    </div>
                    <img src="assets/images/lockerDemo.png" class="imgLocker">
                  </div>
                </div>
              </div>
              <div class="moduleInfoOptionsDiv1Div22 col-lg-12 col-xl-12 col-xxl-4"
                *ngIf="selectedModuleInfo.twoColumnModule">
                <div class=" columnMapDouble columnMapDouble1">
                  <div class="twoColumnModuleColumnLabel2 h5" *ngIf="selectedModuleInfo.twoColumnModule">Columna
                    {{selectedModuleInfo.id}}:</div>
                  <div class="imgContainer">
                    <div class="lockersDiv">
                      <div
                        *ngIf="selectedModuleInfo.locks[0]?.height !== '' && selectedModuleInfo.secondColumnStart ? (selectedModuleInfo.secondColumnStart >0) : true  && selectedModuleInfo.locks[0]?.size !== ''"
                        class="lockDiv lockDiv1"
                        [class.isDisabledLock]="selectedModuleInfo.locks[0]?.businessType === 'disabled'"
                        [class.isTech]="selectedModuleInfo.locks[0]?.businessType === 'tech'"
                        [class.screenMod3]="selectedModuleInfo.locks[0]?.businessType === 'screen' && selectedModuleInfo.isReducedModule"
                        [class.screenMod4]="selectedModuleInfo.locks[0]?.businessType === 'screen' && !selectedModuleInfo.isReducedModule">
                        <div class="locksSizeText" [class.h2]="selectedModuleInfo.locks[0]?.businessType !== 'disabled'" [class.h6]="selectedModuleInfo.locks[0]?.businessType === 'disabled'">{{selectedModuleInfo.locks[0]?.businessType !== 'disabled' ? (selectedModuleInfo.locks[0]?.businessType !== 'tech' ?
                          (selectedModuleInfo.locks[0]?.businessType !== 'screen' ?
                          selectedModuleInfo.locks[0]?.size.toUpperCase() : '') : 'TECH') : 'DISABLED'}}</div>
                      </div>
                      <div
                        *ngIf="selectedModuleInfo.locks[1]?.height !== '' && selectedModuleInfo.secondColumnStart ? (selectedModuleInfo.secondColumnStart >1) : true  && selectedModuleInfo.locks[1]?.size !== ''"
                        class="lockDiv lockDiv2"
                        [class.isDisabledLock]="selectedModuleInfo.locks[1]?.businessType === 'disabled'"
                        [class.isTech]="selectedModuleInfo.locks[1]?.businessType === 'tech'"
                        [class.screenMod3]="selectedModuleInfo.locks[1]?.businessType === 'screen' && selectedModuleInfo.isReducedModule"
                        [class.screenMod4]="selectedModuleInfo.locks[1]?.businessType === 'screen' && !selectedModuleInfo.isReducedModule">
                        <div class="locksSizeText" [class.h2]="selectedModuleInfo.locks[1]?.businessType !== 'disabled'" [class.h6]="selectedModuleInfo.locks[1]?.businessType === 'disabled'">{{selectedModuleInfo.locks[1]?.businessType !== 'disabled' ? (selectedModuleInfo.locks[1]?.businessType !== 'tech' ?
                          (selectedModuleInfo.locks[1]?.businessType !== 'screen' ?
                          selectedModuleInfo.locks[1]?.size.toUpperCase() : '') : 'TECH') : 'DISABLED'}}</div>
                      </div>
                      <div
                        *ngIf="selectedModuleInfo.locks[2]?.height !== '' && selectedModuleInfo.secondColumnStart ? (selectedModuleInfo.secondColumnStart >2) : true  && selectedModuleInfo.locks[2]?.size !== ''"
                        class="lockDiv lockDiv3"
                        [class.isDisabledLock]="selectedModuleInfo.locks[2]?.businessType === 'disabled'"
                        [class.isTech]="selectedModuleInfo.locks[2]?.businessType === 'tech'"
                        [class.screenMod3]="selectedModuleInfo.locks[2]?.businessType === 'screen' && selectedModuleInfo.isReducedModule"
                        [class.screenMod4]="selectedModuleInfo.locks[2]?.businessType === 'screen' && !selectedModuleInfo.isReducedModule">
                        <div class="locksSizeText" [class.h2]="selectedModuleInfo.locks[2]?.businessType !== 'disabled'" [class.h6]="selectedModuleInfo.locks[2]?.businessType === 'disabled'">{{selectedModuleInfo.locks[2]?.businessType !== 'disabled' ? (selectedModuleInfo.locks[2]?.businessType !== 'tech' ?
                          (selectedModuleInfo.locks[2]?.businessType !== 'screen' ?
                          selectedModuleInfo.locks[2]?.size.toUpperCase() : '') : 'TECH') : 'DISABLED'}}</div>
                      </div>
                      <div
                        *ngIf="selectedModuleInfo.locks[3]?.height !== '' && selectedModuleInfo.secondColumnStart ? (selectedModuleInfo.secondColumnStart >3) : true  && selectedModuleInfo.locks[3]?.size !== ''"
                        class="lockDiv lockDiv4"
                        [class.isDisabledLock]="selectedModuleInfo.locks[3]?.businessType === 'disabled'"
                        [class.isTech]="selectedModuleInfo.locks[3]?.businessType === 'tech'"
                        [class.screenMod3]="selectedModuleInfo.locks[3]?.businessType === 'screen' && selectedModuleInfo.isReducedModule"
                        [class.screenMod4]="selectedModuleInfo.locks[3]?.businessType === 'screen' && !selectedModuleInfo.isReducedModule">
                        <div class="locksSizeText" [class.h2]="selectedModuleInfo.locks[3]?.businessType !== 'disabled'" [class.h6]="selectedModuleInfo.locks[3]?.businessType === 'disabled'">{{selectedModuleInfo.locks[3]?.businessType !== 'disabled' ? (selectedModuleInfo.locks[3]?.businessType !== 'tech' ?
                          (selectedModuleInfo.locks[3]?.businessType !== 'screen' ?
                          selectedModuleInfo.locks[3]?.size.toUpperCase() : '') : 'TECH') : 'DISABLED'}}</div>
                      </div>
                      <div
                        *ngIf="selectedModuleInfo.locks[4]?.height !== '' && selectedModuleInfo.secondColumnStart ? (selectedModuleInfo.secondColumnStart >4) : true  && selectedModuleInfo.locks[4]?.size !== ''"
                        class="lockDiv lockDiv5"
                        [class.isDisabledLock]="selectedModuleInfo.locks[4]?.businessType === 'disabled'"
                        [class.isTech]="selectedModuleInfo.locks[4]?.businessType === 'tech'"
                        [class.screenMod3]="selectedModuleInfo.locks[4]?.businessType === 'screen' && selectedModuleInfo.isReducedModule"
                        [class.screenMod4]="selectedModuleInfo.locks[4]?.businessType === 'screen' && !selectedModuleInfo.isReducedModule">
                        <div class="locksSizeText" [class.h2]="selectedModuleInfo.locks[4]?.businessType !== 'disabled'" [class.h6]="selectedModuleInfo.locks[4]?.businessType === 'disabled'">{{selectedModuleInfo.locks[4]?.businessType !== 'disabled' ? (selectedModuleInfo.locks[4]?.businessType !== 'tech' ?
                          (selectedModuleInfo.locks[4]?.businessType !== 'screen' ?
                          selectedModuleInfo.locks[4]?.size.toUpperCase() : '') : 'TECH') : 'DISABLED'}}</div>
                      </div>
                      <div
                        *ngIf="selectedModuleInfo.locks[5]?.height !== '' && selectedModuleInfo.secondColumnStart ? (selectedModuleInfo.secondColumnStart >5) : true  && selectedModuleInfo.locks[5]?.size !== ''"
                        class="lockDiv lockDiv6"
                        [class.isDisabledLock]="selectedModuleInfo.locks[5]?.businessType === 'disabled'"
                        [class.isTech]="selectedModuleInfo.locks[5]?.businessType === 'tech'"
                        [class.screenMod3]="selectedModuleInfo.locks[5]?.businessType === 'screen' && selectedModuleInfo.isReducedModule"
                        [class.screenMod4]="selectedModuleInfo.locks[5]?.businessType === 'screen' && !selectedModuleInfo.isReducedModule">
                        <div class="locksSizeText" [class.h2]="selectedModuleInfo.locks[5]?.businessType !== 'disabled'" [class.h6]="selectedModuleInfo.locks[5]?.businessType === 'disabled'">{{selectedModuleInfo.locks[5]?.businessType !== 'disabled' ? (selectedModuleInfo.locks[5]?.businessType !== 'tech' ?
                          (selectedModuleInfo.locks[5]?.businessType !== 'screen' ?
                          selectedModuleInfo.locks[5]?.size.toUpperCase() : '') : 'TECH') : 'DISABLED'}}</div>
                      </div>
                      <div
                        *ngIf="selectedModuleInfo.locks[6]?.height !== '' && selectedModuleInfo.secondColumnStart ? (selectedModuleInfo.secondColumnStart >6) : true  && selectedModuleInfo.locks[6]?.size !== ''"
                        class="lockDiv lockDiv7"
                        [class.isDisabledLock]="selectedModuleInfo.locks[6]?.businessType === 'disabled'"
                        [class.isTech]="selectedModuleInfo.locks[6]?.businessType === 'tech'"
                        [class.screenMod3]="selectedModuleInfo.locks[6]?.businessType === 'screen' && selectedModuleInfo.isReducedModule"
                        [class.screenMod4]="selectedModuleInfo.locks[6]?.businessType === 'screen' && !selectedModuleInfo.isReducedModule">
                        <div class="locksSizeText" [class.h2]="selectedModuleInfo.locks[6]?.businessType !== 'disabled'" [class.h6]="selectedModuleInfo.locks[6]?.businessType === 'disabled'">{{selectedModuleInfo.locks[6]?.businessType !== 'disabled' ? (selectedModuleInfo.locks[6]?.businessType !== 'tech' ?
                          (selectedModuleInfo.locks[6]?.businessType !== 'screen' ?
                          selectedModuleInfo.locks[6]?.size.toUpperCase() : '') : 'TECH') : 'DISABLED'}}</div>
                      </div>
                      <div
                        *ngIf="selectedModuleInfo.locks[7]?.height !== '' && selectedModuleInfo.secondColumnStart ? (selectedModuleInfo.secondColumnStart >7) : true  && selectedModuleInfo.locks[7]?.size !== ''"
                        class="lockDiv lockDiv8"
                        [class.isDisabledLock]="selectedModuleInfo.locks[7]?.businessType === 'disabled'"
                        [class.isTech]="selectedModuleInfo.locks[7]?.businessType === 'tech'"
                        [class.screenMod3]="selectedModuleInfo.locks[7]?.businessType === 'screen' && selectedModuleInfo.isReducedModule"
                        [class.screenMod4]="selectedModuleInfo.locks[7]?.businessType === 'screen' && !selectedModuleInfo.isReducedModule">
                        <div class="locksSizeText" [class.h2]="selectedModuleInfo.locks[7]?.businessType !== 'disabled'" [class.h6]="selectedModuleInfo.locks[7]?.businessType === 'disabled'">{{selectedModuleInfo.locks[7]?.businessType !== 'disabled' ? (selectedModuleInfo.locks[7]?.businessType !== 'tech' ?
                          (selectedModuleInfo.locks[7]?.businessType !== 'screen' ?
                          selectedModuleInfo.locks[7]?.size.toUpperCase() : '') : 'TECH') : 'DISABLED'}}</div>
                      </div>
                    </div>
                    <img src="assets/images/lockerDemo.png" class="imgLocker">
                  </div>
                </div>
                <div class="columnMapDouble columnMapDouble2" *ngIf="selectedModuleInfo.twoColumnModule">
                  <div class="twoColumnModuleColumnLabel2 h5">Columna
                    {{string.fromCharCode(selectedModuleInfo.id.charCodeAt(0) + 1)}}:</div>
                  <div class="imgContainer">
                    <div class="lockersDiv">
                      <!-- <div *ngIf="selectedModuleInfo.secondColumnStart && selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart]?.height !== '' && !(selectedModuleInfo.secondColumnStart >=0)" class="lockDiv lockDiv9" > <div class="locksSizeText" [class.h2]="selectedModuleInfo.locks[0]?.businessType !== 'disabled'" [class.h6]="selectedModuleInfo.locks[0]?.businessType === 'disabled'">{{selectedModuleInfo.locks[0]?.size.toUpperCase()}}</div></div> -->
                      <div
                        *ngIf="selectedModuleInfo.secondColumnStart && selectedModuleInfo.locks.length>=selectedModuleInfo.secondColumnStart && selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart]?.height !== ''"
                        class="lockDiv lockDiv9"
                        [class.isDisabledLock]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart]?.businessType === 'disabled'"
                        [class.screenMod3]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart]?.businessType === 'screen' && selectedModuleInfo.isReducedModule"
                        [class.screenMod4]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart]?.businessType === 'screen' && !selectedModuleInfo.isReducedModule">
                        <div class="locksSizeText" [class.h2]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart]?.businessType !== 'disabled'" [class.h6]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart]?.businessType === 'disabled'">
                          {{selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart]?.businessType !== 'disabled' ? (selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart]?.businessType !==
                          'tech' ? (selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart]?.businessType
                          !== 'screen' ?
                          selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart]?.size.toUpperCase() : '')
                          : 'TECH') : 'DISABLED'}}</div>
                      </div>
                      <div
                        *ngIf="selectedModuleInfo.secondColumnStart && selectedModuleInfo.locks.length>=(selectedModuleInfo.secondColumnStart + 1) && selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 1]?.height !== ''"
                        class="lockDiv lockDiv10"
                        [class.isDisabledLock]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 1]?.businessType === 'disabled'"
                        [class.screenMod3]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 1]?.businessType === 'screen' && selectedModuleInfo.isReducedModule"
                        [class.screenMod4]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 1]?.businessType === 'screen' && !selectedModuleInfo.isReducedModule">
                        <div class="locksSizeText" [class.h2]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 1]?.businessType !== 'disabled'" [class.h6]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 1]?.businessType === 'disabled'">
                          {{selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 1]?.businessType !== 'disabled' ? (selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 1]?.businessType !==
                          'tech' ? (selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart +
                          1]?.businessType !== 'screen' ?
                          selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 1]?.size.toUpperCase() :
                          '') : 'TECH') : 'DISABLED'}}</div>
                      </div>
                      <div
                        *ngIf="selectedModuleInfo.secondColumnStart && selectedModuleInfo.locks.length>=(selectedModuleInfo.secondColumnStart + 2) && selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 2]?.height !== ''"
                        class="lockDiv lockDiv11"
                        [class.isDisabledLock]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 2]?.businessType === 'disabled'"
                        [class.screenMod3]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 2]?.businessType === 'screen' && selectedModuleInfo.isReducedModule"
                        [class.screenMod4]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 2]?.businessType === 'screen' && !selectedModuleInfo.isReducedModule">
                        <div class="locksSizeText" [class.h2]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 2]?.businessType !== 'disabled'" [class.h6]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 2]?.businessType === 'disabled'">
                          {{selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 2]?.businessType !== 'disabled' ? (selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 2]?.businessType !==
                          'tech' ? (selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart +
                          2]?.businessType !== 'screen' ?
                          selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 2]?.size.toUpperCase() :
                          '') : 'TECH') : 'DISABLED'}}</div>
                      </div>
                      <div
                        *ngIf="selectedModuleInfo.secondColumnStart && selectedModuleInfo.locks.length>=(selectedModuleInfo.secondColumnStart + 3 ) && selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 3]?.height !== ''"
                        class="lockDiv lockDiv12"
                        [class.isDisabledLock]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 3]?.businessType === 'disabled'"
                        [class.screenMod3]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 3]?.businessType === 'screen' && selectedModuleInfo.isReducedModule"
                        [class.screenMod4]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 3]?.businessType === 'screen' && !selectedModuleInfo.isReducedModule">
                        <div class="locksSizeText" [class.h2]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 3]?.businessType !== 'disabled'" [class.h6]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 3]?.businessType === 'disabled'">
                          {{selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 3]?.businessType !== 'disabled' ? (selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 3]?.businessType !==
                          'tech' ? (selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart +
                          3]?.businessType !== 'screen' ?
                          selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 3]?.size.toUpperCase() :
                          '') : 'TECH') : 'DISABLED'}}</div>
                      </div>
                      <div
                        *ngIf="selectedModuleInfo.secondColumnStart && selectedModuleInfo.locks.length>=(selectedModuleInfo.secondColumnStart + 4) && selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 4]?.height !== ''"
                        class="lockDiv lockDiv13"
                        [class.isDisabledLock]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 4]?.businessType === 'disabled'"
                        [class.screenMod3]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 4]?.businessType === 'screen' && selectedModuleInfo.isReducedModule"
                        [class.screenMod4]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 4]?.businessType === 'screen' && !selectedModuleInfo.isReducedModule">
                        <div class="locksSizeText" [class.h2]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 4]?.businessType !== 'disabled'" [class.h6]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 4]?.businessType === 'disabled'">
                          {{selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 4]?.businessType !== 'disabled' ? (selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 4]?.businessType !==
                          'tech' ? (selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart +
                          4]?.businessType !== 'screen' ?
                          selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 4]?.size.toUpperCase() :
                          '') : 'TECH') : 'DISABLED'}}</div>
                      </div>
                      <div
                        *ngIf="selectedModuleInfo.secondColumnStart && selectedModuleInfo.locks.length>=(selectedModuleInfo.secondColumnStart + 5) && selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 5]?.height !== ''"
                        class="lockDiv lockDiv14"
                        [class.isDisabledLock]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 5]?.businessType === 'disabled'"
                        [class.screenMod3]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 5]?.businessType === 'screen' && selectedModuleInfo.isReducedModule"
                        [class.screenMod4]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 5]?.businessType === 'screen' && !selectedModuleInfo.isReducedModule">
                        <div class="locksSizeText" [class.h2]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 5]?.businessType !== 'disabled'" [class.h6]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 5]?.businessType === 'disabled'">
                          {{selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 5]?.businessType !== 'disabled' ? (selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 5]?.businessType !==
                          'tech' ? (selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart +
                          5]?.businessType !== 'screen' ?
                          selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 5]?.size.toUpperCase() :
                          '') : 'TECH') : 'DISABLED'}}</div>
                      </div>
                      <div
                        *ngIf="selectedModuleInfo.secondColumnStart && selectedModuleInfo.locks.length>=(selectedModuleInfo.secondColumnStart + 6) && selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 6]?.height !== ''"
                        class="lockDiv lockDiv15"
                        [class.isDisabledLock]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 6]?.businessType === 'disabled'"
                        [class.screenMod3]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 6]?.businessType === 'screen' && selectedModuleInfo.isReducedModule"
                        [class.screenMod4]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 6]?.businessType === 'screen' && !selectedModuleInfo.isReducedModule">
                        <div class="locksSizeText" [class.h2]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 6]?.businessType !== 'disabled'" [class.h6]="selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 6]?.businessType === 'disabled'">
                          {{selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 6]?.businessType !== 'disabled' ? (selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 6]?.businessType !==
                          'tech' ? (selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart +
                          6]?.businessType !== 'screen' ?
                          selectedModuleInfo.locks[selectedModuleInfo.secondColumnStart + 6]?.size.toUpperCase() :
                          '') : 'TECH') : 'DISABLED'}}</div>
                      </div>
                    </div>
                    <img src="assets/images/lockerDemo.png" class="imgLocker">
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="moduleInfoOptionsDiv2Div">
            <button class="btn btn-danger ms-4 btn-lg" (click)="exitModuleInfoOptionsDiv()">Cancelar</button>
            <button class="btn btn-success ms-4 btn-lg"
              (click)="exitAndSaveModuleInfoOptionsDiv()">Guardar</button>
          </div>
        </div>

        <div class="notificationDiv" *ngIf="notificationText">
          <div class="notificationText">{{notificationText}}</div>
        </div>
        <!-- <div class="buttons text-end" >
    <button class="btn btn-success ms-2" [disabled]="loading" (click)="saveLocksSizes()">Guardar</button>
  </div> -->
      </div>
    </div>
  </div>
  <div class="spinner" [hidden]="!loading">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</div>