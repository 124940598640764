import { style } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { PlatformService } from '../../../services/platform.service';

@Component({
  selector: 'app-configuration-styles',
  templateUrl: './styles.component.html',
  styleUrls: ['./styles.component.scss']
})
export class ConfigurationStylesComponent implements OnInit {

  operationsUrl: any;
  message: string;
  messageError: boolean = false;
  loading: boolean = false;
  responseStyle: string = '';

  lockerId: string;
  client: string;
  flagInPort: any;
  typeOperationProcess: number = 1;
  styleSelected: string = 'Seleccionar';

  @Input()
  get stylesV(): any { return this.styles; }
  set stylesV(obj: any) {
    console.log({method: 'stylesV', data: obj})
    if (obj) {
      this.styles = obj;
      console.log({styles: this.styles});
      if (this.styles.length > 0) {
        this.styleSelected = this.styles[0].id;
        this.changeStyle();
      }
    }
  }

  styles = [];

  constructor(
    private _platformService: PlatformService
  ) { }

  ngOnInit(): void {
    this.lockerId = localStorage.getItem('lockerid');
    this.client = localStorage.getItem('companyName');
  }

  async changeStyle() {
    for (const style of this.styles) {
      if (style.id == this.styleSelected) {
        this.responseStyle = JSON.stringify(style, null, 4);
      }
    }
  }

  async save() {
    const data: any = {
      lockerId: this.lockerId,
      styleId: this.styleSelected
    }
    this._platformService.updateStyleOwn(data).subscribe((res: any) => {
      alert('Asociado correctamente');
      this.loading = false;
    }, (err) => {
      this.loading = false;
    })
    return;
  }

  cancel() {
    
  }

}
