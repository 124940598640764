import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LockerStaffService {

  constructor(private http: HttpClient) { }

  getAllUsers(lockerId, saveIncidence) {
    return this.http.post(`${environment.apiUrl}/locker-staff`, { lockerId, saveIncidence })
  }

  createUser(user) {
    return this.http.post(`${environment.apiUrl}/locker-staff/create`, user);
  }

  updateUser(user) {
    return this.http.post(`${environment.apiUrl}/locker-staff/update`, user);
  }

  deleteUser(data) {
    return this.http.post(`${environment.apiUrl}/locker-staff/delete`, data);
  }
}
