<div class="col-md-12">
    <h4 class="float-start">Usuarios Configuration Tool</h4>
    <div class="text-end mt-4 mb-4">
        <button class="btn btn-primary ms-2" (click)="openModal(template)">Agregar</button>
    </div>
    <table class="table">
        <thead>
            <tr>
                <th>Nombre</th>
                <th>Email</th>
                <th width="150"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let user of users">
                <td>{{user.name}}</td>
                <td>{{user.email}}</td>
                <td>
                    <button (click)="update(user, template)" class="me-2">Editar</button>
                    <button (click)="delete(user)">Eliminar</button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{titleOperation}} usuario</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <label for="name">Name</label>
                <input type="text" class="form-control" name="name" id="name" placeholder="Nombre" [(ngModel)]="selected.name">
            </div>
            <div class="col-md-6">
                <label for="email">Email</label>
                <input type="email" class="form-control" name="email" id="email" placeholder="Email" [(ngModel)]="selected.email">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="password">Password</label>
                <input type="password" class="form-control" name="password" id="password" placeholder="Password" [(ngModel)]="selected.password">
            </div>
            <div class="col-md-6">
                <label for="password2">Confirmar Password</label>
                <input type="password" class="form-control" name="password2" id="password2" placeholder="Confirmar Password" [(ngModel)]="selected.password2">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 mt-2">
                <label class="form-label" for="">Permisos de configuracion</label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="selected.config.specs" (change)="changeLanguage()" type="checkbox" id="inlineCheckbox4" >
                  <label class="form-check-label" for="inlineCheckbox1">Specs</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="selected.config.params" (change)="changeLanguage()" type="checkbox" id="inlineCheckbox5" >
                  <label class="form-check-label" for="inlineCheckbox2">Params</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" [(ngModel)]="selected.config.specialParams" (change)="changeLanguage()" type="checkbox" id="inlineCheckbox4" >
                    <label class="form-check-label" for="inlineCheckbox1">Special params</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" [(ngModel)]="selected.config.network" (change)="changeLanguage()" type="checkbox" id="inlineCheckbox5" >
                    <label class="form-check-label" for="inlineCheckbox2">Network</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" [(ngModel)]="selected.config.users" (change)="changeLanguage()" type="checkbox" id="inlineCheckbox5" >
                    <label class="form-check-label" for="inlineCheckbox2">Users</label>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default btn-primary" (click)="save()">Guardar</button>
    </div>
</ng-template>
<div class="spinner" [hidden]="!loading">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>