<div class="row">
  <div class="col-md-12 ">
    <div class="row">
      <div class="text-end col-md-12">
        <button class="btn btn-success ms-2" [disabled]="loading" (click)="save()">Guardar</button>
        <button class="btn btn-primary ms-2" [disabled]="loading" (click)="showModal(addUser, 1)">Agregar</button>
        <button class="btn btn-primary ms-2" [disabled]="loading" (click)="cancel()">Cancelar</button>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <table class="table">
      <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
          <th>Email</th>
          <th>Username</th>
          <th>Teléfono</th>
          <th>Notificación</th>
          <th>Rol</th>
          <th width="80" class="text-center"></th>
          <th width="100" class="text-center"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of users; let i = index">
          <td>{{user.id}}</td>
          <td>{{user.name}}</td>
          <td>{{user.email}}</td>
          <td>{{user.username}}</td>
          <td>{{user.phoneNumber}}</td>
          <td>{{user.notificationChannelStr}}</td>
          <td>{{user.roleStr}}</td>
          <td><button class="btn btn-primary" (click)="editUser(user, i)">Editar</button></td>
          <td><button class="btn btn-primary" (click)="removeUser(user, i)">Eliminar</button></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-template #addUser>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Agregar Usuario</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-6">
        <label for="id" class="form-label">Id</label>
        <input class="form-control" type="text" name="id" [(ngModel)]="userModel.id" id="id"
          placeholder="hmy@hmy.hmy">
      </div>
      <div class="col-md-6">
        <label for="name" class="form-label">Name</label>
        <input class="form-control" type="text" name="name" [(ngModel)]="userModel.name" id="name"
          placeholder="hmy">
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label for="email" class="form-label">Email</label>
        <input class="form-control" type="text" name="email" [(ngModel)]="userModel.email" id="email"
          placeholder="hmy@hmy.hmy">
      </div>
      <div class="col-md-6">
        <label for="username" class="form-label">Username</label>
        <input class="form-control" type="text" name="username" [(ngModel)]="userModel.username" id="username"
          placeholder="hmy">
      </div>
    </div>
    <div class="row">
      <div class="col-md-7">
        <label for="password" class="form-label">Password</label>
        <input class="form-control" type="text" name="password" [(ngModel)]="userModel.password" id="password"
          placeholder="1234">
      </div>
      <div class="col-md-5">
        <label for="phone" class="form-label">Teléfono</label>
        <input class="form-control" type="number" name="phone" [(ngModel)]="userModel.phoneNumber" id="phone"
          placeholder="+54 111 222 33 44">
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="col-md-6 mt-2">
          <label class="form-label" for="">Modos de notificación</label>
          <div class="form-check form-check-inline">
            <input class="form-check-input" [(ngModel)]="userModel.notificationChannelB.sms"
              (change)="changeNotification()" type="checkbox" id="inlineCheckbox4">
            <label class="form-check-label" for="inlineCheckbox1">Sms</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" [(ngModel)]="userModel.notificationChannelB.email"
              (change)="changeNotification()" type="checkbox" id="inlineCheckbox5">
            <label class="form-check-label" for="inlineCheckbox2">Email</label>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <label for="width" class="form-label">Rol</label>
        <select name="role" [(ngModel)]="userModel.role" change="changeRole()" id="role" class="form-control">
          <option value="">Seleccionar</option>
          <option value="staffAll">CC y Consigna</option>
          <option value="staffCC">CC</option>
          <option value="staffConsigment">Consigna</option>
        </select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default btn-danger" (click)="modalRef.hide()">Cancelar</button>
    <button type="button" class="btn btn-default btn-primary" (click)="saveUser()">Agregar</button>
  </div>
</ng-template>