<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<style>
  
</style>

<!-- Toolbar -->
<div class="navbar toolbar" role="banner" *ngIf="logged">
  <div style="display: flex; align-items: center;">
    <img
      width="90"
      alt="Angular Logo"
      [src]="logoHMY"
    />
    <span (click)="goToHome()" style="cursor: pointer;">Support Tool</span>
    <li class="nav-item dropdown" style="list-style: none;">
      <a class="nav-link dropdown-toggle" *ngIf="logged && role == 3" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style="color: #888 !important;">
        Usuarios
      </a>
      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
        <li><a class="dropdown-item" [class]="{'active': route == '/users'}" style="color: #888 !important; cursor: pointer;" *ngIf="logged && role == 3" aria-current="page" (click)="goToUsers()" >Herramienta de Soporte</a></li>
        <li><a class="dropdown-item" [class]="{'active': route == '/configuration-tool'}" style="color: #888 !important; cursor: pointer;" *ngIf="logged && role == 3" aria-current="page" (click)="goToConfigurationTool()">Herramienta de Configuración</a></li>
        <li><a class="dropdown-item" [class]="{'active': route == '/super-client'}" style="color: #888 !important; cursor: pointer;" *ngIf="logged && role == 3" aria-current="page" (click)="goToSuperClient()">Super Cliente</a></li>
      </ul>
    </li>
    <li class="nav-item dropdown" style="list-style: none;">
      <a class="nav-link" *ngIf="logged && role == 3" role="button" style="color: #888 !important; cursor: pointer;" (click)="goToNivel3()">
        Nivel 3
      </a>
    </li>
  </div>
  <a style="color: white; margin-right: 10px; cursor: pointer;" (click)="logout()" *ngIf="logged">Logout</a>
</div>
<div [ngClass]="{'container mt-4': logged}">
  <div class="row justify-content-md-center" *ngIf="logged && route != '/home' && route != '/users' && showResumen && route != '/configuration-tool' && route != '/super-client' && route != '/super-level3'">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <ul style="margin-bottom: 0;">
                <li>Fecha: {{fecha}}</li>
                <li>Hora: {{hora_inicio}}</li>
                <li>Status: <span [class]="{'text-success': ping_result == 'Online', 'text-danger': ping_result == 'Offline'}">{{ping_result}}</span></li>
              </ul>
            </div>
            <div class="col-md-4">
              <ul>
                <li>Cliente: {{companyName}}</li>
                <li>Usuario: {{username}}</li>
                <li>Diagnostic: {{diagnostic_result}}</li>
              </ul>
            </div>
            <div class="col-md-4">
              <ul>
                <li>LockerId: {{lockerId}}</li>
                <li>Ubicación: {{location}}</li>  
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-2 step-row" *ngIf="logged && route != '/home' && route != '/users' && showResumen && step !== 1">
    <div class="col-md-12">
      <div class="row">
        <div class="text-center p-3 nivel-tab" (click)="goToNivel(2)" [ngClass]="{'active': step == 2, 'col-md-6': role == 1, 'col-md-4': role == 2, 'col-md-3': role == 3}">
          <span>
          Nivel 1
          </span>
        </div>
        <div class="text-center p-3 nivel-tab" (click)="goToNivel(3)" *ngIf="role == 2 || role == 3" [ngClass]="{'active': step == 3, 'col-md-6': role == 1, 'col-md-4': role == 2, 'col-md-3': role == 3}">
          <span>
          Nivel 2
          </span>
        </div>
        <div class="text-center p-3 nivel-tab" (click)="goToNivel(4)" *ngIf="role == 3" [ngClass]="{'active': step == 4, 'col-md-6': role == 1, 'col-md-4': role == 2, 'col-md-3': role == 3}">
          <span>
          Nivel 3
          </span>
        </div>
        <div class="text-center p-3 nivel-tab" (click)="goToNivel(5)" [ngClass]="{'active': step == 5, 'col-md-6': role == 1, 'col-md-4': role == 2, 'col-md-3': role == 3}">
          <span>
          Final
          </span> 
        </div>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
