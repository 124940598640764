<div class="row justify-content-md-center pt-3" #contentPDF>
    <div class="col-md-12">
        <h3 style="text-align: center;">Reporte de Incidencia</h3>
        <table style="width: 100%; border: 1px solid #ccc; margin: auto;">
            <tbody>
                <tr>
                    <td>Fecha: {{fecha}}</td>
                    <td>Finalizacion: {{hora_fin}}</td>
                    <td>Cliente: {{companyName}}</td>
                    <td>LockerId: {{lockerId}}</td>
                </tr>
                <tr>
                    <td>Hora: {{hora_inicio}}</td>
                    <td>Duracion: {{duration}}m</td>
                    <td>Usuario: {{username}}</td>
                    <td>Ubicación Mueble: {{location}}</td>
                </tr>
            </tbody>
        </table>
        <strong>Mensaje de Inicio:</strong>
        <p>{{startMessage}}</p>
        <hr>
        <div *ngFor="let request of requests">
            <strong>{{request.hora}} - URL: {{request.uri}}</strong>
            <p *ngIf="request.data && !emptyObject(request.data)">Data: {{request.dataString}}</p>
            <p>Status: {{request.status}}</p>
            <pre *ngIf="!request.uri.includes('diagnostic')">{{request.responseString}}</pre>
            <div [innerHtml]="request.responseString" *ngIf="request.uri.includes('diagnostic')" ></div>
            <hr>
        </div>
        <strong>Mensaje Final :</strong>
        <p>{{endMessage}}</p>
    </div>
</div>