import { Component, Input, OnInit } from '@angular/core';
import { PlatformService } from '../../../services/platform.service';


@Component({
  selector: 'app-configuration-params',
  templateUrl: './params.component.html',
  styleUrls: ['./params.component.scss']
})
export class ConfigurationParamsComponent implements OnInit {

  operationsUrl: any;
  message: string;
  messageError: boolean = false;
  loading: boolean = false;

  lockerId: string;
  client: string;
  flagInPort: any;
  typeOperationProcess: number = 1;

  paramsModel: any = {
    lateOpenDelay: 5,
    expiredDelayCC: 168,
    expiredDelayCustomer: 24,
    waitForCCNotification: 5,
    notificationTimeOutPreExpiredOrderCCMinutes: '',
    notificationTimeOutPreExpiredOrderConsignaMinutes: '',
    expiredDelayStaff: 0,
    statusLocker: '',
    outOfService: '',
    onlyPickupMode: '',
    hasPayment: '',
    consignaCostPerMinute: 0,
    lyraEmail: '',
    companyName: '',
    supportCompanyName: '',
    supportCompanyNameES: '',
    supportCompanyNameEN: '',
    supportCompanyNameFR: '',
    portRS485: '',
    customerConsignmentNotification: '',
    customerCCNotification: '',
  }

  @Input()
  get paramsModelV(): any { return this.paramsModel; }
  set paramsModelV(obj: any) {
    if (obj) {
      this.paramsModel = obj;
      setTimeout(() => {
        this.paramsModel.hasPayment = (this.paramsModel.hasPayment) ? 'Si' : 'No';
      }, 500)
    }
  }
  
  constructor(private _platformService: PlatformService) { }

  ngOnInit(): void {
    this.lockerId = localStorage.getItem('lockerid');
    this.client = localStorage.getItem('companyName');
  }

  cancel() {
    this.paramsModel = {
      lateOpenDelay: 5,
      expiredDelayCC: 168,
      expiredDelayCustomer: 24,
      waitForCCNotification: 5,
      notificationTimeOutPreExpiredOrderCCMinutes: '',
      notificationTimeOutPreExpiredOrderConsignaMinutes: '',
      expiredDelayStaff: 0,
      statusLocker: '',
      outOfService: '',
      onlyPickupMode: '',
      hasPayment: '',
      consignaCostPerMinute: 0,
      lyraEmail: '',
      lyraPublicKey: '',
      lyraClientToken: '',
      companyName: '',
      supportCompanyName: '',
      supportCompanyNameES: '',
      supportCompanyNameEN: '',
      supportCompanyNameFR: '',
      portRS485: '',
      portRS485BaudRate: '',
      customerConsignmentNotification: '',
      customerCCNotification: '',
    }
  }

  async save() {

    console.log(JSON.stringify(this.paramsModel, null, 4));

    let flagValid = false;
    const data = {};

    for (const key of Object.keys(this.paramsModel)) {
      let value = this.paramsModel[key];
      if(value != '') {
        if (['outOfService', 'onlyPickupMode', 'hasPayment', 'customerConsignmentNotification', 'customerCCNotification'].indexOf(key) !== -1) {
          if (value === 'Si') {
            value = true;
          } else if (value === 'No') {
            value = false;
          }
        }
        flagValid = true;
        data[key] = value
      }
    }
    if(!flagValid) {
      alert('Debe rellenar al menos un campo');
      return;
    }

    data['outOfService'] = false;
    data['onlyPickupMode'] = false;

    switch (data['statusLocker']) {
      case 'Fuera de servicio': data['outOfService'] = true; break;
      case 'Solo recogida': data['onlyPickupMode'] = true; break;
    }

    console.log('data', data);

    const jsonData = {
      clientId: this.client,
      name: `${this.lockerId}`,
      lockerId: this.lockerId,
      listConfigMedia: [{
        name: `${this.lockerId}`,
        urlFile: '',
        extensionName: 'json',
        isExternalFile: true
      }],
      params: {
        ...data
      }
    }

    console.log({data, jsonData});

    this.loading = true;
    this._platformService.createJSONs(jsonData).subscribe((res: any) => {
      alert('Configuracion seteada correctamente');
      this.loading = false;
    }, (err) => {
      this.loading = false;
    })
  }

}
