import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { UtilsService } from '../../services/utils.service';
import { LoginService } from '../../services/login.service';
import { SupportService } from '../../services/support.service';

import jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  @ViewChild('contentPDF') contentPDF: ElementRef ;

  startMessage: string = '';
  endMessage: string = '';
  requests: any = [];

  fecha: string = '';
  hora_inicio: string = '';
  hora_fin: string = '';
  duration: string = '';
  companyName: string = '';
  username: string = '';
  lockerId: string = '';
  location: string = '';

  constructor(private router: Router, 
    private _supportService: SupportService, 
    private _loginService: LoginService, 
    private cdRef:ChangeDetectorRef,
    private _utilsService: UtilsService) { }

  ngOnInit(): void {
    this._loginService.updateShowResumen(false);
    const incidence = localStorage.getItem('incidence');
    this._supportService.getIncidenceById(incidence).subscribe((res: any) => {
      this.startMessage = res.startMessage;
      this.endMessage = res.endMessage;
      this.requests = res.requests;
      this.fecha = moment(res.startHour).format('DD/MM/YYYY');
      this.hora_fin = moment(res.endHour).format('HH:mm');
      this.hora_inicio = moment(res.startHour).format('HH:mm');
      this.duration = res.duration;
      this.companyName = res.client;
      this.username = localStorage.getItem('username');
      this.lockerId = res.idLock;
      this.location = res.address;
      this.requests = this.requests.map((req: any) => {
        req.hora = moment(req.date).format('HH:mm');
        const data = req.data;
        delete data.lockerId;
        delete data.staff;
        console.log({empty: this.emptyObject(data)});
        req.dataString = JSON.stringify(req.data, null, 4);
        if (!req.uri.includes('diagnostic-locker')) {
          if ((req.response && !req.response.statusCode) || req.response == true || req.response.success == true || (Array.isArray(req.response))) {
            req.responseString = 'OK';
          } else {
            req.responseString = 'NO OK';
          }
          return req;
        }
        let infoData = '';
        if (req.uri.includes('diagnostic-locker')) {
          if (req.response.info) {
            infoData = req.response.info.split("\n").join("<br>");
            infoData = infoData.split("<----->").join("-");
            infoData = this._utilsService.processDiagnostic(infoData);
          } else {
            infoData = 'NO OK'
          }
          
        }
        return {...req, responseString: infoData}
      })
      this.requests.sort((b, a) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      setTimeout(() => {
        this.exportToPDF();
      }, 500)
    })
  }

  goBack() {
    this.router.navigate(['support'])
  }

  exportToPDF() {
    const doc = new jsPDF();
   
    const pdfTable = this.contentPDF.nativeElement;
   
    var html = htmlToPdfmake(pdfTable.innerHTML);
     
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).open(); 

    setTimeout(() => {
      localStorage.clear();
      this._loginService.updateLoginStatus(false);
      const url = (localStorage.getItem('admin') == 'si') ? 'home' : '';
      this.router.navigate([url]);
    }, 1000)
  }

  emptyObject(obj) {
    return Object.keys(obj).length == 0;
  }

}
