<div class="col-md-12">
    <h4 class="float-start">Usuarios de Super Cliente</h4>
    <div class="text-end mt-4 mb-4">
        <button class="btn btn-primary ms-2" (click)="openModal(template)">Agregar</button>
    </div>
    <table class="table">
        <thead>
            <tr>
                <th>Nombre</th>
                <th>Email</th>
                <th width="150"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let user of users">
                <td>{{user.clientName}}</td>
                <td>{{user.email}}</td>
                <td>
                    <button (click)="delete(user)">Eliminar</button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{titleOperation}} super cliente</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <label for="client">Cliente</label>
                <select name="locksPerBoard" [(ngModel)]="selected.client" id="client" class="form-control">
                    <option *ngFor="let client of clients" [value]="client.id">{{client.name}}</option>
                </select>
            </div>
            <div class="col-md-6">
                <label for="email">Email</label>
                <input type="email" class="form-control" name="email" id="email" placeholder="Nombre" [(ngModel)]="selected.email">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="password">Password</label>
                <input type="password" class="form-control" name="password" id="password" placeholder="Password" [(ngModel)]="selected.password">
            </div>
            <div class="col-md-6">
                <label for="password2">Confirmar Password</label>
                <input type="password" class="form-control" name="password2" id="password2" placeholder="Confirmar Password" [(ngModel)]="selected.password2">
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default btn-primary" (click)="save()">Guardar</button>
    </div>
</ng-template>
<div class="spinner" [hidden]="!loading">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>