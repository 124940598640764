<div class="container mt-4">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <label for="clientSelected" class="form-label">Seleccione Cliente</label>
          <select name="clientSelected" [(ngModel)]="clientSelected" (change)="changeClient()" id="clientSelected" class="form-control">
            <option *ngFor="let client of clients" [value]="client.id" >{{client.name}}</option>
          </select>
        </div>
        <div class="col-md-6">
          <label for="lockerId" class="form-label">Seleccione el LockerId</label>
          <select name="lockerId" [disabled]="lockersId.length == 1" [(ngModel)]="lockerId" (change)="changeConfig()" id="lockerId" class="form-control">
            <option *ngFor="let lockId of lockersId" [value]="lockId.id" >{{lockId.name}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="accordion mt-4" id="accordionExample" *ngIf="showAccordion">
    <div class="accordion-item" *ngIf="lockerId !== '1'">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#jsonsTable" aria-expanded="true" aria-controls="collapseOne">
          Consultar tabla de JSONs
        </button>
      </h2>
      <div id="jsonsTable" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <div class="row justify-content-md-center">
            <div class="col-md-2 text-center">
              <button class="btn btn-primary" (click)="download('specs')">Specs</button>
            </div>
            <div class="col-md-2 text-center">
              <button class="btn btn-primary" (click)="download('params')">Params</button>
            </div>
            <div class="col-md-3 text-center">
              <button class="btn btn-primary" (click)="download('special-params')">Special Params</button>
            </div>
            <div class="col-md-2 text-center">
              <button class="btn btn-primary" (click)="download('network')">Network</button>
            </div>
            <div class="col-md-2 text-center">
              <button class="btn btn-primary" (click)="download('users')">Users</button>
            </div>
          </div>
          <a id="downloadAnchorElem" style="display:none"></a>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#restartLocker" aria-expanded="true" aria-controls="collapseOne" (click)="getCronURLs()">
          Programación de cron
        </button>
      </h2>
      <div id="restartLocker" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <div class="row justify-content-md-center">
            <div class="col-md-6">
              <label for="url">URL:</label>
              <select name="url" id="url" [(ngModel)]="selectedURL" (change)="changeURL('selectedURL', 'selectedURLStr', true)" class="form-control">
                <option *ngFor="let url of URLs" [value]="url.id">{{url.name || url.url}}</option>
              </select>
            </div>
          </div>
          <div class="row justify-content-md-center" *ngIf="crondataShow">
            <div class="col-md-6">
              <label for="crondata">Data:</label>
              <pre class="bg-light">{{crondata}}</pre>
            </div>
          </div>
          
          <div class="row justify-content-md-center">
            <div class="col-md-2">
              LockerId: <b>{{lockerId}}</b>
            </div>
            <div class="col-md-4">
              <label for="hour">Hora:</label>
              <select name="hour" id="hour" [(ngModel)]="selectedHour" class="form-control">
                <option *ngFor="let hour of hours">{{hour}}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-end">
              <button class="btn btn-primary" [disabled]="loading" (click)="goBack()">Volver</button>
              <button class="btn btn-success ms-2" [disabled]="loading" (click)="resetLocker()">Programar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion-item" *ngIf="lockerId !== '1'">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#downloadCronLogs" aria-expanded="true" aria-controls="collapseOne" (click)="getCronURLs()">
          Descargar Logs de Crons
        </button>
      </h2>
      <div id="downloadCronLogs" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <div class="row justify-content-md-center">
            <div class="col-md-6">
              <label for="url">URL:</label>
              <select name="url" id="url" [(ngModel)]="selectedURLForDownload" (change)="changeURL('selectedURLForDownload', 'selectedURLForDownloadStr', false)" class="form-control">
                <option *ngFor="let url of URLs" [value]="url.id">{{url.name || url.url}}</option>
              </select>
            </div>
          </div>
          <div class="row justify-content-md-center">
            <div class="col-md-2">
              LockerId: <b>{{lockerId}}</b>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-end">
              <button class="btn btn-success ms-2" [disabled]="loading" (click)="downloadCronLogs()">Descargar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion-item" *ngIf="lockerId !== '1'">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#logsByLockerId" aria-expanded="true" aria-controls="collapseOne">
          Consultar Logs
        </button>
      </h2>
      <div id="logsByLockerId" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <form class="row row-cols-sm-auto">
            <div class="col-12">
              <div class="dp-hidden position-absolute">
                <div class="input-group">
                  <input
                    name="datepicker"
                    class="form-control"
                    ngbDatepicker
                    #datepicker="ngbDatepicker"
                    [autoClose]="'outside'"
                    (dateSelect)="onDateSelection($event)"
                    [displayMonths]="2"
                    [dayTemplate]="t"
                    outsideDays="hidden"
                    [startDate]="fromDate!"
                    tabindex="-1"
                  />
                  <ng-template #t let-date let-focused="focused">
                    <span
                      class="custom-day"
                      [class.focused]="focused"
                      [class.range]="isRange(date)"
                      [class.faded]="isHovered(date) || isInside(date)"
                      (mouseenter)="hoveredDate = date"
                      (mouseleave)="hoveredDate = null"
                    >
                      {{ date.day }}
                    </span>
                  </ng-template>
                </div>
              </div>
              <div class="input-group">
                <input
                  #dpFromDate
                  class="form-control"
                  placeholder="yyyy-mm-dd"
                  name="dpFromDate"
                  [value]="formatter.format(fromDate)"
                  (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                />
                <button class="btn btn-outline-secondary bi bi-calendar3" (click)="datepicker.toggle()" type="button"></button>
              </div>
            </div>
            <div class="col-12">
              <div class="input-group">
                <input
                  #dpToDate
                  class="form-control"
                  placeholder="yyyy-mm-dd"
                  name="dpToDate"
                  [value]="formatter.format(toDate)"
                  (input)="toDate = validateInput(toDate, dpToDate.value)"
                />
                <button class="btn btn-outline-secondary bi bi-calendar3" (click)="datepicker.toggle()" type="button"></button>
              </div>
            </div>
          </form>
          <div class="row">
            <div class="col-md-12 text-end">
              <button class="btn btn-success" (click)="downloadLogs()">Descargar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="spinner" [hidden]="!loading">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>  
  </div>
</div>
<ng-template #template>
  <div class="modal-header">
      <h4 class="modal-title pull-left">{{titleOperation}} usuario</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">×</span>
      </button>
  </div>
  <div class="modal-body">
      <input type="text" class="form-control" name="id" id="id" placeholder="Id" [(ngModel)]="userModel.modal.id">
  </div>
  <div class="modal-body">
      <input type="text" class="form-control" name="name" id="name" placeholder="Nombre" [(ngModel)]="userModel.modal.name">
  </div>
  <div class="modal-body">
      <input type="email" class="form-control" name="email" id="email" placeholder="Email" [(ngModel)]="userModel.modal.email">
  </div>
  <div class="modal-body">
      <select name="role" id="role" [(ngModel)]="userModel.modal.role" class="form-control">
          <option value="">Seleccionar Rol</option>
          <option value="staffAll">Todo</option>
          <option value="staffCC">Consigna</option>
          <option value="staffConsigment">Click && Collect</option>
      </select>
  </div>
  <div class="modal-body">
      <select name="role" id="role" [(ngModel)]="userModel.modal.notificationChannel" class="form-control">
          <option value="">Seleccionar Modo de Notificacion</option>
          <option value="disabled">Deshabilitado</option>
          <option value="sms">SMS</option>
          <option value="sms,email">SMS y Email</option>
      </select>
  </div>
  <div class="modal-body">
      <input type="password" class="form-control" name="password" id="password" placeholder="Password" [(ngModel)]="userModel.modal.password">
  </div>
  <div class="modal-body">
      <input type="password" class="form-control" name="password2" id="password2" placeholder="Confirmar Password" [(ngModel)]="userModel.modal.password2">
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-default btn-primary" (click)="save()">Guardar</button>
  </div>
</ng-template>