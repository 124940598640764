import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../../services/login.service';

@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate {
  constructor(private router: Router, private _loginService: LoginService) {}

  canActivate(): Observable<boolean> | boolean {
    const token = localStorage.getItem('token');
    if (token) {
      this._loginService.updateLoginStatus(false);
      localStorage.removeItem('token');
      this.router.navigate(['']);
      return false;
    }
    return true;
  }
}
