<div class="row justify-content-md-center pt-3">
  <div class="row">
    <div class="col-md-4">
      <label for="lateOpenDelay" class="form-label">Tiempo de reutilización de código de apertura (minutos)</label>
      <input class="form-control" type="number" name="lateOpenDelay" [(ngModel)]="paramsModel.lateOpenDelay" id="lateOpenDelay" placeholder="(minutos)">
    </div>
    <div class="col-md-4">
      <label for="expiredDelayCC" class="form-label">Tiempo de expiración CC (horas)</label>
      <input class="form-control" type="number" name="expiredDelayCC" [(ngModel)]="paramsModel.expiredDelayCC" id="expiredDelayCC" placeholder="(horas)">
    </div>
    <div class="col-md-4">
      <label for="expiredDelayCustomer" class="form-label">Tiempo de expiración Consigna (horas)</label>
      <input class="form-control" type="number" name="expiredDelayCustomer" [(ngModel)]="paramsModel.expiredDelayCustomer" id="expiredDelayCustomer" placeholder="(horas)">
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <label for="notificationTimeOutPreExpiredOrderCCMinutes" class="form-label">Preaviso a usuario de expiración CC</label>
      <input class="form-control" type="number" name="notificationTimeOutPreExpiredOrderCCMinutes" [(ngModel)]="paramsModel.notificationTimeOutPreExpiredOrderCCMinutes" id="notificationTimeOutPreExpiredOrderCCMinutes" placeholder="(horas)">
    </div>
    <div class="col-md-4">
      <label for="notificationTimeOutPreExpiredOrderConsignaMinutes" class="form-label">Preaviso a usuario de expiración Consigna</label>
      <input class="form-control" type="number" name="notificationTimeOutPreExpiredOrderConsignaMinutes" [(ngModel)]="paramsModel.notificationTimeOutPreExpiredOrderConsignaMinutes" id="notificationTimeOutPreExpiredOrderConsignaMinutes" placeholder="(horas)">
    </div>
    <div class="col-md-4">
      <label for="waitForCCNotification" class="form-label">Delay Notificación a usuario CC (minutos)</label>
      <input class="form-control" type="number" name="waitForCCNotification" [(ngModel)]="paramsModel.waitForCCNotification" id="waitForCCNotification" placeholder="(minutos)">
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <label for="statusLocker" class="form-label">Modo de operación</label>
      <select name="statusLocker" [(ngModel)]="paramsModel.statusLocker" id="statusLocker" class="form-control">
        <option value="">Seleccionar</option>
        <option value="Normal">Normal</option>
        <option value="Solo recogida">Solo recogida</option>
        <option value="Mantenimiento">Mantenimiento</option>
      </select>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <label for="hasPayment" class="form-label">Pago en mueble</label>
      <select name="hasPayment" [(ngModel)]="paramsModel.hasPayment" id="hasPayment" class="form-control">
        <option value="">Seleccionar</option>
        <option value="Si">Habilitar</option>
        <option value="No">Deshabilitar</option>
      </select>
    </div>
    <div class="col-md-4">
      <label for="consignaCostPerMinute" class="form-label">Tarificación/minuto (€)</label>
      <input type="text" [disabled]="paramsModel.hasPayment != 'Si'" name="consignaCostPerMinute" [(ngModel)]="paramsModel.consignaCostPerMinute" class="form-control">
    </div>
    <div class="col-md-4">
      <label for="lyraEmail" class="form-label">Email notificación de pago</label>
      <input type="text" [disabled]="paramsModel.hasPayment != 'Si'" name="lyraEmail" [(ngModel)]="paramsModel.lyraEmail" class="form-control">
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <label for="lyraPublicKey" class="form-label">Lyra Public Key</label>
      <input type="text" [disabled]="paramsModel.hasPayment != 'Si'" name="lyraPublicKey" [(ngModel)]="paramsModel.lyraPublicKey" class="form-control">
    </div>
    <div class="col-md-4">
      <label for="lyraClientToken" class="form-label">Lyra Client Token</label>
      <input type="text" [disabled]="paramsModel.hasPayment != 'Si'" name="lyraClientToken" [(ngModel)]="paramsModel.lyraClientToken" class="form-control">
    </div>
    <div class="col-md-4">
      <label for="portRS485BaudRate" class="form-label">Port RS485 Baud Rate</label>
      <input type="text" name="portRS485BaudRate" [(ngModel)]="paramsModel.portRS485BaudRate" class="form-control">
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <label for="companyName" class="form-label">Nombre Cliente a mostrar en Salvapantallas (usar idioma de preferencia)</label>
      <input class="form-control" type="text" name="companyName" [(ngModel)]="paramsModel.companyName" id="companyName" placeholder="">
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <label for="supportCompanyName" class="form-label">Nombre empresa de soporte</label>
      <input class="form-control" type="text" name="supportCompanyName" [(ngModel)]="paramsModel.supportCompanyName" id="supportCompanyName" placeholder="">
    </div>
    <div class="col-md-6">
      <label for="supportCompanyNameESENFR" class="form-label">Nombre de Soporte (ES, EN, FR)</label>
      <input class="form-control" type="text" name="supportCompanyNameESENFR" [(ngModel)]="paramsModel.supportCompanyNameESENFR" id="supportCompanyNameESENFR" placeholder="">
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-md-12 text-end">
      <button class="btn btn-primary ms-2" [disabled]="loading" (click)="cancel()">Cancelar</button>
      <button class="btn btn-success ms-2" [disabled]="loading" (click)="save()">Guardar</button>
    </div>
  </div>
</div>
<div class="spinner" [hidden]="!loading">
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>  
</div>
