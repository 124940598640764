import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SupportService } from '../../services/support.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LockerStaffService } from '../../services/locker-staff.service';
import { PlatformService } from '../../services/platform.service';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-level3',
  templateUrl: './level3.component.html',
  styleUrls: ['./level3.component.scss']
})
export class Level3Component implements OnInit {
  loading: boolean = false;
  lockerId: string = '';
  titleOperation: string;

  userModel: any = {
    response: null,
    statusResponse: 0,
    users: [],
    selected: null,
    modal: null
  }
  hours: number[] = [];
  selectedHour: any;
  
  modalRef: BsModalRef;


	hoveredDate: NgbDate | null = null;

	fromDate: NgbDate | null;
	toDate: NgbDate | null;

  constructor(private router: Router, private _lockerStaffService: LockerStaffService, private modalService: BsModalService, private _platformService: PlatformService, private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    this.titleOperation = 'Agregar';
    this.fromDate = calendar.getToday();
		this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  ngOnInit(): void {
    this.lockerId = localStorage.getItem('lockerid');
    for (let index = 0; index <= 23; index++) {
      this.hours.push(index);
    }
  }

  getUsers(saveIncidence: boolean = true) {
    this.loading = true;
    this._lockerStaffService.getAllUsers(this.lockerId, saveIncidence).subscribe((res: any) => {
      this.loading = false;
      console.log('success', res);
      this.userModel.selected = null;
      this.userModel.users = res;
      this.userModel.statusResponse = 200;
    }, (err) => {
      this.userModel.users = [];
      this.userModel.response = 'NO OK';
      this.userModel.statusResponse = err.status;
      this.loading = false;
      console.log('error', err);
    })
  }

  goBack() {
    this.router.navigate(['advanced']);
  }

  showModal(template) {
    this.userModel.modal = {};
    this.userModel.modal.id = '';
    this.userModel.modal.username = '';
    this.userModel.modal.name = '';
    this.userModel.modal.email = '';
    this.userModel.modal.phoneNumber = '';
    this.userModel.modal.password = '';
    this.userModel.modal.password2 = '';
    this.userModel.modal.role = '';
    this.userModel.modal.notificationChannel = '';
    this.modalRef = this.modalService.show(template);
  }
  
  add(template) {
    this.titleOperation = 'Agregar';
    this.showModal(template);
  }

  update(template) {
    this.titleOperation = 'Modificar';
    this.showModal(template);
    this.userModel.modal = this.userModel.selected;
  }

  save() {
    
    let user = this.userModel.modal;

    if (user.id == '' || user.name == '' || user.email == '' || user.password == '' || user.role == '' || user.notificationChannel == '') {
      alert('Todos los campos son requeridos');
      return;
    }

    if (user.password != user.password2) {
      alert('Los passwords deben ser coincidentes');
      return;
    }
    
    if (user.notificationChannel != 'disabled') {
      user.notificationChannel = (user.notificationChannel != 'sms') ? user.notificationChannel.split(',') : ['sms'];
    } else {
      user.notificationChannel = [];
    }

    let data: any = {
      lockerId: this.lockerId,
    }
    data = Object.assign(data, user);
    delete data.password2;

    const method = (this.titleOperation == 'Agregar') ? this._lockerStaffService.createUser(data) : this._lockerStaffService.updateUser(data);
    this.loading = true;
    method.subscribe((res: any) => {
      this.loading = false;
      if(res.error) {
        alert('Ocurrio un error inesperado, por favor intente nuevamente');
      } else {
        const textOperation = (this.titleOperation == 'Agregar') ? 'agregado' : 'modificado';
        alert(`Usuario ${textOperation} correctamente`);
        this.modalRef.hide();
        this.getUsers(false);
      }
    }, err => {
      alert('Ocurrio un error inesperado, por favor intente nuevamente');
    })
  }

  delete() {
    if (confirm(`Está seguro que desea eliminar el usuario (${this.userModel.selected.email})?`)) {
      const data = {
        lockerId: this.lockerId, 
        id: this.userModel.selected.id
      }
      this._lockerStaffService.deleteUser(data).subscribe(res => {
        alert('Usuario eliminado correctamente');
        this.getUsers(false);
      }, err => {
        alert('Error eliminando el usuario');
      })
    }
  }

  download(type) {
    this._platformService.getJSON(this.lockerId, type).subscribe((res: any) => {
      console.log({res});
      var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(res));
      var dlAnchorElem = document.getElementById('downloadAnchorElem');
      dlAnchorElem.setAttribute("href",     dataStr     );
      dlAnchorElem.setAttribute("download", `${this.lockerId}-${type}.json`);
      dlAnchorElem.click();
    }, (Err) => {
      console.log({Err});
      alert('No se consiguio configuracion');
    })
  }

  resetLocker() {
    const data = {
      lockerId: this.lockerId,
      hour: parseInt(this.selectedHour)
    }
    this._platformService.createCron(data).subscribe((res: any) => {
      console.log({res});
      alert('Cron creado correctamente');
    }, (Err) => {
      alert('Ocurrio un error creando el cron')
    })
  }

  next() {
    this.router.navigate(['end']);
  }

  getLogs() {

  }

  onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

	validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input);
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
	}

}
