import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from '../../services/utils.service';
import { SupportService } from '../../services/support.service';
import { LockerOccupationModel } from './model/lockerOccupation.model';
import { SearchCCModel } from './model/searchCC.model';
import { SearchConsignmentModel } from './model/searchConsignment.model';
import { SpecModel } from './model/spec.model';
import { LoginService } from 'src/app/services/login.service';
import { ResetLockerModel } from './model/resetLocker.model';
import { ResetScreenModel } from './model/resetScreen.model';
import { ValidateSMSModel } from './model/validateSMS.model';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  loading: boolean = false;
  statusResponsePing: number;
  responsePing: string = '';
  lockerId: string;

  lockerOccupationModel: LockerOccupationModel;
  searchConsignmentModel: SearchConsignmentModel;
  searchCCModel: SearchCCModel;
  resetLockerModel: ResetLockerModel;
  resetScreenModel: ResetScreenModel;
  validateSMSModel: ValidateSMSModel;

  responseDiagnostic: string = '';
  statusResponseDiagnostic: number;

  consignments: any = [];
  ccs: any = [];
  role: number = 1;

  constructor(private router: Router, private _supportService: SupportService, 
    private _utilsService: UtilsService, private loginService: LoginService) { }

  ngOnInit(): void {
    localStorage.setItem('step', '2');
    this.lockerId = localStorage.getItem('lockerid');
    this.searchConsignmentModel = {
      type: 'phone',
      phone: ''
    };
    this.searchCCModel = {
      type: 'orderid',
      orderId: ''
    };
    this.loginService.role$.subscribe((role) => {
      this.role = role;
    })
    this.lockerOccupationModel = {
      titleOperation: 'Deshabilitar', 
      disabledOperation: false
    };
    this.resetLockerModel = {
      response: '',
    }
    this.resetScreenModel = {
      response: '',
    }
    this.searchConsignmentModel.type = 'phone';
    this.searchConsignmentModel.phone = '';
    this.searchConsignmentModel.response = '';
    this.validateSMSModel = {
      flagValid: false,
      phone: '',
      lang: '',
      sended: false,
      response: ''
    };
  }

  goBack() {
    this.router.navigate(['start']);
  }

  pingLocker() {
    this.loading = true;
    this._supportService.ping(this.lockerId).subscribe((res: any) => {
      this.loading = false;
      console.log('success', res);
      this.responsePing = (res.pingResponse == true) ? 'OK' : 'NO OK';
      this.statusResponsePing = 200;
    }, (err) => {
      this.responsePing = JSON.stringify(err, null, 4);
      this.statusResponsePing = err.status;
      this.loading = false;
      console.log('error', err);
    })
  }

  getDiagnostic() {
    this.loading = true;
    this._supportService.getDiagnostic(this.lockerId).subscribe((res: any) => {
      this.loading = false;
      console.log('success', res);
      //this.responseDiagnostic = JSON.stringify(res, null, 4);
      if (res.info) {
        let infoData = res.info.split("\n").join("<br>");
        infoData = infoData.split("<----->").join("-");
        infoData = this._utilsService.processDiagnostic(infoData);
        this.responseDiagnostic = infoData
        this.statusResponseDiagnostic = 200;
      } else {
        this.responseDiagnostic = 'NO OK';
        this.statusResponseDiagnostic = 200;
      }
      
    }, (err) => {
      this.responseDiagnostic = JSON.stringify(err, null, 4);
      this.statusResponseDiagnostic = err.status;
      this.loading = false;
      console.log('error', err);
    })
  }

  searchConsignment() {
    if (this.searchConsignmentModel.type == '' || (this.searchConsignmentModel.type == 'phone' && this.searchConsignmentModel.phone == '') || 
        (this.searchConsignmentModel.type == 'code' && this.searchConsignmentModel.code == '') || (this.searchConsignmentModel.type == 'coordinates' && this.searchConsignmentModel.coordinates == '')) {
      this.searchConsignmentModel.flagValid = false;
      return;
    }
    this.loading = true;
    this.searchConsignmentModel.response = '';
    this._supportService.searchConsignment(this.lockerId, this.searchConsignmentModel.type, this.searchConsignmentModel.phone, this.searchConsignmentModel.code, this.searchConsignmentModel.coordinates).subscribe((res: any) => {
      this.loading = false;
      console.log('success', res);
      let consignments = res.consignas;
      consignments = consignments.map((consignment, index) => {
        if (index == 0)
          this.searchConsignmentModel.selected = consignment.code;
        consignment.selected = (consignments.length == 1) ? true : false;
        return consignment;
      })
      console.log(consignments);
      this.consignments = consignments;
      if (this.consignments.length == 0) {
        this.searchConsignmentModel.selected = '';
      }
      this.searchConsignmentModel.statusResponse = 200;
      if (this.consignments.length == 0) {
        this.searchConsignmentModel.response = 'No se consiguieron consignas asociadas';
      }
    }, (err) => {
      this.consignments = [];
      this.searchConsignmentModel.statusResponse = err.status;
      this.loading = false;
      console.log('error', err);
    })
  }

  searchCC() {
    console.log({cc: this.searchCCModel});
    if (this.searchCCModel.type == '' || (this.searchCCModel.type == 'phone' && this.searchCCModel.phone == '') || 
        (this.searchCCModel.type == 'orderid' && this.searchCCModel.orderId == '') || (this.searchCCModel.type == 'coordinates' && this.searchCCModel.coordinates == '')) {
      this.searchCCModel.flagValid = false;
      return;
    }
    this.loading = true;
    this._supportService.searchCC(this.lockerId, this.searchCCModel.type, this.searchCCModel.phone, this.searchCCModel.orderId, this.searchCCModel.coordinates).subscribe((res: any) => {
      this.loading = false;
      console.log('success', res);
      let ccs = res.ccs;
      ccs = ccs.map((consignment, index) => {
        if (index == 0)
          this.searchCCModel.selected = consignment.orderId;
        consignment.selected = (ccs.length == 1) ? true : false;
        return consignment;
      })
      console.log(ccs);
      this.ccs = ccs;
      if (this.ccs.length == 0) {
        this.searchCCModel.selected = '';
      }
      this.searchCCModel.statusResponse = 200;
      if (this.ccs.length == 0) {
        this.searchCCModel.response = 'No se consiguieron ordenes de Click & Collect asociadas';
      }
    }, (err) => {
      this.ccs = [];
      this.searchCCModel.statusResponse = err.status;
      this.loading = false;
      console.log('error', err);
    })
  }

  getLockerOccupation() {
    this.loading = true;
    this._supportService.lockerOccupation(this.lockerId).subscribe((res: any) => {
      this.loading = false;
      console.log('success', res);
      this.lockerOccupationModel.locks = res;
      this.lockerOccupationModel.statusResponse = 200;
    }, (err) => {
      this.lockerOccupationModel.response = JSON.stringify(err, null, 4);
      this.lockerOccupationModel.statusResponse = err.status;
      this.loading = false;
      console.log('error', err);
    })
  }

  changeLockerOccupation() {
    this.lockerOccupationModel.titleOperation = (this.lockerOccupationModel.selected.status == 'disabled') ? 'Habilitar' : 'Deshabilitar';
    this.lockerOccupationModel.disabledOperation = (this.lockerOccupationModel.selected.status == 'busy') ? true : false;
  }

  

  next() {
    if (this.role != 1) {
      this.router.navigate(['advanced'])
    } else {
      this.router.navigate(['end']);
    }
  }

  openOrder(type: string) {
    const orders = (type == 'cc') ? this.ccs : this.consignments;
    const selected = (type == 'cc') ? this.searchCCModel.selected : this.searchConsignmentModel.selected;
    if (orders.length > 0 && selected != '') {
      this.loading = true;
      const method = (type == 'cc') ? this._supportService.openCCByOrdeId(this.lockerId, this.searchCCModel.selected) : this._supportService.openConsignmentByCode(this.lockerId, this.searchConsignmentModel.selected);
      method.subscribe((res: any) => {
        this.loading = false;
        const responseOK = (res.success) ? 'OK' : 'NO OK';
        if (type != 'cc') {
          this.searchConsignmentModel.response = responseOK;
          this.searchConsignmentModel.statusResponse = 200
        } else {
          this.searchCCModel.response = JSON.stringify(res, null, 4);
          this.searchCCModel.statusResponse = 200
        }
        
      }, err => {
        this.loading = false;
        if (type != 'cc') {
          this.searchConsignmentModel.response = 'NO OK';
          this.searchConsignmentModel.statusResponse = err.status;
        } else {
          this.searchCCModel.response = 'NO OK';
          this.searchCCModel.statusResponse = err.status
        }
        console.log(err);
      })
    }
  }

  retireOrder(type: string) {
    const orders = (type == 'cc') ? this.ccs : this.consignments;
    const selected = (type == 'cc') ? this.searchCCModel.selected : this.searchConsignmentModel.selected;
    if (orders.length > 0 && selected != '') {
      this.loading = true;
      this._supportService.retireOrder(this.lockerId, type, selected).subscribe((res: any) => {
        this.loading = false;
        const responseOK = (res.success) ? 'OK' : 'NO OK';
        this.searchConsignmentModel.response = responseOK;
        this.searchConsignmentModel.statusResponse = 200;
        if (type != 'cc') {
          this.consignments = this.consignments.filter((consignment) => {
            if (consignment.code == this.searchConsignmentModel.selected) { 
              return false;
            }
            return true;
          })
          this.searchConsignmentModel.selected = '';
        } else {
          this.ccs = this.ccs.filter((cc) => {
            if (cc.code == this.searchCCModel.selected) { 
              return false;
            }
            return true;
          })
          this.searchCCModel.selected = '';
        }
      }, err => {
        this.loading = false;
        this.searchConsignmentModel.response = 'NO OK';
        this.searchConsignmentModel.statusResponse = err.status;
        console.log(err);
      })
    }
  }
  
  sendNotificationOrder(type: string) {
    if (this.consignments.length > 0 && this.searchConsignmentModel.selected != '') {
      this.loading = true;
      this._supportService.resendCode(this.lockerId, this.searchConsignmentModel.selected).subscribe((res) => {
        this.loading = false;
        this.searchConsignmentModel.response = JSON.stringify(res, null, 4);
        this.searchConsignmentModel.statusResponse = 200
      }, err => {
        this.loading = false;
        this.searchConsignmentModel.response = JSON.stringify(err, null, 4);
        this.searchConsignmentModel.statusResponse = err.status;
        console.log(err);
      })
    }
  }

  openLocker() {
    this.loading = true;
    const selected = this.lockerOccupationModel.selected
    if (this.lockerOccupationModel.locks.length > 0 && selected) {
      this._supportService.openConsignmentByCoordinates(this.lockerId, selected.businessType, selected.y, selected.x).subscribe((res: any) => {
        this.loading = false;
        const resOK = (res.success) ? 'OK' : 'NO OK'
        this.lockerOccupationModel.response = resOK;
        this.lockerOccupationModel.statusResponse = 200
      }, err => {
        this.loading = false;
        this.lockerOccupationModel.response = 'NO OK';
        this.lockerOccupationModel.statusResponse = err.status;
        console.log(err);
      })
    }
  }

  disableLocker() {
    this.loading = true;
    const selected = this.lockerOccupationModel.selected
    const here = this;
    if (this.lockerOccupationModel.locks.length > 0 && selected && selected.status != 'busy') {
      const operationType = (selected.status != 'disabled') ? 'bloquear' : 'desbloquear';
      this._supportService.operationLocker(this.lockerId, selected.businessType, operationType, selected.idLock).subscribe((res: any) => {
        here.loading = false;
        const resOK = (res.success) ? 'OK' : 'NO OK'
        here.lockerOccupationModel.response = resOK;
        here.lockerOccupationModel.statusResponse = 200;
        const index = here.lockerOccupationModel.locks.indexOf(selected);
        here.lockerOccupationModel.locks[index].status = (selected.status != 'disabled') ? 'disabled' : 'free';
        here.lockerOccupationModel.selected.status = here.lockerOccupationModel.locks[index].status;
        this.lockerOccupationModel.titleOperation = (here.lockerOccupationModel.selected.status == 'disabled') ? 'Habilitar' : 'Deshabilitar'
      }, err => {
        this.loading = false;
        this.lockerOccupationModel.response = 'NO OK';
        this.lockerOccupationModel.statusResponse = err.status;
        console.log(err);
      })
    }
  }

  resetLocker() {
    this.loading = true;
    this._supportService.resetLocker(this.lockerId).subscribe((res: any) => {
      this.loading = false;
      this.resetLockerModel.response = (res) ? 'OK' : 'NO OK';
      this.resetLockerModel.statusResponse = 200;
    }, (err) => {
      this.resetLockerModel.response = JSON.stringify(err, null, 4);
      this.resetLockerModel.statusResponse = err.status;
      this.loading = false;
      console.log('error', err);
    })
  }

  resetScreen() {
    // this.loading = true;
    // this._supportService.resetLocker(this.lockerId).subscribe((res: any) => {
      // this.loading = false;
      this.resetScreenModel.response = 'OK';
      this.resetScreenModel.statusResponse = 200;
    // }, (err) => {
    //   this.resetLockerModel.response = JSON.stringify(err, null, 4);
    //   this.resetLockerModel.statusResponse = err.status;
    //   this.loading = false;
    //   console.log('error', err);
    // })
  }

  validateSMS() {
    console.log({valid: this.validateSMSModel})
    this.validateSMSModel.sended = true;
    if(this.validateSMSModel.phone == '' || this.validateSMSModel.lang == '') {
      this.validateSMSModel.flagValid = this.validateSMSModel.phone != '';
      this.validateSMSModel.flagValidLang = this.validateSMSModel.lang != '';
      return;
    }
    
    this.validateSMSModel.flagValid = true;
    this.validateSMSModel.flagValidLang = true;
    this.loading = true;
    this._supportService.validateSMS(this.lockerId, this.validateSMSModel.phone, this.validateSMSModel.lang).subscribe((res: any) => {
      this.loading = false;
      this.validateSMSModel.response = (res.success) ? 'OK' : 'NO OK';
      this.validateSMSModel.statusResponse = 200;
    }, (err) => {
      this.validateSMSModel.response = JSON.stringify(err, null, 4);
      this.validateSMSModel.statusResponse = err.status;
      this.loading = false;
      console.log('error', err);
    })
  } 

  changePhone() {
    this.validateSMSModel.flagValid = (this.validateSMSModel.phone != '');
  }

  changeLang() {
    this.validateSMSModel.flagValidLang = (this.validateSMSModel.lang != '');
  }

}
