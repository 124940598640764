import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IsAuthenticatedGuard } from './common/guards/authenticated.guard';
import { LoginGuard } from './common/guards/login.guard';
import { EndComponent } from './pages/end/end.component';
import { StartComponent } from './pages/start/start.component';
import { LoginComponent } from './pages/login/login.component';
import { SupportComponent } from './pages/support/support.component';
import { HomeComponent } from './pages/home/home.component';
import { ReportComponent } from './pages/report/report.component';
import { AdvancedComponent } from './pages/advanced/advanced.component';
import { UsersComponent } from './pages/users/users.component';
import { Level3Component } from './pages/level3/level3.component';
import { ConfigurationToolComponent } from './pages/configuration-tool/configuration-tool.component';
import { ConfigurationSpecsComponent } from './pages/configuration/specs/specs.component';
import { ConfigurationParamsComponent } from './pages/configuration/params/params.component';
import { ConfigurationNetworkComponent } from './pages/configuration/network/network.component';
import { SuperClientComponent } from './pages/super-client/super-client.component';
import { SuperLevel3Component } from './pages/super-level3/super-level3.component';

const routes: Routes = [
  { path: '', component: LoginComponent, canActivate: [LoginGuard] },
  { path: 'home', component: HomeComponent, canActivate: [IsAuthenticatedGuard] },
  { path: 'start', component: StartComponent, canActivate: [IsAuthenticatedGuard] },
  { path: 'support', component: SupportComponent, canActivate: [IsAuthenticatedGuard] },
  { path: 'advanced', component: AdvancedComponent, canActivate: [IsAuthenticatedGuard] },
  { path: 'end', component: EndComponent, canActivate: [IsAuthenticatedGuard] },
  { path: 'report', component: ReportComponent, canActivate: [IsAuthenticatedGuard] },
  { path: 'users', component: UsersComponent, canActivate: [IsAuthenticatedGuard] },
  { path: 'configuration-tool', component: ConfigurationToolComponent, canActivate: [IsAuthenticatedGuard] },
  { path: 'level3', component: Level3Component, canActivate: [IsAuthenticatedGuard] },
  { path: 'configuration-specs', component: ConfigurationSpecsComponent, canActivate: [IsAuthenticatedGuard] },
  { path: 'configuration-params', component: ConfigurationParamsComponent, canActivate: [IsAuthenticatedGuard] },
  { path: 'configuration-network', component: ConfigurationNetworkComponent, canActivate: [IsAuthenticatedGuard] },
  { path: 'super-client', component: SuperClientComponent, canActivate: [IsAuthenticatedGuard] },
  { path: 'super-level3', component: SuperLevel3Component, canActivate: [IsAuthenticatedGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
