import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoginService } from 'src/app/services/login.service';
import { SupportService } from 'src/app/services/support.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  modalRef: BsModalRef;
  loading: boolean = false;
  lockerid: string = '';
  newIncidence: boolean = false;
  incidences: any = [];

  constructor(private router: Router, 
    private modalService: BsModalService, 
    private loginService: LoginService,
    private supportService: SupportService) { 
    }

  ngOnInit(): void {
    console.log('ngOnInit');
    this.supportService.getAllIncidences().subscribe((res: any) => {
      console.log({res});
      res = res.map((re) => {
        console.log(re.startHour);
        console.log(moment(re.startHour, 'YYYY-MM-DD HH:mm'));
        re.date = moment(re.startHour, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY')
        re.hour = moment(re.startHour, 'YYYY-MM-DD HH:mm').format('HH:mm')
        re.statusString = 'Pendiente'
        return re;
      })
      this.incidences = res;
    })
  }

  add(template: TemplateRef<any>) {
    this.newIncidence = true;
    this.modalRef = this.modalService.show(template);
  }

  start() {
    if (this.lockerid == '') {
      alert('Todos los campos son requeridos');
      return;
    }

    localStorage.setItem('lockerid', this.lockerid);
    localStorage.setItem('step', '1');
    if (this.modalRef)
      this.modalRef.hide();
    this.loading = true;

    const timezone = moment.tz.guess();
    localStorage.setItem('timezone', timezone);

    if (this.newIncidence) {
      localStorage.setItem('fecha', moment().format('DD/MM/YYYY'));
      localStorage.setItem('start', moment().format('HH:mm'));
    }

    const data: any = {
      timezone, lockerId: this.lockerid, newIncidence: this.newIncidence,
      startHour: moment().format('YYYY-MM-DD HH:mm')
    }

    this.loginService.getData(data).subscribe((resData: any) => {
      this.loading = false;
      this.loginService.updateLoginStatus(true);
      this.loginService.updateShowResumen(true);
      localStorage.setItem('companyName', resData.companyName || '');
      localStorage.setItem('location', resData.lockerLocation || '');
      localStorage.setItem('token', resData.accessToken);
      this.loading = false;
      this.router.navigate(['start']);
    }, (err) => {
      this.loginService.updateLoginStatus(false);
      this.loading = false;
      if (err.status == 404) {
        alert('Ha ocurrido un error en el servidor, por favor intente nuevamente');
      }
      console.log('error', err);
    })
  }

  open(incidence: any) {
    localStorage.setItem('incidence', incidence.id);
    localStorage.setItem('fecha', incidence.date);
    localStorage.setItem('start', incidence.hour);
    if (incidence.startMessage)
      localStorage.setItem('start_message', incidence.startMessage);
    this.newIncidence = false;
    this.lockerid = incidence.idLock;
    this.start();
  }

}
