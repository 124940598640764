import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { LoginService } from '../../services/login.service';
import { SupportService } from '../../services/support.service';

@Component({
  selector: 'app-end',
  templateUrl: './end.component.html',
  styleUrls: ['./end.component.scss']
})
export class EndComponent implements OnInit {

  message: string = '';
  status: string = '';
  date: string = '';
  loading: boolean = false;
  isAdmin: boolean = false;

  constructor(private router: Router, private _supportService: SupportService, private _loginService: LoginService) {
    this._loginService.isAdmin$.subscribe((isAdmin) => {
      this.isAdmin = isAdmin;
    })
  }

  ngOnInit(): void {

  }

  goBack() {
    if (!this.isAdmin) {
      this.router.navigate(['support'])
    } else {
      this.router.navigate(['advanced'])
    }
  }

  endIncidence() {
    this.loading = true;
    this.date = moment().format('YYYY-MM-DD HH:mm');
    const incidence = localStorage.getItem('incidence');
    this._supportService.endIncidence(this.message, this.status, this.date, incidence).subscribe((res) => {
      this.loading = false;
      if (res == true) {
        this.router.navigate(['report'])
      } else {
        alert('Ocurrio un erro guardando la incidencia')
      }
    })
  }

}
