import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'moduleNoAuxPipe',
    pure: false
})
export class ModuleNoAuxPipe implements PipeTransform {
  transform(items: any, args?: any): any {
    if (!items) {
        return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter(item => item.moduleType !== 'aux' && item.moduleType !== 'cd' && item.moduleType !== 'cb' && item.moduleType !== 'cs');
}
}
