<div class="row justify-content-md-center pt-3">
  <div class="col col-lg-12">
    <label for="message">Descripción de la incidencia: (<span style="color: #dc3545;">*</span>)</label>
    <textarea name="message" class="form-control" [ngClass]="{'required-error-input' : messageError}" [(ngModel)]="message" (ngModelChange)="clearErr('message')" id="" rows="8"></textarea>
  </div>
</div>
<div class="row">
  <div class="text-end mt-4 mb-4">
    <button class="btn btn-primary ms-2" (click)="next()" [disabled]="loading">Comenzar</button>
  </div>
</div>
<div class="spinner" [hidden]="!loading">
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>  
</div>